import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import PhoneIphoneOutlinedIcon from '@mui/icons-material/PhoneIphoneOutlined';
import Typography from '@mui/material/Typography';
import { Stack, useTheme } from "@mui/material";

const ShareItem = ({ text, IconComponent, url = 'http://localhost:3000' }) => {

	return(
		<Stack
			onClick={() => window.open(url)}
			sx={{ cursor: 'pointer' }}
			direction='column'
			spacing={1}
			justifyContent='center'
			alignItems='center'>
			<IconComponent sx={{ fontSize: '4em' }}/>
			<Typography variant="button" sx={{ textDecoration: 'underline' }}>{ text }</Typography>
		</Stack>
	)
}

const ShareAsLink = ({ shareURL, pdfURL }) => {

	const theme = useTheme()

	return (
		<Stack
			direction={{xs: 'column', sm:'row'}}
			spacing={{xs: 2, sm: 8}}
			justifyContent={'center'}
			alignItems='center'>
			<ShareItem
				text='Download PDF'
				IconComponent={InsertDriveFileOutlinedIcon}
				url={pdfURL} />
			<Typography variant={'body2'} sx={{textAlign: 'center', color: theme.palette.grey[500]}}>or</Typography>
			<ShareItem
				text='Copy link'
				IconComponent={PhoneIphoneOutlinedIcon}
				url={shareURL} />
		</Stack>
	)
}

export default ShareAsLink