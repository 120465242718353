import React from 'react';
import FormWrapper from '../components/FormWrapper';
import FormNavigationLogic from '../components/forms/FormNavigationLogic';
import { CURRENT_STEP, CURRENT_STEP_INDEX, NEXT_STEP, PREVIOUS_STEP } from '../components/forms/FormSteps';
import BillingForm from '../components/forms/BillingForm';

const CreateInvoiceBilling= () => {

	const previousStep = PREVIOUS_STEP('billing') 
	const currentStep = CURRENT_STEP('billing')
	const nextStep = NEXT_STEP('billing')
	const activeStepIndex = CURRENT_STEP_INDEX('billing')

  return (
    <FormWrapper heading={currentStep.heading} progress={activeStepIndex}>
		<BillingForm />
      	<FormNavigationLogic
			{...currentStep}
			previousStep={previousStep}
			nextStep={nextStep}
		/>
    </FormWrapper>
  );
}

export default CreateInvoiceBilling;
