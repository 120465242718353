import * as React from 'react'
import { Backdrop, Box, Typography, IconButton, Button, Stack } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { hideOverlay } from '../store/miscSlice'
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material';
import TextInput from './inputs/TextInput';


function RoundOverlay() {

	const theme = useTheme();
	
	const navigate = useNavigate();
    // const misc = useSelector(state => state.misc)
    const misc = { showOverlay: false }
    const dispatch = useDispatch()

    const handleClose = () => {
		console.log('SHOULD CLOSE');
        dispatch( hideOverlay() )
    }

	return(
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={misc.showOverlay}
			onClick={handleClose}
        >
            <Box sx={{
                backgroundColor: theme.palette.grey[300],
				borderRadius: '50px',
                padding: '20px',
                display: 'flex',
                position: 'inherit',
                justifyContent: 'center',
                flexDirection: 'column',
                width: {
                    xs: '70%',
                    s: '200px',
                  },
				height: {
					xs: '400px',
				}
            }}>
				<Typography variant='h3'>Welcome!</Typography>
				<TextInput
					value={""}
					onChange={e => {}}
					label="Type your invite code"
					variant="standard"
				/>
				<Typography variant="small-text">Share for an invite</Typography>
				<TextInput
					value={""}
					onChange={e => {}}
					label="Type your email"
					variant="standard"
				/>
                {/* <Typography sx={{color: 'white'}} variant='h3'>{VALIDATION_MESSAGE['headline']}</Typography>
                <Typography sx={{color: 'white', textAlign: 'center'}}>{VALIDATION_MESSAGE['body']}</Typography>
                { misc.overlayMsg ? <Typography sx={{color: 'white', textAlign: 'center', whiteSpace: 'pre-line'}}>{misc.overlayMsg}</Typography> : null }
				<Stack direction={'row'} sx={{paddingTop: '15px', justifyContent: 'space-between'}}>
					<Button sx={{color: 'white', textDecoration: 'underline'}} onClick={handleClose}>Go to error</Button>
					<Button sx={{color: 'white', textDecoration: 'underline'}} onClick={handleForceSend}>Send anyway</Button>
				</Stack> */}
            </Box>

        </Backdrop>
    )
}

export default RoundOverlay