import { Fragment } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import { useSelector } from 'react-redux';
import InvoiceList from '../components/InvoiceList';
import { useNavigate } from 'react-router-dom';
import TextButton from '../components/buttons/TextButton';
import Logo from '../components/logo/Logo';
import { useMediaQuery, useTheme } from '@mui/material';
import YourLogo from '../components/LogoSelect/YourLogo';

function Invoices() {

	const navigate = useNavigate();
	const theme = useTheme();
	const onMobile = useMediaQuery(theme.breakpoints.down("sm"))

	const user = useSelector(state => state.user)
	const avatar = useSelector(state => state.user.avatar)

	return (
		<Fragment>
		<Container sx={{
				width: '100%',
				minHeight: '80vh',
			}}>
			<Stack spacing={2} sx={{padding: onMobile ? '' : '40px', paddingTop: '60px', paddingBottom: '60px'}}>
				<Container sx={{
					display: 'flex',
					flexDirection: onMobile ? 'column' : 'row',
					justifyContent: onMobile ? '' : 'flex-start',
					alignItems: onMobile ? '' : 'flex-start',
					gap: onMobile ? '10px' : '20px',
					}}>
					<Box sx={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
						gap: '10px'
						}}>
						{ avatar.URL ?
							<Logo showPro={true} avatar={avatar} size="large" /> : 
							<Logo showPro={true} border={true} size="large"><YourLogo/></Logo> }
					</Box>
					<Typography sx={{ ...(!onMobile && {lineHeight: '100px'})}} variant='h1'>{user.companyName}</Typography>
					{ onMobile ? <TextButton onClick={() => navigate('/edit-profile')}>Edit profile</TextButton> : null }
					{ onMobile ? <TextButton onClick={() => navigate('/edit-billing')}>Edit billing</TextButton> : null }
				</Container>
				{ onMobile ? null : <Container>
							<TextButton onClick={() => navigate('/edit-profile')}>Edit profile</TextButton>
							<TextButton onClick={() => navigate('/edit-billing')}>Edit billing</TextButton>
						</Container> }
				<Container sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
					<InvoiceList />
				</Container>
			</Stack>
		</Container>
		</Fragment>
	)
}

export default Invoices;