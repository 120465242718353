import { styled } from '@mui/system';
import { Fragment } from 'react';


const PLACEMENT = {
	top: '40vh',
	side: '15vw',
	side_tight: '8vw',
}

const SIZE = {
	top: 0,
	right: 13,
	bottom: 24,
	left: 70,
	width: 80,
	height: 20,
	bef_left: -4,
	bef_top: 5,
	aft_left: 4,
	aft_top: -40,
}

const SCALE = 2

const StyledLightningBolt = styled('div')(({ theme, orientation, isShadow }) => ({
	left: orientation === 'left' ? PLACEMENT.side : '',
	right: orientation === 'right' ? PLACEMENT.side : '',
	[theme.breakpoints.only('md')]: {
		left: orientation === 'left' ? PLACEMENT.side_tight : '',
		right: orientation === 'right' ? PLACEMENT.side_tight : '',
	},
	[theme.breakpoints.down('md')]: {
		display: 'none',
	},
	position: 'fixed',
	zIndex: isShadow ? '2' : '3',
	width: `${SIZE.width*SCALE}px`,
	height: `${SIZE.height*SCALE}px`,
	margin: '0 auto',
	top: PLACEMENT.top,
	animation: 'flash 3s infinite, float 3s ease-in-out infinite',
	transform: `${orientation === 'left' ? 'rotate(-40deg)' : 'rotate(20deg)'} ${isShadow ? 'translate(10px, 30px)' : ''}`,
	filter: isShadow ? 'blur(5px)' : '',
	':before, :after': {
		content: '""',
		position: 'absolute',
	},
	':before': {
		width: '0',
		height: '0',
		left: `${SIZE.bef_left*SCALE}px`,
		top: `${SIZE.bef_top*SCALE}px`,
		borderStyle: 'solid',
		borderWidth: `${SIZE.top*SCALE}px ${SIZE.right*SCALE}px ${SIZE.bottom*SCALE}px ${SIZE.left*SCALE}px`,
		borderColor: `transparent transparent ${isShadow ? theme.palette.grey[700] : theme.palette.grey[300]} transparent`,
		transform: `rotate(-60deg)`,
	},
	':after': {
		width: '0',
		height: '0',
		left: `${SIZE.aft_left*SCALE}px`,
		top: `${SIZE.aft_top*SCALE}px`,
		borderStyle: 'solid',
		borderWidth: `${SIZE.top*SCALE}px ${SIZE.right*SCALE}px ${SIZE.bottom*SCALE}px ${SIZE.left*SCALE}px`,
		borderColor: `transparent transparent ${isShadow ? theme.palette.grey[700] : theme.palette.grey[300]} transparent`,
		transform: `rotate(-60deg) scaleY(-1) scaleX(-1)`,
	}
}))



const LightningBolt = ({ orientation = 'left' }) => {

	return (
		<Fragment>
			<StyledLightningBolt orientation={orientation} />
			<StyledLightningBolt orientation={orientation} isShadow/>
		</Fragment>
	)
}

export default LightningBolt