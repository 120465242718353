import Chip from '@mui/material/Chip';
import { FORM_PROPS } from '../../helpers/FormProps';
import { styled } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TextButton from '../buttons/TextButton';
import { useTheme } from '@emotion/react';

const StyledChip = styled(Chip, {
	
})(({theme, purpose = 'existing'}) => ({
	...theme.typography.button,
	['.MuiChip-label']: {
		padding: '20px',
	},
	...(purpose === 'new' && {
		backgroundColor: theme.palette.primary.light,
	})
}))

const SelectCustomerForm = ({ selectCustomer = () => {}, createNewCustomer = () => {}, customers = []}) => {
	const theme = useTheme()
	// customers = customers.concat(customers, customers, customers, customers, customers, customers, customers, customers,)
	return (
		<Stack direction={"column"} spacing={FORM_PROPS.ROW_SPACING}>
			<TextButton textAlign="left" onClick={createNewCustomer}>
				Add new
			</TextButton>
			<Box>
				<Typography variant='body2' sx={{ padding: '8px', color: theme.palette.primary.main }}>Previous customers</Typography>
				<Grid
					justifyContent="flex-start"
					direction="row"
					container
					sx={{ padding: '8px' }}
					spacing={FORM_PROPS.COLUMN_SPACING}>
					{customers.map((customer, index) =>
						<Grid item="true" key={`customer-${index}`}>
							<StyledChip
								variant="outlined"
								label={customer.companyName}
								onClick={() => selectCustomer(customer.id)}
							/>
						</Grid>
					)}
				</Grid>
			</Box>
		</Stack>
	)
}

export default SelectCustomerForm