import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Box, Typography, Button, Stack, TextField, CircularProgress, InputAdornment } from '@mui/material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useTheme, styled } from '@mui/material';
import { AuthContext } from '../../helpers/auth/AuthProvider';
import { useNavigate } from 'react-router-dom';
import FullModal from '../modals/FullModal';
import ModalTextInput from '../inputs/ModalTextInput';

const VALIDATE_STATUS = {
	NOT_SENT: 'NOT_SENT',
	VALIDATING: 'VALIDATING',
	VALID: 'VALID',
	INVALID: 'INVALID',
}

const VALIDATE_CODE = (enteredCode = '') => {
	const matches = enteredCode.replace(/\D/g,"");
	if (matches.split("").reduce((part, num) => part + Number(num), 0) === 10) return true
	return false
}

const INPUT_ARROW = ({ faded = false, submitFunc = () => { console.log('submit'); } }) => {
	const theme = useTheme();
	return (
		<InputAdornment position="end" onClick={ submitFunc }>
			<ArrowForwardIcon sx={{ color: faded ? theme.palette.grey[400] : theme.palette.primary.main }} />
		</InputAdornment>
	)
}

const StyledTextField = styled(TextField)(({theme}) => ({
	input: {
		['&::placeholder'] : {
			color: 'black',
			opacity: 1,
		},
	}
}))

const LOADING_SPINNER = () => {
	return (
		<InputAdornment position="end">
			<CircularProgress size={'1rem'} />
		</InputAdornment>
	)
}

function InviteModal(props) {

	const authContext = useContext(AuthContext)

	const navigate = useNavigate()

	const { showInvite, setShowInvite, setShowCreateAccount, setValidCode } = authContext

	const [ validating, setValidating ] = useState(VALIDATE_STATUS.NOT_SENT)

	const [inviteCode, setInviteCode] = useState('');
	const [requestEmail, setRequestEmail] = useState('');

	const theme = useTheme();

	useEffect(() => {
		if (validating === VALIDATE_STATUS.VALIDATING) {
			const isValid = VALIDATE_CODE(inviteCode)
			const timeout = setTimeout(() => {
				if( isValid ) {
					setValidCode(true)
					setValidating(VALIDATE_STATUS.VALID)
				} else {
					setValidating(VALIDATE_STATUS.INVALID)
				}
			}, 1000)
			return () => {
				clearTimeout(timeout)
			}
		}
		if (validating === VALIDATE_STATUS.VALID) {
			closeModal()
			setShowCreateAccount(true)
			// const timeout = setTimeout(() => closeModal(), 0)
			// return () => {
			// 	clearTimeout(timeout)
			// }
		}
	}, [validating])

	const handleValidateCode = (e = { key: false }) => {
		if (e.key !== 'Enter') return
		setValidating(VALIDATE_STATUS.VALIDATING)
		return
	}

	const handleRequestEmail = (e = { key: false }) => {
		if (e.key !== 'Enter') return
		window.open('mailto:info@pejme.se?subject=Request code&body=Hi!%0D%0A%0D%0AI\'d like access to pejme, my email is: '+requestEmail+'%0D%0A%0D%0ACheers!')
		return
	}

	const closeAndRedirect = () => {
		navigate('/')
		setValidating(VALIDATE_STATUS.NOT_SENT)
		setShowInvite(false)
	}

	const closeModal = () => {
		setValidating(VALIDATE_STATUS.NOT_SENT)
		setShowInvite(false)
	}

	return(
		<FullModal isOpen={showInvite} onClose={ closeAndRedirect} showBolts={true} >
		{
			{
				'INVALID' :
				<Fragment>
					<Stack spacing={3}>
						<Typography variant='h2'>Sorry</Typography>
						<Typography variant="p" textAlign={'center'}>Wrong code</Typography>
						<Button sx={{...theme.typography.p}} variant="text" onClick={() => setValidating(VALIDATE_STATUS.NOT_SENT)}>Try again?</Button>	
					</Stack>
				</Fragment>	,
				'VALID' :
				<Fragment>
					<Stack spacing={5}>
						<Typography variant='h2'>Congrats!</Typography>
						<Typography variant="p" textAlign={'center'}>Access granted</Typography>
					</Stack>
				</Fragment>				
			}[validating] ||
				<Fragment>
					<Stack spacing={5}>
						<Stack spacing={5}>
							<Typography variant='h2'>Welcome!</Typography>
							<Box>
								<Typography variant='body2' color={theme.palette.grey[600]}>Do you have an invite?</Typography>
								<StyledTextField
									disabled={validating === VALIDATE_STATUS.VALIDATING}
									value={inviteCode}
									onChange={(e) => { setInviteCode(e.target.value)}}
									onKeyDown={handleValidateCode}
									fullWidth
									placeholder="Invite code"
									type="text"
									variant="standard"
									autoComplete='off'
									InputProps={{
										endAdornment: validating === VALIDATE_STATUS.VALIDATING ? <LOADING_SPINNER /> : <INPUT_ARROW faded={inviteCode.length < 5} submitFunc={() => handleValidateCode({key: 'Enter' })} />
									}}
								/>
							</Box>
						</Stack>
						<Box>
							<Typography variant='body2' color={theme.palette.grey[600]}>Request an invite</Typography>
							<StyledTextField
								disabled={validating === VALIDATE_STATUS.VALIDATING}
								value={requestEmail}
								onChange={(e) => { setRequestEmail(e.target.value)}}
								onKeyDown={handleRequestEmail}
								fullWidth
								placeholder="Your email"
								type="email"
								variant="standard"
								autoComplete="email"
								InputProps={{
									endAdornment: validating === VALIDATE_STATUS.VALIDATING ? <LOADING_SPINNER /> : <INPUT_ARROW faded={requestEmail.length < 5} submitFunc={() => handleRequestEmail({key: 'Enter' })} />
								}}
							/>
						</Box>
					</Stack>
				</Fragment>
		}
		</FullModal>
    )
}

export default InviteModal