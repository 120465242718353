import { TableCell, TableContainer, TableHead, TableRow, Table, TableBody, Paper, styled, Typography } from "@mui/material"
import { useSelector } from "react-redux"
import dayjs from 'dayjs'
import { CURRENCIES } from "../helpers/MoneyHelper"
import FrameModal from "./modals/FrameModal"
import SendAsEmail from "./share/SendAsEmail"
import { useEffect, useState } from "react"
import { useMediaQuery, useTheme } from '@mui/material';
import ConfirmRemove from "./fragments/ConfirmRemove"
import InvoiceListEmpty from "./InvoiceListEmpty"

const StyledTableContainer = styled(TableContainer)(({theme}) => ({
	width: '100%'
}))

const StyledTableRow = styled(TableRow)(({theme}) => ({
	cursor: 'pointer'
}))

const StyledTableCell = styled(TableCell, {
		shouldForwardProp: (prop) => prop !== 'isAction'
	})(({theme, isAction = false, aligntext = false}) => ({
		border: 'none',
		padding: '8px',
		paddingLeft: '16px',
		paddingRight: '16px',
		backgroundColor: '#ffffff',
		...(isAction && {
			textDecoration: 'underline',
			cursor: 'pointer',
		}),
		...(aligntext && {
			textAlign: aligntext
		})
	}
))

const InvoiceList = () => {

	const [emailModalOpen, setEmailModalOpen] = useState(false)
	const [currentInvoice, setCurrentInvoice] = useState({})

	const [removeConfirmationOpen, setRemoveConfirmationOpen] = useState(false)

	const theme = useTheme();
	const onMobile = useMediaQuery(theme.breakpoints.down("sm"))

	useEffect(() => {
		if(!emailModalOpen) {
			setCurrentInvoice({})
		}
	}, [emailModalOpen])

	const invoices = useSelector(({invoices}) => {
		return [...invoices.invoices].sort((a,b) => a.invoiceDate - b.invoiceDate)
	})

	const openInvoiceLink = (invoiceId) => {
		window.open(`${window.location.origin}/invoice/${invoiceId}`)
	}

	const openInvoicePdf = ({event, URL}) => {
		event.stopPropagation();
		window.open(URL)
	}

	const openShare = ({event, invoice}) => {
		event.stopPropagation();
		// Handle customer email as default?
		setCurrentInvoice(invoice)
		setEmailModalOpen(true)
	}

	const handleRemove = ({event, invoice}) => {
		event.stopPropagation()
		setCurrentInvoice(invoice)
		setRemoveConfirmationOpen(true)
	}

	const handleCloseRemoveModal = () => {
		setCurrentInvoice({})
		setRemoveConfirmationOpen(false)
	}

	const closeEmailModal = () => {
		setEmailModalOpen(false)
	}

	if (invoices.length === 0) return (<InvoiceListEmpty />)

	return (
		<StyledTableContainer elevation={0} component={Paper}>
			<Table size="small">
				<TableHead>
					<TableRow>
						<StyledTableCell>No.</StyledTableCell>
						{ onMobile ? null : <StyledTableCell>Invoice date</StyledTableCell> }
						{ onMobile ? null : <StyledTableCell>Due date</StyledTableCell> }
						<StyledTableCell>Amount</StyledTableCell>
						<StyledTableCell></StyledTableCell>
						{/* { process.env.NODE_ENV === 'development' && <StyledTableCell>Share</StyledTableCell> } */}
						<StyledTableCell></StyledTableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{ invoices.map((item, index) => (
						<StyledTableRow key={`invoice-${index}`} onClick={() => openInvoiceLink(item.id)}>
							<StyledTableCell sx={{textDecoration: 'underline'}}><Typography variant="tableContent">{ item.invoiceNumber }</Typography></StyledTableCell>
							{ onMobile ? null : <StyledTableCell>{ dayjs.unix(item.invoiceDate).format("DD MMM YYYY") }</StyledTableCell> }
							{ onMobile ? null : <StyledTableCell>{ dayjs.unix(item.dueDate).format("DD MMM YYYY") }</StyledTableCell> }
							<StyledTableCell>{ `${CURRENCIES[item.currency](item.totalAmount).format()}` }</StyledTableCell>
							<StyledTableCell isAction={true} onClick={(e) => {openInvoicePdf({ event: e, URL: item.invoiceURL}) }}>Download</StyledTableCell>
							{/* { process.env.NODE_ENV === 'development' && <StyledTableCell isAction={true} onClick={(e) => { openShare({ event: e, invoice: item }) }}>Send email</StyledTableCell> } */}
							<StyledTableCell isAction={true} onClick={(e) => { handleRemove({ event: e, invoice: item }) }}>Remove</StyledTableCell>
						</StyledTableRow>
					))}
				</TableBody>
			</Table>
			<ConfirmRemove isOpen={removeConfirmationOpen} handleClose={handleCloseRemoveModal} invoice={currentInvoice} />
			<FrameModal isOpen={emailModalOpen} setIsOpen={setEmailModalOpen} >
				<SendAsEmail defaultCustomerEmail="" invoiceId={currentInvoice.id} emailSent={closeEmailModal} />
			</FrameModal>
		</StyledTableContainer>
	)
}

export default InvoiceList