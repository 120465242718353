import { InputAdornment, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { clearValidation } from "../../store/miscSlice";
import { AccountFormat } from "./formats/AccountFormat";
import { AmountFormat } from "./formats/AmountFormat";
import { BankgiroFormat } from "./formats/BankgiroFormat";
import { ClearingFormat } from "./formats/ClearingFormat";
import { OrgFormat } from "./formats/OrgFormat";
import { PhoneNumberFormat } from "./formats/PhoneNumberFormat";
import { VATFormat } from "./formats/VATFormat";

function NumberInput({
    id = false,
    handleChangeFunc,
    value,
    propName = '',
    inputType = 'amount',
    label = '',
	helperText = '',
    valid = true,
    adorment = { label: '', placement: false },
    disabled = false,
    sx = { width: '50%'},
}){

    let inputComponent = AmountFormat;
    if (inputType === 'phone') inputComponent = PhoneNumberFormat
    if (inputType === 'bg') inputComponent = BankgiroFormat
    if (inputType === 'clearing') inputComponent = ClearingFormat
    if (inputType === 'account') inputComponent = AccountFormat
    if (inputType === 'org') inputComponent = OrgFormat
    if (inputType === 'VAT') inputComponent = VATFormat

    const dispatch = useDispatch();
    const currency = useSelector(state => state.invoice.currency)

    const handleFocus = () => {
        const [slice, prop] = propName.split('.')
        if(!valid) dispatch( clearValidation({ slice, prop }))
    }

    const handleChange = (e) => {
        if (id) {
            const payload = {
                id: id,
                value: e.target.value
            }
            dispatch(handleChangeFunc( payload ))
            return
        }
        dispatch(handleChangeFunc(e.target.value))
    }

    return (
        <TextField
            sx={{
                'input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button': {
                    WebkitAppearance: 'none',
                },
                ...sx,
            }}
            fullWidth
            error={!valid}
            onFocus={handleFocus}
            onChange={handleChange}
            disabled={disabled}
            value={value}
			helperText={helperText}
            type="number"
            variant="standard"
            label={label}
            InputProps={{
                sx: { letterSpacing: '0.08em' },
                inputComponent,
                endAdornment: adorment.placement === 'end' ? <InputAdornment position="end">{adorment.label}</InputAdornment> : null
            }}
        />
    )
}

export default NumberInput