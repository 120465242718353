import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import { styled } from "@mui/material";
import { Fragment, useState } from "react";
import ProModal from "../fragments/ProModal";


const StyledBox = styled(Box, {
	shouldForwardProp: (prop) => prop !== 'fixed'
})(({theme, fixed}) => ({
	background: `linear-gradient(45deg, ${theme.palette.frame.col1}, ${theme.palette.frame.col2})`,
	width: '1.2em',
	height: '1.2em',
	padding: '5px',
	borderRadius: '50%',
	border: '3px solid white',
	cursor: 'pointer',
	...(fixed && {
		position: 'absolute',
		top: '-5px',
		right: '-5px',
	})
}))

const ProBadge = ({ fixed = false }) => {

	const [showPro, setShowPro] = useState(false)

	return (
		<Fragment>
			<ProModal showPro={showPro} handleClose={() => setShowPro(false)} />
			<StyledBox fixed={fixed} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}} onClick={() => setShowPro(true)}>
				<Typography sx={{fontSize: '1em'}} color={'misc.logo'} variant="logo">P</Typography>
			</StyledBox>
		</Fragment>
	)
}

export default ProBadge