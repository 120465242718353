import { useRef } from 'react';
import Invoice from '../components/invoice/Invoice';
import { useSelector } from 'react-redux';
import FormWrapper from '../components/FormWrapper';
import FormNavigationLogic from '../components/forms/FormNavigationLogic';
import { CURRENT_STEP, NEXT_STEP, PREVIOUS_STEP } from '../components/forms/FormSteps';

const PreviewInvoice = ()  => {

	const invoiceRef = useRef(null)

	const previousStep = PREVIOUS_STEP('preview') 
	const currentStep = CURRENT_STEP('preview')
	const nextStep = NEXT_STEP('preview')

	const invoice = useSelector(state => state.invoice)
	const user = useSelector(state => state.user)
	const customer = useSelector(state => state.customer)

	return (
		<FormWrapper heading={currentStep.heading} ref={invoiceRef}>
			<Invoice
				showFull={true}
				invoice={invoice}
				user={user}
				customer={customer}
			/>
			<FormNavigationLogic
				{...currentStep}
				invoiceRef={invoiceRef}
				previousStep={previousStep}
				nextStep={nextStep}
			/>
		</FormWrapper>
	);
}

export default PreviewInvoice;
