import { Box } from "@mui/material"


const InvoiceSection = ({children, sx = {}}) => {
	return(
		<Box sx={{marginTop: '64px !important', ...sx}}>
			{ children }
		</Box>
	)
}

export default InvoiceSection