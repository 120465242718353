export const typographyTheme = {
	typography: {
		fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
		h1: {
		  color: 'black',
		  fontWeight: '600',
		  fontSize: '2rem',
		  textAlign: 'center',
		  fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
		},
		h2: {
		  color: 'black',
		  fontWeight: '600',
		  fontSize: '1.8rem',
		  textAlign: 'center',
		  fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
		},
		h3: {
		  color: 'black',
		  fontWeight: '600',
		  fontSize: '1.8rem',
		  textAlign: 'center',
		  letterSpacing: '0.15rem',
		  lineHeight: '1.35',
		  fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
		},
		h3Thin: {
			color: 'black',
			fontWeight: '400',
			fontSize: '1.8rem',
			textAlign: 'center',
			letterSpacing: '0.15rem',
			lineHeight: '1.35',
			fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
		  },
		h4: {
		  color: 'black',
		  fontWeight: '600',
		  fontSize: '1.5rem',
		  letterSpacing: '0.1rem',
		  fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
		},
		h5: {
		  color: 'black',
		  fontWeight: '600',
		  fontSize: '1.4rem',
		  minHeight: '1.4rem',
		  fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
		},
		h6: {
		  color: 'black',
		  fontWeight: '400',
		  fontSize: '1rem',
		  lineHeight: '1.6rem',
		  minHeight: '1.6rem',
		  letterSpacing: '0.05rem',
		  fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
		},
		logo: {
			color: 'black',
			fontSize: '1.5rem',
			textAlign: 'center',
			fontFamily: 'DrunkWideBold',
		},
		p: {
		  color: 'black',
		  fontSize: '1.5rem',
		  fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
		},
		body2: {
		  color: 'black',
		  fontSize: '0.875rem',
		  minHeight: '0.875rem',
		  fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
		},
		tightText: {
		  color: 'black',
		  fontSize: '0.8rem',
		  lineHeight: '1.2rem',
		  minHeight: '0.8rem',
		  fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
		  letterSpacing: '0.01071em',
		},
		miniText: {
		  color: 'black',
		  fontSize: '0.6rem',
		  lineHeight: '1rem',
		  minHeight: '0.6rem',
		  fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
		  letterSpacing: '0.01071em',
		  textAlign: 'center',
		},
		tableHead: {
		  color: 'black',
		  fontSize: '0.8rem',
		  fontWeight: '500',
		  lineHeight: '1.2rem',
		  fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
		},
		tableContent: {
		  color: 'black',
		  fontSize: '0.8rem',
		  lineHeight: '1.2rem',
		  fontWeight: '400',
		  fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
		},
		tableTotal: {
		  color: 'black',
		  fontSize: '0.8rem',
		  lineHeight: '1.2rem',
		  fontWeight: '400',
		  fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
		},
		inputText: {
			color: 'black',
			fontSize: '1rem',
			lineHeight: '1.5rem',
			fontWeight: '400',
			fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
		  },
		buttonSm: {
			color: 'black',
			fontSize: '0.875rem',
			lineHeight: '1.2rem',
			fontWeight: '400',
			fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
			textTransform: 'none',
		},
		button: {
			color: 'black',
			fontSize: '1rem',
			lineHeight: '1.2rem',
			fontWeight: '400',
			fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
			textTransform: 'none',
		},
		buttonLg: {
			color: 'black',
			fontSize: '1.2rem',
			lineHeight: '2rem',
			fontWeight: '400',
			fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
			textTransform: 'none',
		}
	  }
}