import { Fragment } from "react";
import PageMeta from "../components/PageMeta";
import RoundOverlay from "../components/RoundOverlay";
import LoginModal from "../components/fragments/LoginModal";
import InviteModal from "../components/fragments/InviteModal";
import CreateAccountModal from "../components/fragments/CreateAccountModal";
import { Outlet } from "react-router-dom";

const PageTemplate = () => {

    return(
     	<Fragment>
			<PageMeta />
			<RoundOverlay />
			<InviteModal />
			<LoginModal />
			<CreateAccountModal />
			<Outlet />
    	</Fragment>
    )
  }

export default PageTemplate