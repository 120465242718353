import { Fragment } from 'react';
import FormWrapper from '../components/FormWrapper';
import { CURRENT_STEP, CURRENT_STEP_INDEX, NEXT_STEP, PREVIOUS_STEP } from '../components/forms/FormSteps';
import FormNavigationLogic from '../components/forms/FormNavigationLogic';
import CustomerForm from '../components/forms/CustomerForm';


const CreateInvoiceCustomer = () => {

  const previousStep = PREVIOUS_STEP('customer') 
  const currentStep = CURRENT_STEP('customer')
  const nextStep = NEXT_STEP('customer')
  const activeStepIndex = CURRENT_STEP_INDEX('customer')

  return (
    <Fragment>
      <FormWrapper heading={currentStep.heading} progress={activeStepIndex}>
        <CustomerForm />
        <FormNavigationLogic
			{...currentStep}
			previousStep={previousStep}
			nextStep={nextStep}
		/>
      </FormWrapper>
    </Fragment>
  );
}

export default CreateInvoiceCustomer;
