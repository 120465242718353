import * as React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useDispatch } from 'react-redux'
import { useNavigate } from "react-router-dom"
import { validateToSend } from '../store/miscSlice';
import { keyframes, css } from "@mui/system";
import { styled, useTheme } from '@mui/material';
import { BounceAnimation } from './animations/BounceAnimation';
import SpinningDots from './animations/SpinningDots'
import { logProgress } from '../helpers/AnalyticsHelper';
import { generatePdfBlob, generatePdfFile } from '../helpers/pdf/PdfWorker';
import { setPdfForInvoice } from '../store/invoiceActions';

const nextAnimation = keyframes`
  0%, 50%, 80%, 100% { transform: scale(1); }
  20% { transform: scale(1.2); }
  65% { transform: scale(1.1); }    
`

const StyledButton = styled(Button)(({size = 'normal', role = 'prev', theme}) => ({
  [`&:hover`]: {
    animation: `${nextAnimation} 300ms linear`,
    backgroundColor: theme.palette.pinkOrange.col1,
  },
  backgroundColor: theme.palette.primary.light,
  ...(size === 'normal' && { height: '80px', width: '80px' }),
  ...(size === 'large' && { height: '120px', width: '120px' }),
  ...(role === 'next' && { backgroundColor: 'black', color: 'white' }),
}))

function FormNavigation(props) {

  const {
	activeStep,
	showBack = true,
	nextLabel = 'Next',
	nextStep,
	prevStep,
	nextAction = false,
	doAction = false,
	action = false,
	analyticsState = {},
	stepName = '',
	saveAction = () => {},
	invoiceRef = false
	} = props

  const theme = useTheme();

  const [showLoading, setLoading] = React.useState(false)

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const animate = BounceAnimation();

  const handlePrevious = () => {
    navigate(prevStep)
  }

  const handleNext = async () => {
	console.log('handle next', nextAction);
	logProgress({ stepName: stepName, ...analyticsState })
    if (activeStep === 4) {
      const valid = await dispatch( validateToSend() )
	  console.log(valid);
    //   console.log('invalid fields', valid.payload.fields);
      if (!valid.payload.validToSend) return
		const saveComplete = await dispatch( saveAction() )
		console.log('save Complete');
		// const pdfFileComplete = await generatePdfFile(invoiceRef)
		const pdfComplete = await generatePdfBlob(invoiceRef)
		console.log('pdfComplete', pdfComplete);
		const uploaded = await dispatch( setPdfForInvoice(pdfComplete) )
		console.log(uploaded);
    }
	if (action) {
		const actionComplete = await dispatch( action() )
		console.log('actionComplete', actionComplete);
	}
    if (nextAction) {
      const actionComplete = await dispatch( nextAction() )
      console.log('actionComplete', actionComplete);
    } else if (doAction) {
      doAction()
    }
    if (activeStep === 3) {
      setLoading(true)
      setTimeout(() => {
        setLoading(false)
        navigate(nextStep)
      }, process.env.NODE_ENV === 'development' ? 0 : 1200)
      return
    }
    navigate(nextStep)
  }

  return (
    <React.Fragment>
      <Stack
	  	data-html2canvas-ignore="true"
        sx={{
          paddingTop: '20px'
        }}
        justifyContent={showBack ? 'space-between' : 'flex-end'}
        direction="row">
        { showBack ? <StyledButton
          sx={{
            fontWeight: '400',
            height: '80px',
            width: '80px',
            borderRadius: '50%',
            position: 'fixed',
            left: {
              xs: '20px',
              s: '20px',
              md: 'calc(50vw - 300px - 100px)'
            },
            bottom: '20px',
          }}
          variant="text"
          color="inherit"
          disabled={activeStep === 0}
          onClick={handlePrevious}
        >
          Back
        </StyledButton> : null }
        <StyledButton
			role={'next'}
			size={activeStep === 3 || activeStep === 4 ? 'large' : 'normal'}
          variant="text"
          color="inherit"
          sx={{
            fontWeight: '400',
            lineHeight: '1.2em',
            borderRadius: '50%',
            position: 'fixed',
            right: {
              xs: '20px',
              s: '20px',
              md: 'calc(50vw - 300px - 100px)'
            },
            bottom: '20px',
            
          }}
          onClick={handleNext}>
          { showLoading ? <SpinningDots /> : nextLabel }
        </StyledButton>
      </Stack>
    </React.Fragment>
  );
}

export default FormNavigation;
