import { Box, useTheme } from "@mui/material"
import { FrameAnimation } from "./animations/FrameAnimation"


const FluidBackgroundBox = ({sx, children}) => {
	const theme = useTheme()
	const { palette: { frame } } = theme
	const animation = FrameAnimation(`${frame.col1}, ${frame.col2}`);
	return (
		<Box sx={{
			...sx,
			...animation,
			flexDirection: 'row',
			display: 'flex',
			justifyContent: 'center',
		}}>
			{ children }
		</Box>
	)
}

export default FluidBackgroundBox