import { createAsyncThunk } from "@reduxjs/toolkit";
import { addDoc, collection } from "firebase/firestore";
import { db } from '../../Firebase'
import { getValidatedUser } from "../../helpers/auth/Authentication"

export const sendInvoiceEmail = createAsyncThunk('misc/email', async (args, thunkAPI) => {
	const { customerEmail, emailCopy } = args
	try {
		const { uid : currentUserId } = await getValidatedUser()
		const { user } = thunkAPI.getState()

		const message = {
			to: customerEmail,
			cc: user.email,
			message: {
				subject: 'An invoice from Pejme',
				html: emailCopy,
			},
			user: currentUserId,
		}
		await addDoc(collection(db, 'mail'), message)
		return {
			sent: true
		}
	} catch (error) {
		console.log(error);
		return {
			sent: false,
			error: error,
		}
	}
})