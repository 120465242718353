import { forwardRef } from "react";
import { useTheme } from "@emotion/react";
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import { FrameAnimation } from "./animations/FrameAnimation";


const Frame = forwardRef(({children}, ref) => {
	const theme = useTheme()
	const { col1, col2 } = theme.palette.frame
	const animation = FrameAnimation(`${col1}, ${col2}`)

	return(
		<Box sx={{
			boxShadow: "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;",
			padding: '15px',
			marginBottom: '120px',
			...animation,
			width: {
				xs: '100%',
				s: '100%',
				md: '600px',
			},
		}}>
			<Box
          		sx={{
            		backgroundColor: 'white',
            		paddingTop: '10px',
          		}}>
          		<Container sx={{height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', paddingTop: '10px', paddingBottom: '20px', position: 'relative'}}>
            		{ children }
          		</Container>
        	</Box>
		</Box>
	)
})

export default Frame