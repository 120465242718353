import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Typography, Stack, TextField, CircularProgress } from '@mui/material'
import { useTheme, styled } from '@mui/material';
import { FORM_PROPS } from '../../helpers/FormProps';
import { requestPasswordReset, signInUserPassword } from '../../helpers/auth/Authentication';
import { AuthContext } from '../../helpers/auth/AuthProvider';
import { AuthErrorCodes } from 'firebase/auth';
import FilledButton from '../buttons/FilledButton';
import TextButton from '../buttons/TextButton';
import FullModal from '../modals/FullModal';


const LOGIN_STATUS = {
	NOT_SENT: 'NOT_SENT',
	LOADING: 'LOADING',
	SUCCESS: 'SUCCESS',
	ERROR: 'ERROR',
	NO_USER: 'NO_USER',
	FORGOT_EMAIL: 'FORGOT_EMAIL',
	FORGOT_LOADING: 'FORGOT_LOADING',
	FORGOT_SENT: 'FORGOT_SENT',
}

const StyledTextField = styled(TextField)(({theme}) => ({
	input: {
		'&::placeholder' : {
			color: 'black',
			opacity: 1,
		},
	}
}))

const LoginModal = ({ loginSuccess = () => {} }) => {

	const authContext = useContext(AuthContext)

	const { showLogin, setShowLogin, setShowInvite } = authContext

	const [ signingIn, setSigningIn ] = useState(LOGIN_STATUS.NOT_SENT)

	const [userEmail, setUserEmail] = useState('');
	const [userPassword, setUserPassword] = useState('');

	const theme = useTheme();
	
	useEffect(() => {
		if (signingIn === LOGIN_STATUS.SUCCESS) {
			const timeout = setTimeout(() => closeModal(), 0)
			return () => {
				clearTimeout(timeout)
				loginSuccess()
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [signingIn])

	const handleSignIn = async () => {
		setSigningIn(LOGIN_STATUS.LOADING)
		try {
			const { signedIn, error } = await signInUserPassword(userEmail, userPassword)
			//No user found
			if (error === AuthErrorCodes.USER_DELETED) {
				setSigningIn(LOGIN_STATUS.NO_USER)
				return
			}
			if (error === AuthErrorCodes.USER_MISMATCH) {
				setSigningIn(LOGIN_STATUS.ERROR)
				return
			}
			if (error === AuthErrorCodes.INVALID_PASSWORD) {
				setSigningIn(LOGIN_STATUS.ERROR)
				return
			}
			if (signedIn) {
				setSigningIn(LOGIN_STATUS.SUCCESS)
			}
		} catch (error) {
			console.log('error signing in', error);	
		}
	}

	const handleForgot = async () => {
		setSigningIn(LOGIN_STATUS.FORGOT_LOADING)
		try {
			await requestPasswordReset(userEmail)
			setSigningIn(LOGIN_STATUS.FORGOT_SENT)
		} catch (error) {
			console.log('error resetting password', error);
		}
	}

	const handleRetry = () => {
		setSigningIn(LOGIN_STATUS.NOT_SENT)
	}

	const handleRequest = () => {
		setSigningIn(LOGIN_STATUS.NOT_SENT)
		setShowLogin(false)
		setShowInvite(true)
	}

	const requestForgot = () => {
		setSigningIn(LOGIN_STATUS.FORGOT_EMAIL)
	}

	const closeModal = () => {
		setSigningIn(LOGIN_STATUS.NOT_SENT)
		setShowLogin(false)
	}

	return(
		<FullModal isOpen={showLogin} onClose={closeModal} showBolts={true} >
			<Stack spacing={FORM_PROPS.ROW_SPACING}>
				{
					{
						'ERROR' :
							<Fragment>
								<Typography variant="h2" textAlign={'center'}>Error occured</Typography>
								<FilledButton
									btnStyle="rounded"
									btnColor="black"
									stretch={false}
									onClick={() => setSigningIn(LOGIN_STATUS.NOT_SENT)}>
									Try again?
								</FilledButton>
								<Typography variant={'body2'} sx={{textAlign: 'center', color: theme.palette.grey[500]}}>or</Typography>
								<TextButton size="medium" onClick={handleRequest}>
									Create profile
								</TextButton>
							</Fragment>	,
						'SUCCESS' :
							<Fragment>
								<Typography variant='h2'>Congrats!</Typography>
								<Typography variant="p" textAlign={'center'}>Sign in succesful</Typography>
							</Fragment>,
						'NO_USER' :
							<Fragment>
								<Typography variant='h2'>No user found</Typography>
								<Typography variant="p" textAlign={'center'}>We couldn't find your account</Typography>
								<FilledButton
									btnStyle="rounded"
									btnColor="black"
									stretch={false}
									onClick={handleRetry}>
									Try again?
								</FilledButton>
								<Typography variant={'body2'} sx={{textAlign: 'center', color: theme.palette.grey[500]}}>or</Typography>
								<TextButton size="medium" onClick={handleRequest}>
									Enter invite
								</TextButton>
							</Fragment>,
						'FORGOT_EMAIL':
							<Fragment>
								<Typography variant='h2'>Reset password</Typography>
								<StyledTextField
									value={userEmail}
									onChange={(e) => { setUserEmail(e.target.value)}}
									fullWidth
									label="Email"
									type="email"
									variant="standard"
									autoComplete='email'
								/>
								<FilledButton
									btnStyle="rounded"
									btnColor="black"
									stretch={false}
									disabled={!userEmail.includes('@')}
									onClick={handleForgot}
									>{ signingIn === LOGIN_STATUS.FORGOT_LOADING ? <CircularProgress size={'2.6rem'}/> : 'Reset' }
								</FilledButton>
							</Fragment>,
						'FORGOT_SENT' :
							<Fragment>
								<Typography variant='h2'>Password reset requested</Typography>
								<Typography variant="body2" textAlign={'center'}>Check your inbox for a password reset link!</Typography>
							</Fragment>,
					}[signingIn] ||
						<Fragment>
							<Typography variant='h2'>Log in</Typography>
							<StyledTextField
								value={userEmail}
								onChange={(e) => { setUserEmail(e.target.value)}}
								fullWidth
								label="Email"
								type="email"
								variant="standard"
								autoComplete='email'
							/>
							<StyledTextField
								value={userPassword}
								onChange={(e) => { setUserPassword(e.target.value)}}
								fullWidth
								label="Password"
								type="password"
								helperText={userPassword.length < 8 && userPassword.length > 4 ? 'Use a longer password!' : ''}
								variant="standard"
								autoComplete="new-password"
							/>
							<FilledButton
								btnStyle="rounded"
								btnColor="black"
								stretch={false}
								disabled={!userEmail.includes('@') || userPassword.length < 4}
								onClick={handleSignIn}
								>{ signingIn === LOGIN_STATUS.LOADING ? <CircularProgress size={'2rem'}/> : 'Login' }
							</FilledButton>
							<TextButton
								size={'medium'}
								disabled={signingIn === LOGIN_STATUS.LOADING}
								onClick={requestForgot}
								>Forgot password?
							</TextButton>
							<Typography variant={'body2'} sx={{textAlign: 'center', color: theme.palette.grey[500]}}>or</Typography>
							<TextButton
								size={'medium'}
								disabled={signingIn === LOGIN_STATUS.LOADING}
								onClick={handleRequest}
								>Create profile
							</TextButton>
						</Fragment>
				}
			</Stack>
		</FullModal>
    )
}

export default LoginModal