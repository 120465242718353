import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Typography, Stack, TextField, CircularProgress } from '@mui/material'
import { styled } from '@mui/material';
import { FORM_PROPS } from '../../helpers/FormProps';
import { createUserPassword } from '../../helpers/auth/Authentication';
import { AuthContext } from '../../helpers/auth/AuthProvider';
import { AuthErrorCodes } from 'firebase/auth';
import TextButton from '../buttons/TextButton';
import { useNavigate } from 'react-router-dom';
import FullModal from '../modals/FullModal';
import FilledButton from '../buttons/FilledButton';
import { useTheme } from '@emotion/react';


const LOGIN_STATUS = {
	NOT_SENT: 'NOT_SENT',
	LOADING: 'LOADING',
	SUCCESS: 'SUCCESS',
	ERROR: 'ERROR',
	NO_USER: 'NO_USER',
	EMAIL_EXISTS: 'EMAIL_EXISTS',
	RESET_PASSWORD: 'RESET_PASSWORD',
}

const StyledTextField = styled(TextField)(({theme}) => ({
	input: {
		'&::placeholder' : {
			color: 'black',
			opacity: 1,
		},
	}
}))

const CreateAccountModal = (props) => {

	const authContext = useContext(AuthContext)
	const navigate = useNavigate();
	const theme = useTheme();

	const { showCreateAccount, setShowCreateAccount, setShowLogin } = authContext

	const [ signingIn, setSigningIn ] = useState(LOGIN_STATUS.NOT_SENT)

	const [userEmail, setUserEmail] = useState('');
	const [userPassword, setUserPassword] = useState('');
	
	useEffect(() => {
		if (signingIn === LOGIN_STATUS.SUCCESS) {
			const timeout = setTimeout(() => closeModal(), 0)
			return () => {
				clearTimeout(timeout)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [signingIn])

	const handleSignIn = async () => {
		setSigningIn(LOGIN_STATUS.LOADING)
		try {
			const { signedIn, error } = await createUserPassword(userEmail, userPassword)
			console.log(error);
			//No user found
			if (error === AuthErrorCodes.USER_DELETED) {
				setSigningIn(LOGIN_STATUS.NO_USER)
				return
			}
			if (error === AuthErrorCodes.USER_MISMATCH) {
				setSigningIn(LOGIN_STATUS.ERROR)
				return
			}
			if (error === AuthErrorCodes.EMAIL_EXISTS) {
				setSigningIn(LOGIN_STATUS.EMAIL_EXISTS)
				return
			}
			if (signedIn) {
				setSigningIn(LOGIN_STATUS.SUCCESS)
			}
		} catch (error) {
			console.log('error signing in', error);	
		}
	}

	const goToLogin = () => {
		closeModal()
		setShowLogin(true)
	}

	const handleRetry = () => {
		setSigningIn(LOGIN_STATUS.NOT_SENT)
	}

	const closeModal = () => {
		setSigningIn(LOGIN_STATUS.NOT_SENT)
		setShowCreateAccount(false)
	}

	const closeModalAndRedirect = () => {
		closeModal()
		navigate('/')
	}

	return(
		<FullModal isOpen={showCreateAccount} onClose={closeModalAndRedirect} showBolts={true}>
			<Stack spacing={FORM_PROPS.ROW_SPACING}>
				{
					{
						'ERROR' :
							<Fragment>
								<Typography variant='h2'>An error occured.</Typography>
								<FilledButton
									btnStyle="rounded"
									btnColor="black"
									stretch={false}
									onClick={() => setSigningIn(LOGIN_STATUS.NOT_SENT)}>
									Try again?
								</FilledButton>
							</Fragment>	,
						'SUCCESS' :
							<Fragment>
								<Typography variant='h2'>😃</Typography>
								<Typography variant="body2" textAlign={'center'}>Hi there! Sign in succesful</Typography>
							</Fragment>,
						'NO_USER' :
							<Fragment>
								<Typography variant='h2'>No user found</Typography>
								<Typography variant="p" textAlign={'center'}>We couldn't find your account</Typography>
								<FilledButton
									btnStyle="rounded"
									btnColor="black"
									stretch={false}
									onClick={handleRetry}>
									Try again?
								</FilledButton>
							</Fragment>,
						'EMAIL_EXISTS' :
							<Fragment>
								<Typography variant='h2'>Already have a profile?</Typography>
								<FilledButton
									btnStyle="rounded"
									btnColor="black"
									stretch={false}
									onClick={goToLogin}>
									Login
								</FilledButton>
								<Typography variant={'body2'} sx={{textAlign: 'center', color: theme.palette.grey[500]}}>or</Typography>
								<TextButton size="medium" onClick={handleRetry}>
									Try again
								</TextButton>
							</Fragment>,
					}[signingIn] ||
						<Fragment>
							<Typography variant='h2'>Create profile</Typography>
							<StyledTextField
								value={userEmail}
								onChange={(e) => { setUserEmail(e.target.value)}}
								label="Email"
								type="email"
								variant="standard"
								autoComplete='email'
							/>
							<StyledTextField
								value={userPassword}
								onChange={(e) => { setUserPassword(e.target.value)}}
								label="Password"
								type="password"
								helperText={userPassword.length < 8 && userPassword.length > 4 ? 'Use a longer password!' : ''}
								variant="standard"
								autoComplete="new-password"
							/>
							<FilledButton
								disabled={!userEmail.includes('@')}
								btnStyle="rounded"
								btnColor="black"
								stretch={false}
								onClick={handleSignIn}
								>{ signingIn === LOGIN_STATUS.LOADING ? <CircularProgress size={'2.4rem'}/> : 'Sign up' }
							</FilledButton>
							<Typography variant={'body2'} sx={{textAlign: 'center', color: theme.palette.grey[500]}}>or</Typography>
							<TextButton onClick={goToLogin} size="medium">
								Login
							</TextButton>
						</Fragment>
				}
			</Stack>
		</FullModal>
    )
}

export default CreateAccountModal