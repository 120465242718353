import dayjs from "dayjs"
import { defaultItem, PAYMENT_OPTIONS } from "./storeTypes"
import { v4 as uuidv4 } from 'uuid';
import { currentUser } from "../helpers/auth/Authentication";

export const CUSTOMER_DEBUG_STATE = {
    id: false,
    companyName: 'Kund bolag',
    email: 'kund@mail.test',
    address: 'Kund address 12',
    postalCode: '112 45',
    city: 'Kund stad',
    country: 'SE',
    ORGnumber: '5566221123',
}

export const INVOICE_DEBUG_STATE = {
    items: [
        {
			description: 'asd',
			amountExclVAT: 810000,
			currentVAT: 12,
			discount: 0,
			itemAmount: 910000,
			itemNetAmountExclVAT: 100000,
			id: uuidv4(),
        },
        {
			description: 'asdaasddasdasdas',
			amountExclVAT: 154,
			currentVAT: 12,
			discount: 10,
			itemAmount: 172,
			itemNetAmountExclVAT: 100000,
			id: uuidv4(),
        },
    ],
    currentItem: defaultItem(),
    totalAmount: 263000,
    netAmount: 235000,
    paymentOptions: Object.assign({}, PAYMENT_OPTIONS, {
		Swish: { enabled: true, value: '0702323341', label: 'Swish' },
		BankTransferName: { enabled: true, value: 'Swedbank', label: 'Bank' },
		BankTransferClearing: { enabled: true, value: '82644', label: 'Clearing' },
		BankTransferAccount: { enabled: true, value: '437725096', label: 'Account' },
	}),
    totalVAT: {
        0: 0,
        6: 0,
        12: 28000,
        25: 0,
        total: 28000,
    },
    paymentTerms: 7,
    currency: 'SEK',
    reference: 'TestRef',
    invoiceDate: dayjs().unix(),
    dueDate: dayjs().unix(),
    invoiceNumber: 'TestNumber',
	invoiceURL: false,
    customer: false,
    user: false,
    id: false,
    status: false,
	valid: false,
}

export const USER_DEBUG_STATE = {
    companyName: 'Användare bolag AB',
    address: 'Användare address 12',
    postalCode: '112 45',
    city: 'User city',
    country: 'SE',
    email: 'user@email.com',
    VATnumber: 'SE551122334401',
    ORGnumber: '5511223344',
    avatar: {
        fullPath: '',
        thumbPath: '',
        URL: '',
        // fullPath: "avatars/764f7c6f-0c24-4e7d-b80a-9e9be35dde23",
        // thumbPath: "thumbnails/764f7c6f-0c24-4e7d-b80a-9e9be35dde23",
        // URL: "https://firebasestorage.googleapis.com/v0/b/pejme-3abaa.appspot.com/o/thumbnails%2F764f7c6f-0c24-4e7d-b80a-9e9be35dde23?alt=media&token=9a9db726-9646-4341-87e3-54c35886a7b0",
    },
    id: currentUser(),
    customers: [],
    invoices: [],
    status: '',
    uploading: false,
}