import { styled } from '@mui/material';
import Button from '@mui/material/Button';

const FilledStyleButton = styled(Button, {
	shouldForwardProp: prop => prop !== 'btnStyle' && prop !== 'stretch'
})(({theme, btncolor, btnStyle, size, stretch}) => ({
	...theme.typography.button,
	...(size === 'large' && {
		...theme.typography.buttonLg
	}),
	...(size === 'medium' && {
		...theme.typography.button
	}),
	...(size === 'small' && {
		...theme.typography.buttonSm
	}),
	backgroundColor: theme.palette.primary.contrastText,
	'&:hover': { 
		backgroundColor: theme.palette.primary.contrastText,
	 },
	 ...(btnStyle === 'rounded' && {
		borderRadius: '500px',
	 }),
	 ...(btncolor === 'black' && {
		color: theme.palette.primary.contrastText,
		backgroundColor: theme.palette.primary.dark,
		'&:hover': { 
			backgroundColor: theme.palette.primary.dark,
	 	},
	 }),
	 ...(!stretch && {
		width: 'fit-content',
		paddingLeft: '1.5em',
		paddingRight: '1.5em',
		minWidth: '200px',
		alignSelf: 'center',
	 })
}))

const FilledButton = ({ children, stretch = true, btnStyle = 'square', btnColor = "white", size = 'large', ...inputProps}) => {
	return (
		<FilledStyleButton stretch={stretch} btncolor={btnColor} btnStyle={btnStyle} size={size} {...inputProps} variant='contained'>
			{ children }
		</FilledStyleButton>
	)
}

export default FilledButton