import { Typography, Stack } from '@mui/material'
import { FORM_PROPS } from '../../helpers/FormProps';
import FullModal from '../modals/FullModal';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(relativeTime)

const PRO_VALID_TO = dayjs('2024-05-31 00:00')
const NOW = dayjs()

const ProModal = ({ showPro = true, handleClose = () => {}}) => {

	const closeModal = () => {
		handleClose()
	}

	return(
		<FullModal isOpen={showPro} onClose={closeModal}>
			<Stack spacing={FORM_PROPS.ROW_SPACING}>
				<Typography variant='h2'>You have the Superprofile</Typography>
				<Typography variant="p" textAlign={'center'}>{`Expires ${NOW.to(PRO_VALID_TO)}`}</Typography>
			</Stack>
		</FullModal>
    )
}

export default ProModal