import Dinero from 'dinero.js'
import currency from 'currency.js'

Dinero.globalLocale = 'se-SV'
Dinero.defaultCurrency = 'SEK'
Dinero.globalFormat = '0.0 SEK'

const SEK = value => currency(value, { symbol: "SEK", separator: " ", decimal: ",", precision: 0, pattern:"# !" })

const CURRENCIES = {
    SEK
}

export {
    SEK,
    CURRENCIES,
}