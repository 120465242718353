import { createTheme } from '@mui/material/styles';
import { components } from './themes/components';
import { typographyTheme } from "./themes/typography";
import { blueYellow } from "./themes/blueYellow";
import { baseColors } from "./themes/baseColors";
import { pinkOrange } from "./themes/pinkOrange";
import { brownPink } from './themes/brownPink';
import { darkblueYellow } from './themes/darkblueYellow';
import { darkRed } from './themes/darkRed';
import { orangeYellow } from './themes/orangeYellow';
import { greenBlue } from './themes/greenBlue';
import { redBlack } from './themes/redBlack';
import { blackWhite } from './themes/blackWhite';

export const THEMES = {
	'blueYellow': createTheme(blueYellow, { ...baseColors, ...components, ...typographyTheme }),
	'brownPink': createTheme(brownPink, { ...baseColors, ...components, ...typographyTheme }),
	'darkblueYellow': createTheme(darkblueYellow, { ...baseColors, ...components, ...typographyTheme }),
	'darkRed': createTheme(darkRed, { ...baseColors, ...components, ...typographyTheme }),
	'greenBlue': createTheme(greenBlue, { ...baseColors, ...components, ...typographyTheme }),
	'orangeYellow': createTheme(orangeYellow, { ...baseColors, ...components, ...typographyTheme }),
	'pinkOrange': createTheme(pinkOrange, { ...baseColors, ...components, ...typographyTheme }),
	'redBlack': createTheme(redBlack, { ...baseColors, ...components, ...typographyTheme }),
	'blackWhite': createTheme(blackWhite, { ...baseColors, ...components, ...typographyTheme }),
}

export const getTheme = (theme) => THEMES[theme]