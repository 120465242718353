import { Fragment, useEffect, useState } from 'react'
import { sendInvoiceEmail } from '../../store/actions/emailActions'
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useDispatch } from 'react-redux';
import { CircularProgress } from '@mui/material';
import { delayMsPromise } from '../../helpers/GeneralHelper';

const EMAIL_STATUS = {
	'NOT_SENT': 'NOT_SENT',
	'PENDING': 'PENDING',
	'SENT': 'SENT',
	'ERROR': 'ERROR',
}

const setInitialMessage = (invoiceId) => 
`Hi!

Your invoice is available at: https://pejme.se/invoice/${invoiceId}

Cheers,
Pejme
`

function SendAsEmail({defaultCustomerEmail = '', invoiceId = 'invoice-ID', emailSent = () => {} }){

	const dispatch = useDispatch()

	const [customerEmail, setCustomerEmail] = useState(defaultCustomerEmail)
	const [emailCopy, setEmailCopy] = useState('')
	const [emailStatus, setEmailStatus] = useState(EMAIL_STATUS.NOT_SENT)

	useEffect(() => {
		const initialMessage = setInitialMessage(invoiceId)
		setEmailCopy(initialMessage)
	}, [invoiceId])

	useEffect(() => {
		if (emailStatus === EMAIL_STATUS.SENT) {
			const waitAndSetSent = async () => {
				await delayMsPromise(1000)
				emailSent()
			}
			waitAndSetSent()
		}
	}, [emailStatus, emailSent])

	const handleSend = async () => {
		setEmailStatus(EMAIL_STATUS.PENDING)
		const { payload: { sent, error } } = await dispatch( sendInvoiceEmail({ customerEmail, emailCopy }) )
		if (sent) {
			setEmailStatus(EMAIL_STATUS.SENT)
			return
		}
		console.log('ERROR SENDING EMAIL', error);
		setEmailStatus(EMAIL_STATUS.ERROR)
	}

	const handleError = () => {
		setEmailStatus(EMAIL_STATUS.NOT_SENT)
		console.log('SHOULD HANDLE EMAIL ERROR');
	}

  return(
	<Fragment>
		<Stack spacing={3} sx={{ position: 'relative' }}>
			<TextField
				required
				error={emailStatus === EMAIL_STATUS.ERROR}
				value={customerEmail}
				onChange={e => setCustomerEmail(e.target.value)}
				label="Customer email"
				variant="standard"
			/>
			<TextField
				required
				error={emailStatus === EMAIL_STATUS.ERROR}
				value={emailCopy}
				onChange={e => setEmailCopy(e.target.value)}
				label="Message"
				variant="standard"
				multiline
				rows={6}
			/>
			{{
				'NOT_SENT': <Button onClick={handleSend}>Send email</Button>,
				'PENDING': <Button disabled={emailStatus === EMAIL_STATUS.PENDING} onClick={handleSend}><CircularProgress size={'1.5rem'}/> </Button>,
				'ERROR': <Button variant="text" color="error" onClick={handleError}>Something went wrong - Try again?</Button>,
				'SENT': <Button variant="text" color='black' onClick={() => {}}>Email sent!</Button>,
			}[emailStatus]}
		</Stack>
	</Fragment>
  )
}

export default SendAsEmail