import { v4 as uuidv4 } from 'uuid';

export const API_STATUS = {
	'PENDING': 'PENDING',
	'DONE': 'DONE',
	'ERROR': 'ERROR',
}

export const defaultItem = () => ({
    description: '',
    amountExclVAT: '', // netto ex moms med rabatt
    currentVAT: 0, // moms-sats
    discount: 0, //rabatt
    itemAmount: '', // brutto ink moms med rabatt
	itemNetAmountExclVAT: '', // netto ex moms utan rabatt
    id: uuidv4(),
  })
  
  export const TEST_ITEM = [{
    description: 'TestDescription',
    amountExclVAT: 8000,
    currentVAT: 2000,
    discount: 1000,
    itemAmount: 10000,
	itemNetAmount: 100,
  }]
  
  export const DEFAULT_VAT = {
    '0': 0,
    '6': 0,
    '12': 0,
    '25': 0,
    'total': 0,
  };
  
  export const PAYMENT_OPTIONS = {
    Bankgiro: { enabled: false, value: '', label: 'Bankgiro' },
    Swish: { enabled: false, value: '', label: 'Swish' },
    BankTransferName: { enabled: false, value: '', label: 'Bank' },
    BankTransferClearing: { enabled: false, value: '', label: 'Clearing' },
    BankTransferAccount: { enabled: false, value: '', label: 'Account' },
  }