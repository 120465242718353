import { Typography, Stack, Box, CircularProgress } from '@mui/material'
import { FORM_PROPS } from '../../helpers/FormProps';
import FullModal from '../modals/FullModal';
import FilledButton from '../buttons/FilledButton';
import TextButton from '../buttons/TextButton';
import { Fragment, useEffect, useState } from 'react';
import { removeInvoice } from '../../store/invoiceSlice';
import { useDispatch } from 'react-redux';

const REMOVE_STATUS = {
	INITIAL: 'INITIAL',
	LOADING: 'LOADING',
	SUCCESS: 'SUCCESS',
}

const ConfirmRemove = ({ isOpen, invoice = {}, handleClose = () => {}}) => {

	const [ removeStatus, setRemoveStatus ] = useState(REMOVE_STATUS.INITIAL)

	const dispatch = useDispatch()

	useEffect(() => {
		if (removeStatus === REMOVE_STATUS.SUCCESS) {
			const timeout = setTimeout(() => closeModal(), 750)
			return () => {
				clearTimeout(timeout)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [removeStatus])

	const closeModal = () => {
		setRemoveStatus(REMOVE_STATUS.INITIAL)
		handleClose()
	}

	const handleConfirm = async () => {
		setRemoveStatus(REMOVE_STATUS.LOADING)
		try {
			const removed = await dispatch( removeInvoice(invoice.id) )
			console.log('removed response', removed);
			setRemoveStatus(REMOVE_STATUS.SUCCESS)
		} catch (error) {
			console.log(error);
		}
	}

	return(
		<FullModal isOpen={isOpen} onClose={closeModal}>
			<Stack spacing={FORM_PROPS.ROW_SPACING}>
				{
					{
						'SUCCESS' :
						<Fragment>
							<Typography variant='h2'>{`Invoice removed!`}</Typography>
						</Fragment>

					}[removeStatus] ||
					<Fragment>
						<Typography variant='h2'>{`This will remove invoice #${invoice.invoiceNumber}`}</Typography>
						<FilledButton
							btnStyle="rounded"
							btnColor='black'
							stretch={false}
							onClick={handleConfirm}
							disabled={ removeStatus === REMOVE_STATUS.LOADING }
							>
							{ removeStatus === REMOVE_STATUS.LOADING ? <CircularProgress size={'2rem'} /> : 'Remove' }
						</FilledButton>
						<TextButton disabled={ removeStatus === REMOVE_STATUS.LOADING } size="medium" onClick={closeModal}>
							Back
						</TextButton>
					</Fragment>
				}
			</Stack>
		</FullModal>
    )
}

export default ConfirmRemove