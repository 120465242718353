import {
  createSlice,
  createAsyncThunk,
 } from '@reduxjs/toolkit'
import {
  addDoc,
  collection,
  doc,
  updateDoc,
  getDoc,
  setDoc,
  arrayUnion,
} from "firebase/firestore"; 
import { db } from '../Firebase';
import { CUSTOMER_DEBUG_STATE } from './debugStore';
import { clearValidationForParam, validateState } from '../helpers/ValidationHelper';
import { VALIDATE_CUSTOMER_RULES } from './validationRules';

const INITIAL_STATE = {
  id: false,
  companyName: '',
  email: '',
  address: '',
  postalCode: '',
  city: '',
  country: 'SE',
  ORGnumber: '',
  status: '', // Don't save
  stateValid: true, // Don't save
  validation: {}, // Don't save
}


const getCustomerToSave = (customerSlice) => {
	const { status, stateValid, validation, ...customerToSave } = customerSlice
	return customerToSave
}

const getPublicFields = (customerSlice) => {
	const { status, ...publicCustomer } = customerSlice
	return publicCustomer
}

const USE_STATE = INITIAL_STATE
// const USE_STATE = (process.env.NODE_ENV === 'development') ? Object.assign({}, INITIAL_STATE, CUSTOMER_DEBUG_STATE) : INITIAL_STATE

export const createOrUpdateCustomer = createAsyncThunk('customer/create', async (args, thunkAPI) => {
  try {
    const { customer, user } = thunkAPI.getState()
	let { id, ...customerToSave } = getCustomerToSave(customer)
	console.log('Saving customer', id, customerToSave, 'for user:', user.id);
    customerToSave.user = user.id
    if (id) {
      await updateDoc(doc(db, 'customers', id), customerToSave)
	  customerToSave.id = id
    } else {
      const { id: savedId } = await addDoc(collection(db, 'customers'), customerToSave)
	  customerToSave.id = savedId
    }
	const res = await setDoc(doc(db, 'entitiesForUser', user.id), { customers: arrayUnion(customerToSave.id) }, { merge: true })
    return customerToSave
  } catch (e) {
    console.log('ERROR SAVING CUSTOMER');
    console.log(e);
    console.log('----');
  }
})

export const getCustomer = createAsyncThunk('customer/get', async (customerId, thunkAPI) => {
  try {
    const docRef = doc(db, 'customers', customerId)
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const data = docSnap.data()
      return data
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  } catch (error) {
    console.log(error);
  }
})

export const customerSlice = createSlice({
  name: 'customer',
  initialState: { ...USE_STATE },
  reducers: {
	unSetCustomer: (state) => {
		state = Object.assign(state, INITIAL_STATE)
	},
	setCustomer: (state, action) => {
		state = Object.assign(state, action.payload)
	},
    setCustomerCompanyName: (state, action) => {
      state.companyName = action.payload
    },
    setEmail: (state, action) => {
      state.email = action.payload
    },
    setCity: (state, action) => {
      state.city = action.payload
    },
    setAddress: (state, action) => {
      state.address = action.payload
    },
    setPostalCode: (state, action) => {
      state.postalCode = action.payload
    },
    setCountry: (state, action) => {
      state.country = action.payload
    },
    setORGnumber: (state, action) => {
      state.ORGnumber = action.payload
    },
	validateCustomer: (state) => {
		const { stateValid, validation } = validateState(state, VALIDATE_CUSTOMER_RULES)
		state.stateValid = stateValid
		state.validation = validation
	},
	clearValidation: clearValidationForParam,
  },
  extraReducers: builder => {
    builder
      .addCase(createOrUpdateCustomer.pending, (state, action) => {
        state.status = 'LOADING';
      })
      .addCase(createOrUpdateCustomer.fulfilled, (state, action) => {
        console.log('create or update payload', action.payload);
        state.id = action.payload.id;
        state.status = 'FULFILLED';
      })
      .addCase(getCustomer.pending, (state, action) => {
        state.status = 'LOADING'
      })
      .addCase(getCustomer.fulfilled, (state, action) => {
		console.log('get customer', action.payload);
        Object.assign(state, action.payload)
        state.status = 'FULFILLED'
      })
  }
})

export const {
	unSetCustomer,
	setCustomer,
	setCustomerCompanyName,
	setEmail,
	setCity,
	setAddress,
	setPostalCode,
	setCountry,
	setORGnumber,
	validateCustomer,
	clearValidation,
} = customerSlice.actions

export const customerState = (state) => state.status;

export default customerSlice.reducer
