import Backdrop from '@mui/material/Backdrop'
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Frame from '../Frame';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const FrameModal = ({ children, isOpen = false, setIsOpen = () => { } }) => {

    const handleClose = () => {
		setIsOpen(false)
    }

    return(
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isOpen}
        >
			<ClickAwayListener onClickAway={handleClose}>
				<Frame>
					<IconButton sx={{position: 'absolute', top: '0px', right: '0px' }} onClick={handleClose}>
						<CloseIcon />
					</IconButton>
					{ children }
				</Frame>
			</ClickAwayListener>
        </Backdrop>
    )
}

export default FrameModal