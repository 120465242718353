import { useDispatch, useSelector } from "react-redux";
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import NumberInput from '../inputs/NumberInput';
import TextInput from '../inputs/TextInput';
import { FORM_PROPS, COUNTRIES_FOR_SELECT, PAYMENT_TERMS } from '../../helpers/FormProps'
import {
	setEmail,
	setAddress,
	setCity,
	setPostalCode,
	setORGnumber,
	setCountry, 
	setCustomerCompanyName} from '../../store/customerSlice'
  import {
	setInvoiceDate,
	setInvoiceNumber,
	setPaymentTerms,
	setReference,
  } from '../../store/invoiceSlice'

const CustomerForm = () => {
	const customer = useSelector(state => state.customer)
	const invoice = useSelector(state => state.invoice);
  
	const dispatch = useDispatch()

	return (
		<Stack spacing={FORM_PROPS.ROW_SPACING}>
            <DatePicker
              value={dayjs.unix(invoice.invoiceDate)}
              label="Invoice date"
              inputFormat='YYYY-MM-DD'
              disableMaskedInput
              closeOnSelect
              onChange={dayjsDate => dispatch(setInvoiceDate( dayjsDate.unix() ))}
              renderInput={(params) => <TextField {...params} />}
              />
            <Stack
              direction="row"
              spacing={FORM_PROPS.COLUMN_SPACING}>
              <TextInput
                sx={{ width: '50%' }}
				propName={'invoice.invoiceNumber'}
                value={invoice.invoiceNumber}
				valid={invoice.validation?.invoiceNumber?.valid}
				handleChangeFunc={setInvoiceNumber}
                label="Invoice number"
                variant="standard"
              />
              <FormControl
                variant="standard"
                sx={{
                  ...FORM_PROPS.SELECT_WIDTH
                }}>
                <InputLabel id="invoice-payment-terms-label">Payment terms</InputLabel>
                <Select
                  required
                  labelId="invoice-payment-terms-label"
                  value={invoice.paymentTerms}
                  onChange={e => dispatch(setPaymentTerms(e.target.value))}
                  label="Payment terms"
                  MenuProps={{ PaperProps: { sx: { maxHeight: 250 }}}}>
                  { PAYMENT_TERMS }
                </Select>
                <FormHelperText>{`Due ${dayjs.unix(invoice.dueDate).format('DD MMM YYYY')}`}</FormHelperText>
              </FormControl>
            </Stack>
            {/* Margin???!!! */}
            <TextInput
				propName={'customer.companyName'}
				valid={customer.validation?.companyName?.valid}
				handleChangeFunc={setCustomerCompanyName}
				value={customer.companyName}
				autoComplete="off"
				label="Customer"
				variant="standard"
				/>
            <TextInput
				propName={'invoice.reference'}
				valid={invoice.validation?.reference?.valid}
                sx={{marginTop: '0px'}}
                value={invoice.reference}
				handleChangeFunc={setReference}
				autoComplete="off"
                label="Contact"
                variant="standard"
              />
            <TextInput
				propName={'customer.email'}
				valid={customer.validation?.email?.valid}
				handleChangeFunc={setEmail}
				value={customer.email}
				autoComplete="email"
				label="Contact email"
				variant="standard" />
            <NumberInput
				propName='customer.ORGnumber'
				valid={customer.validation?.ORGnumber?.valid}
				handleChangeFunc={setORGnumber}
				value={customer.ORGnumber}
				label="Org. number"
				helperText="XXXXXX-XXXX"
				autocomplete="off"
				inputType='org' />
            <TextInput
				propName={'customer.address'}
				valid={customer.validation?.address?.valid}
				handleChangeFunc={setAddress}
				value={customer.address}
				autoComplete="street-address"
				label="Address"
				variant="standard" />
            <TextInput
				propName={'customer.postalCode'}
				valid={customer.validation?.postalCode?.valid}
				handleChangeFunc={setPostalCode}
				value={customer.postalCode}
				autoComplete="postal-code"
				label="Postal code"
				variant="standard" />
            <Stack
              direction="row"
              spacing={FORM_PROPS.COLUMN_SPACING}>
              <TextInput
			  	propName={'customer.city'}
				valid={customer.validation?.city?.valid}
				handleChangeFunc={setCity}
				sx={{ width: '50%' }}
				value={customer.city}
				label="City"
				autoComplete="city"
				variant="standard" />
              <FormControl
                variant="standard"
                sx={{
                  ...FORM_PROPS.SELECT_WIDTH
                }}>
                <InputLabel id="customer-country-label">Country</InputLabel>
                <Select
                  required
                  labelId="customer-country-label"
                  value={customer.country}
                  onChange={e => dispatch(setCountry(e.target.value))}
                  label="Country"
                  MenuProps={{ PaperProps: { sx: { maxHeight: 250 }}}}
                  >
                  { COUNTRIES_FOR_SELECT }
                </Select>
              </FormControl>
            </Stack>
          </Stack>
	)	
}

export default CustomerForm