import { createAsyncThunk } from "@reduxjs/toolkit";
import { ref, uploadBytes } from 'firebase/storage'
import { v4 as uuidv4 } from 'uuid';
import { avatarRef } from "../Firebase";
import { awaitFileURLFromPath } from "../helpers/UploadHelper";

const uploadAvatar = async (file, authId) => {
    try {
        console.log('uploading', file);
        const objectUUID = uuidv4();
        const fileRef = ref(avatarRef, `${authId}/${objectUUID}`);
        console.log('avatarFile', file);
        const snapshot = await uploadBytes(fileRef, file.arrayBuffer, { contentType: file.meta.type })
        console.log('snapshot', snapshot);
        return {
            thumbnailPath: snapshot.metadata.fullPath.replace("avatars", "thumbnails"),
            fullPath: snapshot.metadata.fullPath,
        };

    } catch (error) {
        console.log(error);
    }
}

export const saveAvatar = createAsyncThunk('user/saveAvatar', async (args, thunkAPI) => {
    try {
        console.log(args);
        const { id } = thunkAPI.getState().user
        const uploadedFile = await uploadAvatar(args, id)
        const thumbnailDataURL = await awaitFileURLFromPath(uploadedFile.thumbnailPath)
        return { ...uploadedFile, URL: thumbnailDataURL ? thumbnailDataURL : false }
    } catch (error) {
        console.log(error);
    }
})

export const setPremadeAvatar = createAsyncThunk('user/setPremadeAvatar', async (args, thunkAPI) => {
	try {
		console.log(args);
		const origin = window.location.origin
		return {
			URL: `${origin}${args}`,
		}
	} catch (error) {
		console.log(error);
	}
})