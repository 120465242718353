import CircularProgress from '@mui/material/CircularProgress';
import Container from "@mui/material/Container";

const SPINNER_SIZE = '80px'

const FullscreenSpinner = () => {
	return (
		<Container
			sx={{
				position: 'absolute',
				top: '0',
				bottom: '0',
				left: '0',
				right: '0',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			}}>
			<CircularProgress size={ SPINNER_SIZE }/>
		</Container>
	)
}

export default FullscreenSpinner