import { styled } from '@mui/material';
import { TextField } from '@mui/material'

const StyledTextField = styled(TextField)(({theme}) => ({
	input: {
		['&::placeholder'] : {
			color: 'black',
			opacity: 1,
		},
	}
}))

const ModalTextInput = ({
	disabled = false,
	value,
	onChange = () => {},
	onKeyDown = () => {},
	placeholder = '',
	type = '',
	variant = 'standard',
	autoComplete = 'off',
	InputProps = {},
}) => {
	return (
		<StyledTextField
			disabled={disabled}
			value={value}
			onChange={onChange}
			onKeyDown={onKeyDown}
			fullWidth
			placeholder={placeholder}
			type={type}
			variant={variant}
			autoComplete={autoComplete}
			InputProps={InputProps}
		/>
	)
}

export default ModalTextInput