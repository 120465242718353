import { Typography } from '@mui/material'
import InvoiceSection from './invoice/InvoiceSection'

function OrgDetails(props){
    const { companyName, address, postalCode, city, country, email, VATnumber, ORGnumber } = props

    return (
		<InvoiceSection sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', }}>
            <Typography variant="miniText">{`${companyName ? companyName + ' - ' : ''}${address ? address + ' - ' : ''}${postalCode ? postalCode + ' - ' : ''}${city ? city + ' - ' : ''}${country ? country + ' - ' : ''}${ORGnumber ? 'Org\u00A0no.\u00A0' + ORGnumber + ' - ' : ''}${VATnumber ? 'VAT\u00A0no.\u00A0' + VATnumber + ' - ' : ''}${email ? email : ''}`}</Typography>
		</InvoiceSection>
    )
}

export default OrgDetails