import { Fragment, useEffect } from 'react'
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import LogoURL from '../../images/p_icon.png'
import LargeLogoURL from '../../images/large_p_icon.png'
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';

const LogoTitle = () => {
	return(
	<Fragment>
		<Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' }}}>
			<img src={LargeLogoURL} />
		</Box>
		<Box sx={{ display: { sm: 'block', md: 'none' }}}>
			<img src={LogoURL} />
		</Box>
	</Fragment>)
}

const TextTitle = () => {
	return(
		<Typography variant='logo'>{'PEJME'}</Typography>
	)
}

const NavMid = ({ navTitle = false }) => {

	const navigate = useNavigate();

	return(
		<Link onClick={() => navigate('/')} sx={{
			img: {
			  height: '60px',
			},
			cursor: 'pointer',
			justifySelf: 'center',
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			}}>
			{/* <LogoTitle /> */}
			<TextTitle />
		</Link>
	)
}

export default NavMid