import { styled } from '@mui/material';
import Button from '@mui/material/Button';

const TextStyleButton = styled(Button,{
	shouldForwardProp: (prop) => prop !== 'textAlign'
})(({theme, textAlign, typography, size}) => ({
	...theme.typography.button,
	textDecoration: 'underline',
	'&:hover': { textDecoration: 'underline' },
	...(textAlign === 'left' && {
		justifyContent: 'flex-start',
	}),
	...(typography === 'p' && {
		...theme.typography.buttonLg,
	}),
	...(size === 'large' && {
		...theme.typography.buttonLg
	}),
	...(size === 'medium' && {
		...theme.typography.button
	}),
	...(size === 'small' && {
		...theme.typography.buttonSm
	}),
}))

const TextButton = ({ children, typography, size = 'medium', textAlign = 'center', ...inputProps}) => {
	
	return (
		<TextStyleButton {...inputProps} size={size} typography={typography} variant="text" textAlign={textAlign}>
			{ children }
		</TextStyleButton>
	)
}

export default TextButton