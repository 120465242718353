import LogoSelect from '../LogoSelect/LogoSelect';
import { COUNTRIES_FOR_SELECT, FORM_PROPS } from '../../helpers/FormProps'
import Stack from '@mui/material/Stack';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import TextInput from '../../components/inputs/TextInput';
import NumberInput from '../../components/inputs/NumberInput';
import InputLabel from '@mui/material/InputLabel';
import { useSelector, useDispatch } from 'react-redux'
import {
	setCompanyName,
	setUserAddress,
	setUserPostalCode,
	setUserCountry,
	setUserCity,
	setUserEmail,
	setUserVATnumber,
	setUserORGnumber,
} from '../../store/userSlice'

const UserForm = () => {

	const user = useSelector(state => state.user);
  
	const dispatch = useDispatch();

	return (
		<Stack spacing={FORM_PROPS.ROW_SPACING}>
			<LogoSelect />
			{/* <LogoButton style="brightStyle" size="small" position="right"/> */}
			<TextInput
				propName={'user.companyName'}
				valid={user.validation.companyName?.valid}
				value={user.companyName}
				handleChangeFunc={setCompanyName}
				label="Company name"
				autoComplete="off"
				variant="standard"
				/>
			<TextInput
				propName={'user.email'}
				valid={user.validation.email?.valid}
				value={user.email}
				handleChangeFunc={setUserEmail}
				label="Email"
				autoComplete="email"
				variant="standard"
				/>
			<TextInput
				propName={'user.address'}
				valid={user.validation.address?.valid}
				value={user.address}
				handleChangeFunc={setUserAddress}
				label="Address"
				autoComplete="street-address"
				variant="standard"
				/>
			<Stack direction="row" spacing={FORM_PROPS.COLUMN_SPACING}>
				<TextInput
				propName={'user.postalCode'}
				valid={user.validation.postalCode?.valid}
				value={user.postalCode}
				handleChangeFunc={setUserPostalCode}
				label="Postal code"
				autoComplete="postal-code"
				variant="standard"
				sx={{ width: '50%'}}
				/>
				<FormControl
					variant="standard"
					// error={!user.validation.country?.valid}
					sx={{
						...FORM_PROPS.SELECT_WIDTH
					}}>
					<InputLabel id="user-country-label">Country</InputLabel>
					<Select
						required
						labelId="user-country-label"
						value={user.country}
						onChange={e => dispatch(setUserCountry(e.target.value))}
						label="Country"
						MenuProps={{ PaperProps: { sx: { maxHeight: 250 }}}}
						>
						{ COUNTRIES_FOR_SELECT }
					</Select>
				</FormControl>
			</Stack>
			<TextInput
				propName={'user.city'}
				valid={user.validation.city?.valid}
				value={user.city}
				handleChangeFunc={setUserCity}
				label="City"
				autoComplete="home city"
				variant="standard"
				/>
			<Stack direction="row" spacing={FORM_PROPS.COLUMN_SPACING}>
				<NumberInput
					required
					propName={'user.ORGnumber'}
					valid={user.validation.ORGnumber?.valid}
					handleChangeFunc={setUserORGnumber}
					label="Org. number"
					helperText="XXXXXX-XXXX"
					value={user.ORGnumber}
					inputType="org"
				/>
				<TextInput
					required
					propName={'user.VATnumber'}
					valid={user.validation.VATnumber?.valid}
					handleChangeFunc={setUserVATnumber}
					label="VAT number"
					helperText="SE + Org. number + 01"
					value={user.VATnumber}
					sx={{ width: '50%'}}
				/>
			</Stack>
		</Stack>
	)
}

export default UserForm