import { useDispatch, useSelector } from "react-redux"
import FullModal from "../modals/FullModal"
import { Box, Stack, Typography, useMediaQuery } from "@mui/material"
import { hideValidationOverlay } from "../../store/miscSlice"
import { CURRENT_STEP } from "../forms/FormSteps"
import { useNavigate } from "react-router-dom"
import TextButton from "../buttons/TextButton"
import { useTheme } from "@emotion/react"

// const StyledListItem = styled(ListItem)((theme) => ({ }))

// const StyledListItemText = styled(ListItemText)(({theme}) => ({ ...theme.typography.p, }))

// const ValidationItem = ({ item, index }) => {
// 	return (
// 	<StyledListItem key={`${index}-list-item`}>
// 		<StyledListItemText primary={item.step} primaryTypographyProps={{variant: 'p', sx: { textAlign: 'center'}}}/>
// 	</StyledListItem>)
// }

const ValidationModal = ({ forceNext }) => {

	const showValidationOverlay = useSelector(state => state.misc.showValidationOverlay)

	const theme = useTheme();
	const onMobile = useMediaQuery(theme.breakpoints.down("sm"))

	const userValid = useSelector(state => state.user.stateValid)
	const customerValid = useSelector(state => state.customer.stateValid)
	const invoiceValid = useSelector(state => state.invoice.stateValid)

	const validationItems = [
		{ step: 'user', valid: userValid },
		{ step: 'customer', valid: customerValid },
		{ step: 'items', valid: invoiceValid }
	]

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const handleGoToError = () => {
		const firstError = validationItems.find(item => !item.valid)
		const firstStepWithError = CURRENT_STEP(firstError.step)
		dispatch( hideValidationOverlay() )
		navigate(firstStepWithError.stepUrl)
	}

	const handleClose = () => {
		dispatch( hideValidationOverlay() )
	}

	const handleForceSend = async () => {
		dispatch( hideValidationOverlay() )
		forceNext()
	}

	return (
		<FullModal modalSize="narrow" isOpen={showValidationOverlay} showClose={false} setIsOpen={handleClose}>
			<Stack spacing={3}>
				<Typography variant='h2'>Heads up!</Typography>
				<Typography variant="p" sx={{textAlign: 'center'}}>Your invoice looks incomplete..</Typography>
				<Stack
					direction={onMobile ? 'column' : 'row'}
					spacing={onMobile ? 2 : ''}
					sx={{
						justifyContent: 'space-between',
						paddingLeft: onMobile ? '' : '5em',
						paddingRight: onMobile ? '' : '5em',
					}}>
					<TextButton
						typography={'p'}
						onClick={handleGoToError}>
							Edit
					</TextButton>
					<TextButton
						typography={'p'}
						onClick={handleForceSend}>
							Send anyway
					</TextButton>
				</Stack>
			</Stack>
		</FullModal>
	)
}

export default ValidationModal