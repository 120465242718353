import { Box, styled } from "@mui/material";
import ProBadge from "./ProBadge";

const StyledBox = styled(Box)(({theme, size, border}) => ({
	width: '80px',
	height: '80px',
	position: 'relative',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	...(size === 'large' && {
		width: '100px',
		height: '100px',
	}),
	...(border && {
		borderColor: theme.palette.primary.dark,
		borderStyle: 'solid',
		borderWidth: '1px',
		borderRadius: '50%',
	})
}))

const Logo = ({ avatar = {}, size = 'normal', showPro = false, border = false, children }) => {
	return(
		<StyledBox
			size={ size }
			border={border}
			sx={{
				borderRadius: '50%',
				backgroundImage: `url(${avatar.URL})`,
				backgroundRepeat: 'no-repeat',
				backgroundPosition: '50% 50%',
				backgroundSize: 'cover',
			}}
		>
			{ showPro && <ProBadge fixed={true} /> }
			{ children }
		</StyledBox>
	)
}

export default Logo