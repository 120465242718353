import { IS_EMAIL, IS_ORG, IS_STRING, IS_VAT, IS_ARRAY, IS_NUMBER, IS_SWISH } from '../helpers/ValidationHelper';

const PAYMENT_VALIDATION = {
	'Swish': IS_SWISH,
	'BankTransferAccount': IS_NUMBER,
	'BankTransferClearing': IS_NUMBER,
	'BankTransferName': IS_STRING,
	'Bankgiro': IS_NUMBER,
}

const paymenOptionValidation = (val) => {
	for (const key in val) {
		const item = val[key]
		if (item.enabled) {
			const ruleToTest = PAYMENT_VALIDATION[key]
			return ruleToTest(item.value)
		}
		return true
  }
}

export const VALIDATE_INVOICE_RULES = {
	items: {
		req: true,
		func: IS_ARRAY,
	  },
	  totalAmount: {
		req: true,
		func: IS_NUMBER,
	  },
	  paymentOptions: {
		req: true,
		func: paymenOptionValidation,
	  },
	  invoiceNumber: {
		req: true,
		func: IS_STRING,
	  },
}

export const VALIDATE_USER_RULES = {
    companyName: {
		req: true,
		func: IS_STRING,
	},
	email: {
		req: true,
		func: IS_EMAIL,
	},
	address: {
		req: true,
		func: IS_STRING,
	},
	postalCode: {
		req: true,
		func: IS_STRING,
	},
	city: {
		req: true,
		func: IS_STRING,
	},
	ORGnumber: {
		req: true,
		func: IS_ORG,
	},
	VATnumber: {
		req: true,
		func: IS_VAT,
	},
	country: {
		req: true,
		func: IS_STRING,
	},
	defaultPaymentOptions: {
		req: true,
		func: paymenOptionValidation,
	}
}

export const VALIDATE_CUSTOMER_RULES = {
	companyName: {
		req: true,
		func: IS_STRING,
	  },
	  email: {
		req: true,
		func: IS_STRING,
	  },
	  address: {
		req: true,
		func: IS_STRING,
	  },
	  postalCode: {
		req: true,
		func: IS_STRING,
	  },
	  city: {
		req: true,
		func: IS_STRING,
	  },
	  ORGnumber: {
		req: true,
		func: IS_ORG,
	  },
}