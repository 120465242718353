import { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InvoiceArticles from '../InvoiceArticles'
import NumberInput from '../inputs/NumberInput'
import TextButton from '../buttons/TextButton';
import { FORM_PROPS } from '../../helpers/FormProps'
import { setCurrentItem, removeItem, createItem, setDescription, setItemNetAmountExclVAT, setCurrentVAT, setDiscount, saveItem } from '../../store/invoiceSlice';
import FilledButton from '../buttons/FilledButton';

const VAT_OPTIONS = [
	<MenuItem key="VAT-0" value={0}>0%</MenuItem>,
	<MenuItem key="VAT-6" value={6}>6%</MenuItem>,
	<MenuItem key="VAT-12" value={12}>12%</MenuItem>,
	<MenuItem key="VAT-25" value={25}>25%</MenuItem>,
  ]

const ItemForm = () => {

	const dispatch = useDispatch();
	
	const invoice = useSelector(state => state.invoice)
	const currentItem = invoice.currentItem

	const itemAmount = Math.round( currentItem.itemNetAmountExclVAT * (1 + currentItem.currentVAT / 100) * (1 - currentItem.discount / 100) )

	const selectItem = (itemId) => {
		dispatch(setCurrentItem(itemId))
	}
	
	const deleteItem = (itemId) => {
		dispatch( removeItem(itemId) )
	}
	
	const shouldCreateItem = () => {
		dispatch( createItem() )
	}

	return (
        <Stack spacing={FORM_PROPS.ROW_SPACING}>
          <InvoiceArticles showHeader={true} selectItem={selectItem} addItem={shouldCreateItem} {...invoice} />
          { invoice.currentItem.id ? <Fragment>
          <TextField
            onChange={e => dispatch(setDescription(e.target.value))}
            value={invoice.currentItem.description}
            label="Description"
            variant="standard"
            />
          <Stack
            direction="row"
            spacing={FORM_PROPS.COLUMN_SPACING}
            alignItems="center">
            <NumberInput
              handleChangeFunc={setItemNetAmountExclVAT}
              label={'Amount excl VAT'}
              value={invoice.currentItem.itemNetAmountExclVAT}
              adorment={{label: invoice.currentItem.itemNetAmountExclVAT.length > 0 ? invoice.currency : '', placement: 'end'}}
            />
            <FormControl variant="standard"
              sx={{
                ...FORM_PROPS.SELECT_WIDTH
              }}>
              <InputLabel id="VAT-input-label">VAT</InputLabel>
              <Select
                labelId="VAT-input-label"
                value={invoice.currentItem.currentVAT}
                onChange={e => dispatch(setCurrentVAT(e.target.value))}
                label="VAT"
                >
                { VAT_OPTIONS }
              </Select>
            </FormControl>
          </Stack>
          <Stack
            direction="row"
            spacing={FORM_PROPS.COLUMN_SPACING}
            justifyContent="space-between"
            alignItems="center">
            <NumberInput
              handleChangeFunc={setDiscount}
              value={invoice.currentItem.discount}
              label="Discount"
              adorment={{label: '%', placement: 'end'}}
            />
            <NumberInput
              disabled
              value={itemAmount}
              label="Amount"
              adorment={{label: invoice.currency, placement: 'end'}}
            />
          </Stack>
          <Stack
            direction="row"
            spacing={FORM_PROPS.COLUMN_SPACING}
            justifyContent="flex-end">
            { invoice.currentItem.itemAmount > 0 ? 
              <TextButton
                disabled={itemAmount <= 0}
                sx={{
                  	justifySelf: 'end',
                }}
                onClick={() => {
                  dispatch( deleteItem( invoice.currentItem.id ) )
                }}>
                  Remove
              </TextButton>
            : null }
			<FilledButton
        btnStyle='rounded'
        size='medium'
        btnColor='black'
        sx={{ alignSelf: 'end' }}
        disabled={itemAmount <= 0}
				onClick={() => { dispatch( saveItem( invoice ) )}}
				>
				Save item
			</FilledButton>
          </Stack>
          </Fragment> :
		<FilledButton
      btnStyle='rounded'
      size='medium'
      btnColor='black'
      sx={{ alignSelf: 'end' }}
			disabled={!!invoice.currentItem.id}
			onClick={shouldCreateItem}>
			Add item
		</FilledButton> }
        </Stack>
	)
}

export default ItemForm