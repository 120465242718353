import * as React from 'react';
import Stack from '@mui/material/Stack';
import { Helmet } from 'react-helmet-async'
import Typography from '@mui/material/Typography';
import StartButton from '../components/StartButton';
import Box from '@mui/material/Box';
import CenterWrap from '../components/CenterWrap';


function About() {

  return (
    <React.Fragment>
      <Helmet>
        <title>Pejme | About</title>
      </Helmet>
      <Box sx={{
          flexDirection: 'row',
          display: 'flex',
          justifyContent: 'center',
        }}>
        <CenterWrap>
          <Stack spacing={4} sx={{padding: '20px', paddingTop: '60px',
        paddingBottom: '60px'}}>
            <Typography variant='h1'>About us</Typography>
            <Typography variant='h2'>Important stuff made simple ... and good looking!</Typography>
            <Typography variant='p'>We are on a mission to offer a whole set of productivity tools that prevent you from doing the work that you love the most.</Typography>
            <Typography variant='h6' align="center">Empowering the self-employed mastermind!</Typography>
            <StartButton />
          </Stack>
        </CenterWrap>
      </Box>
    </React.Fragment>
  )
}

export default About;
