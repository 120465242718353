import { Box, keyframes, useTheme } from "@mui/material"


const rotateCircle = keyframes`
0% { transform: rotate(0); }
25% { transform: rotate(90deg); }
50% { transform: rotate(180deg); }
75% { transform: rotate(270deg); }
100% { transform: rotate(360deg); }
`
const OUTER_STYLE = {
	backgroundImage: "linear-gradient(rgba(0,0,0,1) 0%, rgba(0,0,0,1) 59%, rgba(255,255,255,1) 60%, rgba(255,255,255,1) 100%)",
	animation: `${rotateCircle} 2s linear infinite`,
	padding: '1px',
	borderRadius: '50%',
}

const INNER_STYLE = {
	background: 'white',
	animation: `${rotateCircle} 2s linear reverse infinite`,
	borderRadius: '50%',
}

const SMALL_SIZE = {
	width: '80px',
	height: '80px',
}

const LARGE_SIZE = {
	width: '100px',
	height: '100px',
}

const AVATAR_STYLE = {
	bgcolor: 'white',
	borderRadius: '50%',
	color: 'black',
}

function LogoTemplate({ style = 'dark', size = 'small', selected = false, loading = false, children, sx = {} }){
	
	const theme = useTheme()

	const BORDER_STYLE = {
		borderColor: theme.palette.primary.dark,
		borderStyle: 'solid',
		borderWidth: '1px',
		borderRadius: '50%',
	}

	const logoStyle = Object.assign({}, (size === 'large' ? LARGE_SIZE : SMALL_SIZE))
	const avatarStyle = Object.assign({}, AVATAR_STYLE)

	const borderStyle = Object.assign({}, selected ? BORDER_STYLE : {})
	const outerStyle = Object.assign({}, loading ? OUTER_STYLE : {})
	const innerStyle = Object.assign({}, loading ? INNER_STYLE : {})

	return (
		<Box sx={{...logoStyle, flexShrink: '0', ...sx, padding: '5px'}}>
			<Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', ...borderStyle, ...outerStyle}}>
				<Box sx={{display: 'flex', justifyContent: 'center', width: '100%', height: '100%', ...innerStyle}}>
					{ children }
				</Box>
			</Box>
		</Box>
	)
}

export default LogoTemplate