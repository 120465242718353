import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { validateUser } from "./userSlice";
import { validateCustomer } from "./customerSlice";
import { validateInvoice } from "./invoiceSlice";

const DEFAULT_POSITION = {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
}

const DEFAULT_DIRECTION = {
    xDirection: {
        min: 0,
        max: -2
    },
    yDirection: {
        min: -5,
        max: -8
    }
}


export const validateToSend = createAsyncThunk('misc/validate', async (args, thunkAPI) => {
	
	const user = thunkAPI.dispatch( validateUser() )
	const customer = thunkAPI.dispatch( validateCustomer() )
	const invoice = thunkAPI.dispatch( validateInvoice() )

    const state = thunkAPI.getState();

	console.log('validateToSend');
	console.log(user, customer,invoice);
	console.log(state);

	console.log('ALL FIELDS');
})


export const miscSlice = createSlice({
    name: 'misc',
    initialState: {
		showValidationOverlay: false,
        showOverlay: false,
        overlayMsg: '',
		overlayMsgArr: [],
        // overlayMsg: 'Here is what we believe is missing or faulty:\n- your info\n- your info\n',
        showConfetti: false,
        position: { ...DEFAULT_POSITION },
        direction: { ...DEFAULT_DIRECTION },
        validToSend: false,
        validation: { },
    },
    reducers: {
        showConfetti: (state, action) => {
            state.showConfetti = true
            state.position = {
                x: action.payload.position.x,
                y: action.payload.position.y,
                width: action.payload.position.width,
                height: action.payload.position.height,
            }
            state.direction = action.payload.direction
        },
		clearValidation: () => { /* Used for matching validation  */ },
        hideConfetti: (state, action) => {
            state.showConfetti = false
            state.position = DEFAULT_POSITION
            state.direction = DEFAULT_DIRECTION
        },
		showValidationOverlay: (state) => {
			state.showValidationOverlay = true
		},
		hideValidationOverlay: (state) => {
			state.showValidationOverlay = false
		},
		clearValidationForParam: () =>{ },
        showOverlay: (state, action) => {
            state.showOverlay = true
            state.overlayMsg = action.payload.message
            state.overlayMsgArr = action.payload.messageArr
        },
        hideOverlay: (state, action) => {
            state.showOverlay = false
            state.overlayMsg = ''
			state.overlayMsgArr = []
        },
		initInvoice: (state, action) => { },
        resetStore: (state, action) => { },
    },
    extraReducers: builder => {
        builder.addCase(validateToSend.fulfilled, (state, action) => {
            state.validation = action.payload.fields
            state.validToSend = action.payload.validToSend
        })
    }
})

export const {
    showConfetti,
    hideConfetti,
	clearValidationForParam,
	showValidationOverlay,
	hideValidationOverlay,
    showOverlay,
    clearValidation,
    hideOverlay,
	initInvoice,
    resetStore,
} = miscSlice.actions

export default miscSlice.reducer