import BurgerMenu from './BurgerMenu';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Stack from '@mui/material/Stack';
import NavMid from './NavMid';

function NavigationBar({ navTitle }) {

  return (
    <AppBar
      color="inherit"
      elevation={0}
      position="sticky">
      <Toolbar sx={{
        justifyContent: 'center',
        flexDirection: 'row',
        a: { textDecoration: 'none' },
        }}>
		<NavMid navTitle={navTitle} />
        <Stack
          direction="row"
          sx={{
            alignItems: 'center',
            position: 'absolute',
            right: '6px',
          }}
          >
          <BurgerMenu />
        </Stack>
      </Toolbar>
    </AppBar>
  );
}

export default NavigationBar;
