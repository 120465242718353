import { createListenerMiddleware } from "@reduxjs/toolkit";
import { invoiceRemoved } from "../invoicesSlice";

const invoicesMiddleware = createListenerMiddleware()

invoicesMiddleware.startListening({
	type: 'invoice/remove/fulfilled',
	effect: async (action, listenerApi) => {
		listenerApi.dispatch( invoiceRemoved(action.payload) )		
	}
})

export default invoicesMiddleware;