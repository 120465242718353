import { Helmet } from "react-helmet-async";

function PageMeta(){
    return (
    <Helmet>
        <meta charSet="utf-8" />
        <title>Pejme | Invoices made easy</title>
        <meta name="description" content="Pejme makes invoicing easy!" />
    </Helmet>
    )
}

export default PageMeta