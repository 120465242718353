import { useContext, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ContactPageOutlinedIcon from '@mui/icons-material/ContactPageOutlined';
import { NAV_PATHS, AUTH_NAV_PATHS } from '../../AppRouter';
import { AuthContext } from '../../helpers/auth/AuthProvider';
import { logOut } from '../../helpers/auth/Authentication';



const BurgerMenu = () => {

	const { authenticated, setShowLogin } = useContext(AuthContext)

    const [anchorEl, setAnchorEl] = useState(null);

    const navigate = useNavigate();
  
    const open = Boolean(anchorEl);
  
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    }
    const handleClose = () => {
      setAnchorEl(null)
    }
    const handleMenuClick = (route) => {
      handleClose();
      navigate(route);
    }
	const handleSignOut = () => {
		logOut()
	}

    return (
      <>
        <IconButton onClick={handleClick}>
        	<MenuIcon sx={{ fill: 'black', display:{ sm: 'block', md: 'none'}}} />
        </IconButton>
		{ authenticated ?
			[
				AUTH_NAV_PATHS.map((item, index) =>
					<MenuItem key={`menu-${index}`} sx={{display:{ xs: 'none', sm: 'none', md: 'block'}}} onClick={() => {handleMenuClick(item.path)}}>{item.label}</MenuItem>
				),
				<MenuItem key={`menu-login`} sx={{display:{ xs: 'none', sm: 'none', md: 'block'}}} onClick={() => {navigate('/invoices')}}>
					<ContactPageOutlinedIcon sx={{verticalAlign: 'middle'}} />
				</MenuItem>,
				<MenuItem key={`menu-logout`} sx={{display:{ xs: 'none', sm: 'none', md: 'block'}}} onClick={() => {handleClose(); handleSignOut();}}>
					Logout
				</MenuItem>
			]
			:
			[
				NAV_PATHS.map((item, index) =>
					<MenuItem key={`menu-${index}`} sx={{display:{ xs: 'none', sm: 'none', md: 'block'}}} onClick={() => {handleMenuClick(item.path)}}>{item.label}</MenuItem>
				),
				<MenuItem key={`menu-login`} sx={{display:{ xs: 'none', sm: 'none', md: 'block'}}} onClick={() => {setShowLogin(true)}}>
					Login
				</MenuItem>,
			]
		}
        <Menu anchorEl={anchorEl} onClose={handleClose} open={open}>
			{ authenticated ?
				[
					AUTH_NAV_PATHS.map((item, index) =>
						<MenuItem key={`menu-${index}`} onClick={() => {handleMenuClick(item.path)}}>{item.label}</MenuItem>
					),
					<MenuItem key={`menu-login`} onClick={() => {handleClose(); navigate('/invoices');}}>
						Profile
					</MenuItem>,
					<MenuItem key={`menu-logout`} onClick={() => {handleClose(); handleSignOut();}}>
						Logout
					</MenuItem>
				]
				:
				[
					NAV_PATHS.map((item, index) =>
						<MenuItem key={`menu-${index}`} onClick={() => {handleMenuClick(item.path)}}>{item.label}</MenuItem>
					),
					<MenuItem key={`menu-login`} onClick={() => {handleClose(); setShowLogin(true);}}>
						Login
					</MenuItem>
				]
			}
        </Menu>
      </>
    )
  }


  export default BurgerMenu;