import html2pdf from "html2pdf.js";

const PDF_CONFIG = {
	margin:       [20, 10, 10, 10],
	filename:     'invoice.pdf',
	html2canvas: {
		scale: 5,
		allowTaint: false,
		useCORS: true,
	},
	jsPDF: {
		orientation: 'portrait',
		format: 'a4',
		unit: 'mm',
	}
  };

export const generatePdfBlob = async (elementRef) => {

	const pdfFile = await html2pdf()
		.set(PDF_CONFIG)
		.from(elementRef.current)
		.toPdf()
		.output('blob')

	return pdfFile
}

export const generatePdfFile = async (elementRef) => {

	console.info('Generating PDF file with', PDF_CONFIG);

	const pdfFile = await html2pdf()
		.set(PDF_CONFIG)
		.from(elementRef.current)
		.toPdf()
		.save()

	console.info('done..!!', pdfFile);

	return pdfFile
}