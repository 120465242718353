import { createOrUpdateCustomer } from "../../store/customerSlice"
import { generateInvoice } from "../../store/invoiceActions"
import { createOrUpdateInvoice } from "../../store/invoiceSlice"
import { createOrUpdateUser, signInUserAnon } from "../../store/userSlice"

const FALLBACK_STEP = {
	stepName: 'fallback',
	stepUrl: '/',
}

const LAST_STEP = {
	stepName: 'last',
	stepUrl: '/',
}

export const ADMIN_FORM_STEPS_LOGIC = [
	{
		heading: 'Your profile',
		stepName: 'user',
		stepUrl: '/edit-profile',
		nextLabel: 'Save',
		showBack: false,
		action: createOrUpdateUser,
	},
	{
		heading: 'Payment details',
		stepName: 'billing',
		stepUrl: '/edit-billing',
		nextLabel: 'Save',
		showBack: false,
		action: createOrUpdateUser,
	},
	{
		heading: 'Your invoices',
		stepName: 'invoices',
		stepUrl: '/invoices',
	}
]

const FORM_STEPS_LOGIC = [
	{
		heading: 'Your details',
		stepName: 'user',
		stepUrl: '/create',
		showBack: false,
		action: signInUserAnon,
	},
	{
		heading: 'Customer details',
		stepName: 'customer',
		stepUrl: '/customer',
		action: createOrUpdateUser,
	},
	{
		heading: 'Invoice details',
		stepName: 'items',
		stepUrl: '/item',
		action: createOrUpdateCustomer,
	},
	{
		heading: 'Payment details',
		stepName: 'billing',
		stepUrl: '/billing',
		nextLabel: 'Preview',
		nextButtonSize: 'large',
		action: createOrUpdateInvoice,
	},
	{
		heading: 'Your super-🔥 invoice is ready!',
		stepName: 'preview',
		stepUrl: '/preview',
		nextLabel: 'Share',
		nextButtonSize: 'large',
		action: generateInvoice,
	},
	{
		heading: 'Share invoice',
		stepName: 'share',
		stepUrl: '/share',
		showBack: false,
		nextLabel: 'Back to start',
	}
]

export const CURRENT_STEP_INDEX = (step) => FORM_STEPS_LOGIC.findIndex((el) => el.stepName === step)

export const CURRENT_STEP = (step) => {
	return Object.assign({}, FORM_STEPS_LOGIC.find((el) => el.stepName === step))
}

export const GET_ADMIN_STEP = (step) => {
	return Object.assign({}, ADMIN_FORM_STEPS_LOGIC.find((el) => el.stepName === step))
}

export const PREVIOUS_STEP = (step) => {
	const currentStep = FORM_STEPS_LOGIC.findIndex((el) => el.stepName === step)
	if (currentStep === 0) return FALLBACK_STEP
	return FORM_STEPS_LOGIC[currentStep - 1]
}

export const NEXT_STEP = (step) => {
	const currentStep = FORM_STEPS_LOGIC.findIndex((el) => el.stepName === step)
	if (currentStep === FORM_STEPS_LOGIC - 1) return LAST_STEP
	return FORM_STEPS_LOGIC[currentStep + 1]
}

export const FORM_STEPS = FORM_STEPS_LOGIC.slice(0,4).map((el) => el.heading)

export { FORM_STEPS_LOGIC }