import { forwardRef } from "react"
import { PatternFormat } from "react-number-format"

export const OrgFormat = forwardRef(function OrgFormat(props, ref) {
    const { onChange, ...other } = props

    return (
        <PatternFormat
            {...other}
            getInputRef={ref}
            format="######-####"
            displayType="input"
            type="text"
            valueIsNumericString={true}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value
                    }
                })
            }}
        />
    )
})