import { Container, Typography } from '@mui/material'
import { Fragment } from 'react'
import LightningBolt from '../components/login/LightningBolt'
import NavigationBar from '../components/navbar/NavigationBar'
import PageMeta from '../components/PageMeta'

const ErrorPage = () => {
	return (
		<Fragment>
			<PageMeta />
			<NavigationBar />
			{/* <LightningBolt /> */}
			<LightningBolt orientation='left'/>
			<Container>
				<Typography>An error occured</Typography>
			</Container>
		</Fragment>
	)
}

export default ErrorPage