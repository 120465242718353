import { useContext } from 'react';
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { useState } from 'react';
import Button from '@mui/material/Button';
import { useSelector } from 'react-redux'
import { AuthContext } from '../../helpers/auth/AuthProvider';
import { useNavigate } from 'react-router-dom';
import SendAsEmail from './SendAsEmail';
import ShareAsLink from './ShareAsLink';
import ContactPageOutlinedIcon from '@mui/icons-material/ContactPageOutlined';
import TextButton from '../buttons/TextButton';
import RoundButton from '../buttons/RoundButton';


function Share(props) {

	const { setShowCreateAccount, authenticated } = useContext(AuthContext);
	const navigate = useNavigate();

	const [copied, setCopied] = useState(false);
	const [selected, setSelected] = useState('link');

	const invoiceId = useSelector(state => state.invoice.id)
	const invoicePDFUrl =  useSelector(state => state.invoice.invoiceURL)
	const customerEmail = useSelector(state => state.customer.email)

	const shareURL = `${window.location.origin}/invoice/${invoiceId}`

	const handleCopy = () => {
		setCopied(true)
	}

	const handleShowCreate = () => {
		setShowCreateAccount(true)
	}

	const handleGoToAccount = () => {
		navigate('/invoices')
	}

  	return (
		<Stack spacing={2} sx={{display: 'flex', flexDirection: 'column'}}>
			{ process.env.NODE_ENV === 'development' ? <Box sx={{
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			}}>
				<Button
					disabled={selected==='email'}
					sx={{textDecoration: 'underline'}}
					onClick={() => { setSelected('email')}}
					color="inherit"
					>Send as email
				</Button>
				<Button
					disabled={selected==='link'}
					sx={{textDecoration: 'underline'}}
					onClick={() => { setSelected('link')}}
					color="inherit"
					>Share as link
				</Button>
			</Box> : null }
			{ process.env.NODE_ENV === 'development' ? <Divider variant="middle" /> : null }
			{ selected === 'email' ?
			<SendAsEmail defaultCustomerEmail={customerEmail} invoiceId={invoiceId}/> :
			<ShareAsLink
				shareURL={shareURL}
				pdfURL={invoicePDFUrl}
				handleCopy={handleCopy}
				copied={copied}
			/>}
			<Divider variant="middle" />
			{ authenticated ?
				<TextButton onClick={handleGoToAccount}>Go to invoices<ContactPageOutlinedIcon sx={{margin: '10px'}} /></TextButton>
			: <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: '20px',}}>
				<Typography variant="h5" textAlign={'left'}>Keep track of all your invoices?</Typography>
				<RoundButton btnColor='pink' size="'normal"
					onClick={handleShowCreate}
				>Create profile</RoundButton>
			</Box> }
		</Stack>
	);
}

export default Share;
