import { createBrowserRouter } from "react-router-dom";
import PageTemplate from "./pages/PageTemplate";
import PageElements from "./pages/PageElements";
import { logPageview } from "./helpers/AnalyticsHelper";
import CreateInvoice from './pages/CreateInvoice'
import CreateInvoiceCustomer from './pages/CreateInvoiceCustomer'
import CreateInvoiceItem from './pages/CreateInvoiceItem'
import PreviewInvoice from './pages/PreviewInvoice'
import ShowInvoice from './pages/ShowInvoice'
import ShareInvoice from './pages/ShareInvoice'
import Home from './pages/Home'
import VerifyLink from "./pages/VerifyLink";
import About from "./pages/About";
import Why from "./pages/Why";
import Invoices from './pages/Invoices'
import ComingSoon from "./pages/ComingSoon";
import Team from "./pages/Team";
import CreateInvoiceBilling from "./pages/CreateInvoiceBilling";
import ErrorPage from "./pages/ErrorPage";
import ProtectedRoute from "./helpers/auth/ProtectedRoute";
import EditProfile from "./pages/EditProfile";
import AuthenticatedRoute from "./helpers/auth/AuthenticatedRoute";
import store from "./store/store";
import { fetchInvoice } from "./store/invoiceActions";
import { fetchInvoices } from "./store/invoicesSlice";
import SelectCustomer from "./pages/SelectCustomer";
import { getCustomersForUser, validateUser } from "./store/userSlice";
import EditDefaultBilling from "./pages/EditDefaultBilling";
import { getValidatedUser } from "./helpers/auth/Authentication";
import ResetPassword from "./pages/ResetPassword";

export const NAV_PATHS = [
  { path: '/why', label: "Why?" },
  { path: '/start', label: "Create an invoice" },
]

export const AUTH_NAV_PATHS = [
	{ path: '/why', label: "Why?" },
	{ path: '/select-customer', label: "Create an invoice" },
  ]

export const router = createBrowserRouter([
  {
    path: '/',
    element: <PageTemplate />,
	children: [
		{
			path: '/',
			element: <PageElements canSelectTheme={true} footer><Home /></PageElements>,
			loader: () => { logPageview({
				page_title: 'Home',
				page_location: window.location.origin + '/'
			}) },
		},
		{
			path: "/about",
			element: <PageElements footer><About /></PageElements>,
			loader: () => { logPageview({
			  page_title: 'About',
			  page_location: window.location.origin + '/about'
			}) },
		  },
		  {
			path: "/why",
			element: <PageElements footer><Why /></PageElements>,
			loader: () => { logPageview({
			  page_title: 'Why',
			  page_location: window.location.origin + '/why'
			}) },
		  },
		  {
			path: "/team",
			element: <PageElements footer><Team /></PageElements>,
			loader: () => { logPageview({
			  page_title: 'Team',
			  page_location: window.location.origin + '/team'
			}) },
		  },
		  {
			path: "/pricing",
			element: <PageElements footer><ComingSoon /></PageElements>,
			loader: () => { logPageview({
			  page_title: 'Pricing',
			  page_location: window.location.origin + '/pricing'
			}) },
		  },
		  {
			path: "/start",
			element:
				<PageElements canSelectTheme={true}>
					<ProtectedRoute>
						<CreateInvoice />
					</ProtectedRoute>
				</PageElements>,
			loader: () => { 
			  logPageview({
				page_title: 'Invoice | Start',
				page_location: window.location.origin + '/start'
			  })
			},
		  },
		  {
			path: "/edit-profile",
			element:
				<PageElements canSelectTheme={true} navTitle={'Your details'}>
					<ProtectedRoute>
						<EditProfile />
					</ProtectedRoute>
				</PageElements>,
			loader: () => {
				logPageview({
					  page_title: 'Invoice | Start',
					  page_location: window.location.origin + '/start'
				})
			},
		  },
		  {
			path: "/edit-billing",
			element:
				<PageElements canSelectTheme={true} navTitle={'Payment details'}>
					<ProtectedRoute>
						<EditDefaultBilling />
					</ProtectedRoute>
				</PageElements>,
			loader: () => {
				logPageview({
					  page_title: 'Invoice | Start',
					  page_location: window.location.origin + '/start'
				})
			},
		  },
		  {
			path: "/create",
			element:
				<PageElements canSelectTheme={true} navTitle={'Your details'}>
					<ProtectedRoute>
						<CreateInvoice />
					</ProtectedRoute>
				</PageElements>,
			loader: () => {
				logPageview({
					  page_title: 'Invoice | Start',
					  page_location: window.location.origin + '/start'
				})
			},
		  },
		  {
			path: "/invoices",
			element:
				<PageElements footer>
					<AuthenticatedRoute>
						<Invoices />
					</AuthenticatedRoute>
				</PageElements>,
			loader: async () => {
				logPageview({
					  page_title: 'Invoice | Item',
					  page_location: window.location.origin + '/item'
				})
				await getValidatedUser()
				store.dispatch( fetchInvoices() )
			},
		  },
		  {
			path: '/select-customer',
			element:
				<PageElements canSelectTheme={true} navTitle={'Select customer'}>
					<AuthenticatedRoute>
						<SelectCustomer />
					</AuthenticatedRoute>
				</PageElements>,
			loader: async () => {
				store.dispatch( getCustomersForUser() )
				store.dispatch( validateUser() )
			}
		  },
		  {
			path: "/item",
			element:
				<PageElements canSelectTheme={true}>
					<ProtectedRoute>
						<CreateInvoiceItem />
					</ProtectedRoute>
				</PageElements>,
			loader: () => { logPageview({
			  page_title: 'Invoice | Item',
			  page_location: window.location.origin + '/item'
			}) },
		  },
		  {
			path: "/customer",
			element:
				<PageElements canSelectTheme={true}>
					<ProtectedRoute>
						<CreateInvoiceCustomer />
					</ProtectedRoute>
				</PageElements>,
			loader: () => { logPageview({
			  page_title: 'Invoice | Customer',
			  page_location: window.location.origin + '/customer'
			}) },
		  },
		  {
			path: "/billing",
			element:
				<PageElements canSelectTheme={true}>
					<ProtectedRoute>
						<CreateInvoiceBilling />
					</ProtectedRoute>
				</PageElements>,
			loader: () => { logPageview({
			  page_title: 'Invoice | Billing',
			  page_location: window.location.origin + '/billing'
			}) },
		  },
		  {
			path: "/preview",
			element:
				<PageElements canSelectTheme={true}>
					<ProtectedRoute>
						<PreviewInvoice />
					</ProtectedRoute>
				</PageElements>,
			loader: () => { logPageview({
			  page_title: 'Invoice | Preview',
			  page_location: window.location.origin + '/preview'
			}) },
		  },
		  {
			path: "/share",
			element:
				<PageElements>
					<ProtectedRoute>
						<ShareInvoice />
					</ProtectedRoute>
				</PageElements>,
			loader: () => { logPageview({
			  page_title: 'Invoice | Share',
			  page_location: window.location.origin + '/share'
			}) },
		  },
		  {
			path: "/verifylink",
			element:
				<PageElements>
					<ProtectedRoute>
						<VerifyLink />
					</ProtectedRoute>
				</PageElements>,
			loader: () => { logPageview({
			  page_title: 'Verify link',
			  page_location: window.location.origin + '/verifylink'
			}) },
		  },
		  {
			path: "/forgot-password",
			element:
				<PageElements>
					<ResetPassword />
				</PageElements>,
			loader: () => { logPageview({
			  page_title: 'Reset password',
			  page_location: window.location.origin + '/reset-password'
			}) },
		  },
		  {
			path: "/invoice/:invoiceId",
			element: <PageElements navbar={false}><ShowInvoice /></PageElements>,
			loader: async ({ params: { invoiceId }}) => {
				logPageview({
					  page_title: 'View Invoice',
					  page_location: window.location.origin + '/invoice'
				});
				store.dispatch( fetchInvoice(invoiceId) )
			},
		  },
	]
  },
  {
    path: "/404",
    element: <PageTemplate navbar={false}><ErrorPage /></PageTemplate>,
    loader: () => { logPageview({
      page_title: '404 Error',
      page_location: window.location.origin + '/404'
    }) },
  },
])