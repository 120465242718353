import { Box } from "@mui/material"

function PremadeLogo({ source, setImage }){
	return (
		<Box
			sx={{
				borderRadius: '50%',
				width: '100%',
				height: '100%',
				backgroundImage: `url(${source})`,
				backgroundSize: 'contain'
			}}
			onClick={() => setImage(source)}
		/>
	)
}

export default PremadeLogo