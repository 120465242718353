export const IS_SET = (val) => val && val.length > 0 ? true : false

export const IS_STRING = (val) => val.length > 0

export const IS_ARRAY = (val) => val.length > 0

export const IS_NUMBER = (val) => val > 0

export const IS_SWISH = (val) => new RegExp(/^[0-9]{10}$/).test(val)

export const IS_EMAIL = (val) => new RegExp(/^\S+@\S+\.\S+$/).test(val)

export const IS_ORG = (val) => new RegExp(/^[0-9]{10}$/).test(val)

export const IS_VAT = (val) => new RegExp(/^SE[0-9]{10}01$/).test(val)

export const clearValidationForParam = (state, action) => {
	const { payload } = action
	let { validation, stateValid } = state
	delete validation[payload]
	if (Object.keys(validation).length === 0) { stateValid = true}
	state.validation = validation
	state.stateValid = stateValid
}

export const validateState = (state = {}, validationRules = {}) => {
	try {
		let nonValidFields = { };
		let stateValid = true;
		for (const field in validationRules) {
			if (Object.hasOwnProperty.call(validationRules, field)) {
				const validate = validationRules[field];
				const toValidate = state[field];
				const valid = validate.func(toValidate)
				if (!valid) {
					nonValidFields[field] = { valid: false }
					stateValid = false
				}
			}
		}
		return {
			stateValid,
			validation: nonValidFields,
		}
	} catch (error) {
		console.log(error);
		return {
			valid: false,
			validation: {},
		}
	}
}