import { Fragment } from "react"
import { styled } from '@mui/material';
import { keyframes } from "@mui/system";
import Stack from "@mui/material/Stack"
import Button from '@mui/material/Button';
import SpinningDots from "../animations/SpinningDots";

const debugClicked = () => { console.info('Clicked Form navigation'); }

const nextAnimation = keyframes`
  0%, 50%, 80%, 100% { transform: scale(1); }
  20% { transform: scale(1.2); }
  65% { transform: scale(1.1); }    
`

const StyledButton = styled(Button)(({size = 'normal', role = 'prev', placement = 'right', disabled = false, theme}) => ({
	[`&:hover`]: {
	  animation: `${nextAnimation} 300ms linear`,
	  backgroundColor: theme.palette.pinkOrange.col1,
	},
	backgroundColor: theme.palette.primary.light,
	borderRadius: '50%',
	position: 'fixed',
	bottom: '20px',
	...(placement === 'left' && {
		[theme.breakpoints.up("xs")]: { left: '20px' },
		[theme.breakpoints.up("s")]: { left: '20px' },
		[theme.breakpoints.up("md")]: { left: 'calc(50vw - 300px - 100px)' }
	}),
	...(placement === 'right' && {
		[theme.breakpoints.up("xs")]: { right: '20px' },
		[theme.breakpoints.up("s")]: { right: '20px' },
		[theme.breakpoints.up("md")]: { right: 'calc(50vw - 300px - 100px)' }
	}),
	...(size === 'normal' && { height: '80px', width: '80px' }),
	...(size === 'large' && { height: '120px', width: '120px' }),
	...(role === 'next' && { backgroundColor: 'black', color: 'white' }),
	...(disabled && { })
  }))

const FormNavigationButtons = (props) => {
	const {
		showBack,
		showNext,
		showLoading = false,
		nextLabel = 'Next',
		size = 'normal',
		backDisabled = 'false',
		handlePrevious = debugClicked,
		handleNext = debugClicked
	} = props;
	
	return (
		<Fragment>
			<Stack
				data-html2canvas-ignore="true"
				sx={{ paddingTop: '20px' }}
				justifyContent={showBack ? 'space-between' : 'flex-end'}
        		direction="row">
				{ showBack ?
					<StyledButton
						placement='left'
						color="inherit"
						disabled={backDisabled}
						onClick={handlePrevious}
					>
					Back
					</StyledButton>
				: null }
				{ showNext ?
				<StyledButton
					role={'next'}
					color="inherit"
					size={size}
					// size={activeStep === 3 || activeStep === 4 ? 'large' : 'normal'}
					onClick={handleNext}
					>
					{ showLoading ? <SpinningDots /> : nextLabel }
				</StyledButton>
				: null }
			</Stack>
		</Fragment>
	)
}

export default FormNavigationButtons