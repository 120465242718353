import { BounceAnimation } from "../animations/BounceAnimation";
import Typography from '@mui/material/Typography';
import { useTheme } from "@mui/material";


const BASIC_STYLE = { textTransform: 'uppercase', lineHeight: '1.2', textAlign: 'center' }
const FAT_STYLE = { fontWeight: '800', fontSize: '1.2rem' }

const YourLogo = () => {
	const animation = BounceAnimation();
	const theme = useTheme();
	return (
		<>
			<Typography color={theme.palette.primary.dark} sx={BASIC_STYLE}>Your</Typography>
			<Typography color={theme.palette.primary.dark} sx={BASIC_STYLE}>logo</Typography>
			<Typography color={theme.palette.primary.dark} sx={{ ...animation, ...BASIC_STYLE, ...FAT_STYLE }}>here</Typography>
		</>
	)
}

export default YourLogo