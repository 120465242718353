import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { PhoneNumberFormat } from '../inputs/formats/PhoneNumberFormat';
import { AmountFormat } from '../inputs/formats/AmountFormat';
import { BankgiroFormat } from '../inputs/formats/BankgiroFormat';
import { ClearingFormat } from '../inputs/formats/ClearingFormat';
import { AccountFormat } from '../inputs/formats/AccountFormat';
import { formLabelClasses, Grid, Paper, Table, TableCell, TableContainer, TableRow } from '@mui/material';
import { styled } from '@mui/system';

const StyledCell = styled(TableCell)(({theme, size = '', showDiscount = false}) => ({
	// border: '1px solid blue',
	backgroundColor: 'white',
	width: { '' : '', 'left' : '50%', 'right' : '25%' }[size]
}))

const ItemRow = ({ value, label, showDiscount }) => {
	return (
		<TableRow>
			<StyledCell padding="none" size="left">{ label }</StyledCell>
			<StyledCell padding="none">{ value }</StyledCell>
			{ showDiscount ? <StyledCell padding="none" /> : null }
		</TableRow>
	)
}

function PaymentMethods({paymentOptions, style = 'thin', showDiscount}){

  
	const labels = [], values = []
    
    Object.entries(paymentOptions).forEach(([key, val]) => {
      if (val.enabled) {
		let label, value;
        const textStyle = val.style === 'bold' ? { fontWeight: 600 } : {}
        const renderText = (itemValue) => <Typography variant="body2" sx={{...textStyle}}>{itemValue}</Typography>
        label = <Typography variant="body2" key={key} sx={{...textStyle}}>{`${val.label}`}</Typography>
        if (val.label === 'Swish') {
          value = <PhoneNumberFormat
            key={key}
            value={val.value}
            displayType="text"
            renderText={renderText}
          />
        } else if (key === 'Bankgiro') {
          value = <BankgiroFormat
            key={key}
            value={val.value}
            displayType="text"
            renderText={renderText}
          />
        } else if (key === 'BankTransferName') {
          value = <ClearingFormat
            key={key}
            value={val.value}
            displayType="text"
            renderText={renderText}
          />
        } else if (key === 'BankTransferAccount') {
          value = <AccountFormat
            key={key}
            value={val.value}
            displayType="text"
            renderText={renderText}
          />
        } else {
          value = <Typography variant="body2" key={key} sx={{...textStyle}}>{val.value}</Typography>
        }
		values.push(value)
		labels.push(label)
		// rows.push(<ItemRow showDiscount={showDiscount} label={label} value={value} />)
      }
    })

	const leftCol = showDiscount ? 5 : 6
	const rightCol = 12 - leftCol
    return (
		<Box>
			<Typography variant="h6">Payment info</Typography>
			<Grid
				sx={{marginTop: '8px'}}
				direction="row"
				alignItems={'flex-start'}
				justifyContent={'space-between'}
				container
				spacing={1}>
				<Grid item xs={leftCol} sm={6}>
					{ labels.map((item, index) => item)}
				</Grid>
				<Grid item xs={rightCol} sm={6}>
					{ values.map((item, index) => item)}
				</Grid>
			</Grid>
		</Box>
    )
  }

  export default PaymentMethods