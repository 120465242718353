import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit'
import {
	addDoc,
  collection,
  doc,
  getDoc,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { getDownloadURL } from 'firebase/storage';
import { db, signInAnonymously, storageRef, auth } from '../Firebase';
import { currentUser } from '../helpers/auth/Authentication';
import { ref } from 'firebase/storage'
import { saveAvatar, setPremadeAvatar } from './logoActions';
import { USER_DEBUG_STATE } from './debugStore';
import { clearValidationForParam, validateState } from '../helpers/ValidationHelper';
import { API_STATUS, PAYMENT_OPTIONS } from './storeTypes';
import { VALIDATE_USER_RULES } from './validationRules';

const INITIAL_STATE = {
  companyName: '',
  address: '',
  postalCode: '',
  city: '',
  country: 'SE',
  email: '',
  VATnumber: '',
  ORGnumber: '',
  avatar: {
    URL: false,
    fullPath: false,
    thumbnailPath: false,
  },
  id: currentUser(),
  customers: [], // Private
  invoices: [], // Private
  defaultPaymentOptions: { ...PAYMENT_OPTIONS },
  defaultTheme: 'pinkOrange',
  status: '', // Don't save
  userFetchStatus: API_STATUS.DONE, // Don't save
  uploading: false, // Don't save
  stateValid: true, // Don'' save
  validation: {}, // Don't save
}



const getUserToSave = (userSlice) => {
	const { status, uploading, stateValid, validation, ...userToSave } = userSlice
	return userToSave
}

const getPrivateFields = (userSlice) => {
	const { customers, invoices, id, ...publicUser } = userSlice
	return { customers, invoices, id }
}

const getPublicFields = (userSlice) => {
	const { customers, invoices, status, uploading, user, ...publicUser } = userSlice
	return publicUser
}

const USE_STATE = INITIAL_STATE
// const USE_STATE = (process.env.NODE_ENV === 'development') ? Object.assign({}, INITIAL_STATE, USER_DEBUG_STATE) : INITIAL_STATE

export const createOrUpdateUser = createAsyncThunk('user/create', async (args, thunkAPI) => {
  try {
    const { user } = thunkAPI.getState();
    let { id, ...userToSave } = getUserToSave(user)

	await setDoc(doc(db, 'users', id), userToSave)
	userToSave.id = id
	
	return userToSave
  } catch (error) {
    console.log('ERROR SAVING USER', error);
  }
})

export const signInUserAnon = createAsyncThunk('user/signInAnon', async (args, thunkAPI) => {
	try {
		console.log('SING IN ANON');
		const currentUserId = currentUser()
		if (currentUserId) {
			console.info('Already signed in user:', currentUserId);
			return currentUserId
		}
		const { user } = thunkAPI.getState()
		const userToSave = getUserToSave(user);
		const { user: signedInUser } = await signInAnonymously(auth)
		console.info('Signed in anon with user:', signedInUser.uid);
		await setDoc(doc(db, 'users', signedInUser.uid), userToSave)
		return signedInUser.uid
	} catch (error) {
		console.log('ERROR SIGNING IN');
        console.log(error);
	}
})

const getCustomerPromise = async (customerId) => {
	try {
		const docRef = doc(db, 'customers', customerId)
		const docSnap = await getDoc(docRef)
		if (docSnap.exists()) {
			return {
				id: docSnap.id,
				...docSnap.data()
			}
		} else {
			return {}
		}
	} catch (error) {
		console.log(error);
	}
}

export const getCustomersForUser = createAsyncThunk('user/getCustomers', async (args, thunkAPI) => {
	try {
		const currentUserId = currentUser()
		const docRef = doc(db, 'entitiesForUser', currentUserId)
		const entities = await getDoc(docRef)
		const { customers } = entities.data()
		const populatedCustomers = await Promise.all(customers.map((customerId) => getCustomerPromise(customerId)))
		return populatedCustomers
	} catch (error) {
		console.log('getCustomersForUser', error);
	}
})

export const getUser = createAsyncThunk('user/get', async (userId, thunkAPI) => {
  try {
	if (!userId) return {}
    const docRef = doc(db, 'users', userId)
    const docSnap = await(getDoc(docRef))
    if (docSnap.exists()) {
      const data = docSnap.data()
      return data
    } else {
      console.log('No such user');
    }
  } catch (error) {
    console.log(error);
  }
})

export const getAvatar = createAsyncThunk('user/getAvatar', async (arg, thunkAPI) => {
  try {
    const hasAvatar = thunkAPI.getState().user.avatar?.URL
    console.log('hasAvatar', hasAvatar);
    if (!hasAvatar) {
      const avatarPath = thunkAPI.getState().user.avatar?.path
      const avatarStorage = ref(storageRef, avatarPath)
      const waitMS = 60 * 1000
      const tryImage = async () => {
        console.log('tryImage');
        const fileURL = await getDownloadURL(avatarStorage)
        console.log('tryimage', fileURL);
        return fileURL
      }
      const testURL = setTimeout(tryImage, waitMS)
      console.log('testURL', testURL);
      return testURL
    }
    console.log(hasAvatar);

  } catch (error) {
    console.log(error);
  }
})

export const userSlice = createSlice({
  name: 'user',
  initialState: { ...USE_STATE },
  reducers: {
	setDefaultPaymentOptions: (state, action) => {
		state.defaultPaymentOptions = action.payload
	},
	setDefaultPaymentOption: (state, action) => {
		const paymentOpt = {
		  enabled: action.payload.value ? true : false,
		  value: action.payload.value,
		  label: PAYMENT_OPTIONS[action.payload.id].label,
		} 
		state.defaultPaymentOptions[action.payload.id] = paymentOpt
	  },
	setDefaultTheme: (state, action) => {
		state.defaultTheme = action.payload
	},
    setCompanyName: (state, action) => {
      state.companyName = action.payload
    },
    setUserAddress: (state, action) => {
      state.address = action.payload
    },
    setUserPostalCode: (state, action) => {
      state.postalCode = action.payload
    },
    setUserCountry: (state, action) => {
      state.country = action.payload
    },
    setUserCity: (state, action) => {
      state.city = action.payload
    },
    setUserEmail: (state, action) => {
      state.email = action.payload
    },
    setUserVATnumber: (state, action) => {
      state.VATnumber = action.payload
    },
    setUserORGnumber: (state, action) => {
      state.ORGnumber = action.payload
    },
    login: (state, action) => {
      state.user = action.payload
    },
    logout: (state) => {
      state.user = null
    },
    setAvatar: (state, action) => {
      state.avatar.path = action.payload.path
    },
    removeAvatar: (state, action) => {
      state.avatar = {
        URL: false,
        fullPath: false,
        thumbnailPath: false,
      }
    },
	addCustomerForUser: (state, action) => {
		let { customers } = state
		customers.indexOf(action.payload) === -1 ? customers.push(action.payload) : console.log('customer already added to user')
		state.customers = customers
	},
    setCustomersForUser: (state, action) => {
      let customers = state.customers
      state.customers = customers.concat([action.payload])
    },
    setInvoicesForUser: (state, action) => {
      let invoices = state.invoices
      state.invoices = invoices.concat(action.payload)
    },
    setUserId: (state, action) => {
      state.id = action.payload
    },
    unsetUserId: (state) => {
      state.id = null
    },
	validateUser: (state) => {
		const { stateValid, validation } = validateState(state, VALIDATE_USER_RULES)
		console.log('Validate user:', stateValid, validation);
		state.stateValid = stateValid
		state.validation = validation
	},
	clearValidation: clearValidationForParam,
  },
  extraReducers: builder => {
    builder
      .addCase(createOrUpdateUser.pending, (state, action) => {
        state.status = 'LOADING'
      })
      .addCase(createOrUpdateUser.fulfilled, (state, action) => {
        state.status = 'FULFILLED'
      })
      .addCase(getUser.pending, (state, action) => {
        state.status = API_STATUS.PENDING
      })
      .addCase(getUser.fulfilled, (state, action) => {
        Object.assign(state, action.payload)
        state.status = API_STATUS.DONE
      })
	  .addCase(getUser.rejected, (state, action) => {
		state.status = API_STATUS.ERROR
	  })
      .addCase(saveAvatar.pending, (state, action) => {
        state.uploading = true
      })
      .addCase(saveAvatar.fulfilled, (state, action) => {
        console.log(action);
        state.uploading = false
        state.avatar.thumbnailPath = action.payload.thumbnailPath
        state.avatar.fullPath = action.payload.fullPath
        state.avatar.URL = action.payload.URL
      })
	  .addCase(saveAvatar.rejected, (state, action) => {
		state.uploading = false
	  })
	  .addCase(setPremadeAvatar.fulfilled, (state, action) => {
		console.log(action);
		state.avatar.URL = action.payload.URL
	  })
	  .addCase(signInUserAnon.pending, (state, action) => {

	  })
	  .addCase(signInUserAnon.rejected, (state, action) => {
		
	  })
	  .addCase(signInUserAnon.fulfilled, (state, action) => {
		state.id = action.payload
	  })
	  .addCase(getCustomersForUser.fulfilled, (state, action) => {
		state.customers = action.payload
	  })
  }
})

export const {
	setDefaultPaymentOptions,
	setDefaultPaymentOption,
	setDefaultTheme,
	setCompanyName,
	setUserAddress,
	setUserPostalCode,
	setUserCity,
	setUserCountry,
	setUserEmail,
	setUserVATnumber,
	setUserORGnumber,
	setAvatar,
	removeAvatar,
	login,
	logout,
	setCustomersForUser,
	setInvoicesForUser,
	setUserId,
	unsetUserId,
	validateUser,
	clearValidation,
} = userSlice.actions

export default userSlice.reducer
