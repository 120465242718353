import { Fragment } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { CURRENCIES } from '../../helpers/MoneyHelper'
import { Grid, useTheme, styled } from '@mui/material';
import { isInt } from '../../helpers/GeneralHelper';
import InvoiceSection from './InvoiceSection';

const StyledGrid = styled(Grid)(({theme, variant}) => ({
	'&.MuiGrid-item': {
		paddingTop: variant === 'tableContent' ? '5px' : '0px',
		paddingBottom: variant === 'tableContent' ? '5px' : '0px',
	}
}))

const TableItem = ({variant, content, style = 'normal', sx = {}, align = 'left'}) => {
	const textAlign = align === 'left' ? { textAlign: 'left' } : { textAlign: 'right' }
	const theme = useTheme()
	const fontStyle = {
		'bold': { fontWeight: 600 },
		'italic': { fontStyle: 'italic' },
		'fade': { color: theme.palette.grey[600] },
	}[style] || {}
	return <Typography variant={variant} sx={{...sx, ...textAlign, ...fontStyle, marginBottom: 0, marginTop: 0 }} paragraph={true}>{content}</Typography>
}

const COLUMN_WIDTHS = (noItems, showDiscount) => {
	switch (noItems) {
		case 2:
			if (showDiscount) return { 'xs': [8, 4], 'sm': [10, 2] }
		case 3:
			if (showDiscount) return { 'xs': [5, 3, 4], 'sm': [6, 4, 2] }
			return { 'xs': [6,6], 'sm': [6,6] }
		default:
			return { 'xs': [6,6], 'sm': [6,6] }
	}
}

const TableHeadRow = ({items = [], showDiscount = false}) => {
	const columnWidth = COLUMN_WIDTHS(items.length, showDiscount)
	let align = 'left'
	return(
		<Fragment>
			{ items.map((item, index, arr) => {
				let sx = {}
				const hideColumn = columnWidth['xs'][index] === 0 ? { xs: 'none', sm: 'block'} : { xs: 'block', sm: 'block' }
				if (index === arr.length - 1) {
					align = 'right'
					sx = { whiteSpace: 'break-spaces' }
				}
				return (
					<StyledGrid
						// sx={{border: '1px solid black'}}
						key={`tab-row-${index}`}
						item
						variant={'tableHead'}
						display={ hideColumn }
						xs={columnWidth['xs'][index]}
						sm={columnWidth['sm'][index]}>
							<TableItem variant={'tableHead'} content={item} align={align} sx={sx}/>
					</StyledGrid>
				)
			})}
		</Fragment>
	)
}


const TableRow = ({ variant = 'tableContent', items = [], sx = {}, showDiscount = false, style = 'normal', total = false }) => {

	const align = {
		...(total) && { textAlign: 'right'}
	}
	
	const columnWidth = COLUMN_WIDTHS(items.length, showDiscount)
	
	return (
		<Fragment>
			{ items.map((item, index, arr) => {
				const align = variant !== 'tableHead' && index === arr.length - 1 ? 'right' : 'left'
				// Aligna Amount-rubriken i vänsterkant med bredden på summorna
				const hideColumn = columnWidth['xs'][index] === 0 ? { xs: 'none', sm: 'block'} : { xs: 'block', sm: 'block' }
				return (
				<StyledGrid key={`tab-row-${index}`} item variant={variant} display={ hideColumn } xs={columnWidth['xs'][index]} sm={columnWidth['sm'][index]}>
					<TableItem sx={{...sx}} style={style} variant={variant} content={item} align={align} />
				</StyledGrid>
			)})}
		</Fragment>
	)
}

function InvoiceArticles(props){

  const {
    items,
	netAmount,
    totalAmount,
    totalVAT,
    currency,
	showDiscount = false,
   } = props;

   let totalRows = [<TableRow sx={{ paddingTop: '10px'}} key='net' variant='tableTotal' items={['Net', CURRENCIES[currency](netAmount).format() ]} showDiscount />];

   Object.keys(totalVAT).forEach(vatItem => {
    if (totalVAT[vatItem] > 0 && vatItem !== 'total') {
      totalRows.push(<TableRow key={`vat-${vatItem}`} variant='tableTotal' items={[`VAT ${isInt(vatItem) ? '@ ' + vatItem + '%' : vatItem}`, CURRENCIES[currency](totalVAT[vatItem]).format()]} showDiscount style="fade" />)
    }
   })

   totalRows.push(<TableRow key='tot' variant='tableTotal' items={['Amount due', CURRENCIES[currency](totalAmount).format()]} style='bold' total showDiscount />);

//    const amountDueLength = (CURRENCIES[currency](totalAmount).format().length - 'Amount'.length) * 2 + 'Amount'.length
//    const amountHeader = 'Amount'.padEnd(amountDueLength, ' ')

   const HEADER_ITEMS = showDiscount ? ['Description', 'Discount', 'Amount'] : ['Description', 'Amount']

   return (
	<InvoiceSection>
		<Grid
			sx={{marginTop: '8px'}}
			direction="row"
			alignItems={'flex-start'}
			justifyContent={'space-between'}
			container
			rowSpacing={1}
			columnSpacing={1}
			>
			<TableHeadRow items={HEADER_ITEMS} showDiscount />
			{ items.map((item, index) => {
				let rowItems = showDiscount ?
					[item.description, item.discount > 0 ? `${item.discount} %` : '', CURRENCIES[currency](item.itemAmount).format()] :
					[item.description, CURRENCIES[currency](item.itemAmount).format()]
				return <TableRow key={index} variant="tableContent" items={rowItems} showDiscount />
			})}
			{ totalRows }
		</Grid>
	</InvoiceSection>
   )
}

export default InvoiceArticles;
