import Share from '../components/share/Share'
import FormWrapper from '../components/FormWrapper';
import { CURRENT_STEP, NEXT_STEP, PREVIOUS_STEP } from '../components/forms/FormSteps';
import FormNavigationLogic from '../components/forms/FormNavigationLogic';

const ShareInvoice = () => {

	const previousStep = PREVIOUS_STEP('share') 
	const currentStep = CURRENT_STEP('share')
	const nextStep = NEXT_STEP('share')

	return (
		<FormWrapper heading={currentStep.heading}>
			<Share />
			<FormNavigationLogic
				{...currentStep}
				previousStep={previousStep}
				nextStep={nextStep}
			/>
		</FormWrapper>
	);
}

export default ShareInvoice;
