import {
	createSlice,
	createAsyncThunk
} from '@reduxjs/toolkit'
import {
	collection,
	getDocs,
	query,
	where,
} from 'firebase/firestore'
import { db } from '../Firebase'

const INITIAL_STATE = {
	invoices: [],
	loading: false,
}

export const fetchInvoices = createAsyncThunk('invoices/get', async (args, thunkAPI) => {
	try {
		const { user } = thunkAPI.getState()
		const invoicesRef = collection(db, 'invoices')
		const q = query(invoicesRef, where('user', '==', user.id ))
		const snapshot = await getDocs(q)
		let invoices = [];
		snapshot.forEach(doc => invoices.push({ id: doc.id, ...doc.data() }))
		return invoices
	} catch (error) {
		console.log('ERROR FETCHING INVOICES');
		console.log(error);
		return [];
	}
})


export const invoicesSlice = createSlice({
	name: 'invoices',
	initialState: { ...INITIAL_STATE },
	reducers: {
		invoiceRemoved: (state, action) => {
			state.invoices = state.invoices.filter((item) => item.id !== action.payload.removedId )
		},
	},
	extraReducers: builder => {
		builder
			.addCase(fetchInvoices.pending, (state, action) => {
				state.loading = true
			})
			.addCase(fetchInvoices.fulfilled, (state, action) => {
				state.invoices = action.payload
				state.loading = false
			})

	}
})

export const {
	invoiceRemoved,
} = invoicesSlice.actions

export default invoicesSlice.reducer