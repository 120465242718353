import { Box, Divider } from "@mui/material"
import LogoTemplate from "./LogoTemplate"
import LogoUpload from "./LogoUpload"
import PremadeLogo from './PremadeLogo'
import SLHLogo from '../../images/icons/smiley_like_heart.png'
import TY1 from '../../images/icons/ty_1.png'
import TY2 from '../../images/icons/ty_2.png'
import TY3 from '../../images/icons/ty_3.png'
import TY4 from '../../images/icons/ty_4.png'
import TY5 from '../../images/icons/ty_5.jpg'
import TY6 from '../../images/icons/ty_6.png'
import { useDispatch, useSelector } from "react-redux"
import { setPremadeAvatar } from "../../store/logoActions"

const ICONS = [ SLHLogo, TY1, TY2, TY3, TY4, TY5, TY6 ]

const maskStyle = {
	'&::after': {
		content: '""',
		position: 'absolute',
		right: '0',
		height: '120px',
		width: '5px',
		background: 'linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.8) 50%, rgba(255,255,255,1) 100%)',
	},
	'&::before': {
		content: '""',
		position: 'absolute',
		left: '110px',
		height: '120px',
		width: '5px',
		background: 'linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.8) 50%, rgba(255,255,255,0) 100%)',
	},
}

function LogoSelect(params) {
	
	const dispatch = useDispatch();
	const uploadingLogo = useSelector(state => state.user.uploading)

	const setPremadeLogo = (source) => {
		dispatch( setPremadeAvatar(source) )
	}

	return (
		<Box sx={{display: 'flex', flexDirection: 'row', maxWidth: '100%', position: 'relative'}}>
			<LogoTemplate loading={uploadingLogo} size="large" selected="true" sx={{ marginBottom: '17px'}}>
				<LogoUpload/>
			</LogoTemplate>
			<Divider sx={{borderWidth: '1px', borderColor: 'black'}} orientation="vertical" />
			<Box sx={{overflowY: 'scroll', overflowY: 'hidden', whiteSpace: 'nowrap', ...maskStyle, display: 'flex', alignItems: 'center'}}>
				<Box sx={{width: '5px', display: 'inline-block'}} />
				{ ICONS.map((item, index) =>
					<LogoTemplate key={`logo-${index}`} sx={{ display: 'inline-block' }}>
						<PremadeLogo source={item} setImage={setPremadeLogo}/>
					</LogoTemplate>
				)}
				<Box sx={{width: '5px', display: 'inline-block'}} />
			</Box>
		</Box>
	)
}

export default LogoSelect