import { Fragment } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { logButtonClick, logCustomEvent, logLinkClick } from '../helpers/AnalyticsHelper';
import {
	setCompanyName,
} from '../store/userSlice'
import { styled, useTheme } from '@mui/material/styles';
import { FrameAnimation } from '../components/animations/FrameAnimation';
import CenterWrap from '../components/CenterWrap';
import FilledButton from '../components/buttons/FilledButton';
import FluidBackgroundBox from '../components/FluidBackgroundBox';

const FireEmoji = styled('img')({
	width: '1.8em',
	height: '1.8em',
	marginLeft: '-0.3em',
	marginRight: '-0.3em',
	marginTop: '-1.2em',
	verticalAlign: 'bottom',
})

const HowItLooks = () => {
	return (
		<Link
			onClick={() => logLinkClick({ linkName: 'HowItLooks', linkPlacement: 'bottom'})}
			variant="p"
			target="_blank"
			href={`${process.env.PUBLIC_URL}/Pejme-invoice.jpg`}
			sx={{
				color: 'black !important',
				textDecoration: 'underline',
				textAlign: 'center',
			}}>
				Here's what they look like.
		</Link>)
}

const USP = ({ heading, paragraph }) => {
  return(
    <Stack spacing={1}>
      <Typography sx={{textAlign: 'left'}}variant="h2">{heading}</Typography>
      <Typography variant="p">{ paragraph }</Typography>
    </Stack>
  )
}

const Home = () => {

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const theme = useTheme();

	const { palette: { frame } } = theme
	
	const animation = FrameAnimation(`${frame.col1}, ${frame.col2}`);

	const user = useSelector(state => state.user);

	const handleStart = (origin) => {
		logButtonClick({ buttonName: 'Start', buttonPlacement: origin })
		logCustomEvent({ eventName: 'StartInvoice'})
		navigate('/create')
	}

	return (
		<Fragment>
		<FluidBackgroundBox sx={{height: '70vh'}}>
			<CenterWrap>
				<Stack spacing={4} sx={{
					display: 'flex',
					width: '100%',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
					marginTop: '60px',
					padding: '20px',
					borderRadius: '5px',
					marginBottom: '60px'}}
					>
					<Typography
						variant='h1'
						sx={{color: 'white', textAlign: 'center', fontSize: { xs: '2.5em', sm: '3em', md: '3em', lg: '4em' }, fontWeight: 800 }}
						>Send professional<br />and super-<FireEmoji src={`${process.env.PUBLIC_URL}/fire.gif`} /> invoices!
					</Typography>
					<Box sx={{ width: '100%', maxWidth: { sm: '400px' }}}>
						<Stack spacing={1}>
							<Link
								onClick={() => logLinkClick({ linkName: 'HowItLooks', linkPlacement: 'header'})}
								variant="inputText"
								target="_blank"
								sx={{textAlign: 'left', textDecoration: 'underline', color: 'white'}}
								href={`${process.env.PUBLIC_URL}/Pejme_invoice.pdf`}>See how good it looks!</Link>
							<TextField
								value={user.companyName}
								className='frontPage'
								onChange={e => dispatch(setCompanyName(e.target.value))}
								sx={{color: 'white', width: '100%'}}
								color='primary'
								autoComplete="off"
								label="Your company name"
								variant="filled"
							/>
							<FilledButton
								size='large'
								sx={{width: '100%', maxWidth: { sm: '400px' } }}
								onClick={() => handleStart('HeroButton')}>
									Start
							</FilledButton>
						</Stack>
					</Box>
				</Stack>
			</CenterWrap> 
		</FluidBackgroundBox>
		<Box sx={{ flexDirection: 'row', display: 'flex', justifyContent: 'center', }}>
			<CenterWrap>
				<Stack spacing={4} sx={{padding: '20px', paddingTop: '60px', paddingBottom: '60px', backgroundColor: 'white'}}>
					<Typography variant='h2' sx={{textAlign:'left'}}>The simplest way to send pixel-perfect invoices to your customers. It is...</Typography>
					<USP
						heading={"Convenient"}
						paragraph={"Send professional invoices as easy as you type text messages. No applications or software required."}
					/>
					<USP
						heading={"Affordable"}
						paragraph={"Save time and money by sending e-invoices, with zero fees. Plus you do good for the environment."}
					/>
					<USP
						heading={"Effective"}
						paragraph={["Delight your customers with beautiful invoices. ", <HowItLooks key="howitlooks"/>]}
					/>
					<FilledButton
						onClick={() => handleStart('BottomButton')}
						btnColor="black"
						size='large'
						sx={{
							alignSelf: 'center',
							width: '100%',
							maxWidth: { sm: '400px' },
						}}
						>
						Create an invoice
					</FilledButton>
				</Stack>
			</CenterWrap>
		</Box>
		</Fragment>
	)
}

export default Home;


// VIDEO SNIPPET
/* <Box sx={{ position: 'absolute', top: '64px', left: '0', right: '0', height: '70vh', width:'100%' }}>
	<video style={{position: 'absolute', top: '0', left: '0', right: '0', zIndex: '-1', objectFit: 'cover', width: '100%', height: '100%'}} playsInline autoPlay muted loop id="myVideo">
	<source src={`${process.env.PUBLIC_URL}/bgs/bg_video.mp4`} type="video/mp4" />
	</video>
</Box> */