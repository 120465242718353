import { createOrUpdateCustomer, getCustomer } from './customerSlice';
import { createOrUpdateUser, setCustomersForUser, setInvoicesForUser, getUser } from './userSlice';
import { createOrUpdateInvoice, getInvoice, setCustomerForInvoice, setUserForInvoice, updateInvoiceData } from './invoiceSlice';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { CURRENCIES } from '../helpers/MoneyHelper'
import { DEFAULT_VAT, defaultItem } from './storeTypes'
import { uploadBlob } from '../helpers/UploadHelper';
import { showValidationOverlay } from './miscSlice';
import { generatePdfBlob, generatePdfFile } from '../helpers/pdf/PdfWorker';

export const generateInvoice = createAsyncThunk('invoice/generate', async (args, thunkAPI) => {
	const { validate = true, ref = false } = args;
	
	const {
		user: { stateValid: userStateValid, id: userId },
		customer: { stateValid: customerStateValid },
		invoice: { stateValid: invoiceStateValid, id: invoiceId}
	} = thunkAPI.getState()

	try {
		if (validate) {
			if (!userStateValid || !customerStateValid || !invoiceStateValid) {
				thunkAPI.dispatch( showValidationOverlay() )
				return thunkAPI.rejectWithValue({ validToSend: false })
			}
		}
		if (!ref) return thunkAPI.rejectWithValue({ validToSend: false})
		if (process.env.NODE_ENV === 'development') {
			console.log('generating pdf for development');
			await generatePdfFile(ref)
		}
		const pdfBlob = await generatePdfBlob(ref)
		const uploadedFileURL = await uploadBlob(`${userId}/${invoiceId}.pdf`, 'invoices', pdfBlob)
		console.log(uploadedFileURL);
		await thunkAPI.dispatch( updateInvoiceData({ id: invoiceId, data: { invoiceURL: uploadedFileURL }}))
		return { validToSend: true }
	} catch (error) {
		console.log(error);
		return { validToSend: false }
	}
})

export const saveInvoice = createAsyncThunk('invoice/save', async (args, thunkAPI) => {

    try {
        let [savedUser, savedCustomer, savedInvoice] = await Promise.all([
            thunkAPI.dispatch( createOrUpdateUser() ),
            thunkAPI.dispatch( createOrUpdateCustomer() ),
            thunkAPI.dispatch( createOrUpdateInvoice() ),
        ])
 
        thunkAPI.dispatch( setCustomersForUser( savedCustomer.payload.id ))
        thunkAPI.dispatch( setInvoicesForUser( savedInvoice.payload.id ) )

        thunkAPI.dispatch( setCustomerForInvoice( savedCustomer.payload.id ) )
        thunkAPI.dispatch( setUserForInvoice( savedUser.payload.id ) )

        await Promise.all([
            thunkAPI.dispatch( createOrUpdateUser() ),
            thunkAPI.dispatch( createOrUpdateInvoice() ),
        ])
        return savedInvoice.payload.id
    } catch (error) {
        console.log(error);
    } finally {
        console.log('all saved!');
    }
})

// REMOVE
export const setPdfForInvoice = createAsyncThunk('invoice/setPdf', async (args, thunkAPI) => {
	try {
		const currentState = thunkAPI.getState()
		const { invoice, user } = currentState
		const uploadedFileURL = await uploadBlob(`${user.id}/${invoice.id}.pdf`, 'invoices', args)
		await thunkAPI.dispatch( updateInvoiceData(invoice.id, { invoiceURL: uploadedFileURL }))
		return uploadedFileURL
	} catch (error) {
		console.log(error);
	}
})


export const fetchInvoice = createAsyncThunk('invoice/fetch', async (invoiceId, thunkAPI) => {

    try {
        const { payload: invoice } = await thunkAPI.dispatch( getInvoice(invoiceId) )
		console.log('Fetched:', invoice);
        const [ customer, user ] = await Promise.all([
            thunkAPI.dispatch( getCustomer( invoice.customer ) ),
            thunkAPI.dispatch( getUser( invoice.user ) ),
        ])
        return
    } catch (error) {
        console.log(error);   
    }

})

const calculateVAT = (totalVAT, currency) => {
	let newTotalVAT = {}
    for (const [key, value] of Object.entries(totalVAT)) {
	    newTotalVAT[key] = CURRENCIES[currency](value)
    }
    return newTotalVAT
}

const normalizeVAT = (totalVAT) => {
	let newTotalVAT = {}
    for (const [key, value] of Object.entries(totalVAT)) {
	    newTotalVAT[key] = value.value
    }
    return newTotalVAT
}

const normalizeObject = (totalVAT) => {
	let normalizedObject = {}
    for (const [key, value] of Object.entries(totalVAT)) {
		if (typeof value === 'object') {
			normalizedObject[key] = value.value
		} else {
			normalizedObject[key] = value
		}
    }
    return normalizedObject
}

const applyDiscount = (item, currency) => {
	let newItem = { ...item }
	newItem.itemNetAmountExclVAT = CURRENCIES[currency](newItem.itemNetAmountExclVAT)
	newItem.amountExclVAT = calculateDiscount(newItem.itemNetAmountExclVAT, newItem.discount)
	newItem.itemVAT = CURRENCIES[currency](newItem.amountExclVAT).multiply(newItem.currentVAT / 100)
	newItem.itemAmount = CURRENCIES[currency](newItem.amountExclVAT).add(newItem.itemVAT)
	return newItem
}

const calculateDiscount = (amount, discount = 0) => {
	if (discount === 0) return amount
	return amount.multiply(1 - discount / 100 )
}

const compareItems = (a, b) => {
    const keysToCompare = Object.keys({...a,...b}).filter(key => ![null,undefined].includes(a[key]) || ![null,undefined].includes(b[key]))
    return keysToCompare.every(key => a[key] === b[key])
}

const diffItems = (oldItem, newItem, currency) => {
	const oldItemNetAmountExclVAT = CURRENCIES[currency](oldItem.itemNetAmountExclVAT)
	const oldItemAmountExclVAT = calculateDiscount( oldItemNetAmountExclVAT, oldItem.discount)
	const oldItemVAT = CURRENCIES[currency](oldItemAmountExclVAT).multiply(oldItem.currentVAT / 100)
	const oldItemAmount = CURRENCIES[currency](oldItemAmountExclVAT).add(oldItemVAT)

	const newItemNetAmountExclVAT = CURRENCIES[currency](newItem.itemNetAmountExclVAT)
	const newItemAmountExclVAT = calculateDiscount( newItemNetAmountExclVAT, newItem.discount)
	const newItemVAT = CURRENCIES[currency](newItemAmountExclVAT).multiply(newItem.currentVAT / 100)
	const newItemAmount = CURRENCIES[currency](newItemAmountExclVAT).add(newItemVAT)

	const netDiff = newItemAmountExclVAT.subtract(oldItemAmountExclVAT)
	const VATDiff = newItemVAT.subtract(oldItemVAT)
	const amountDiff = newItemAmount.subtract(oldItemAmount)

	return { netDiff, VATDiff, amountDiff }
}

export const recalculateTotal = (currentInvoice, elementId, action = 'add') => {

    // console.log(elementId);

    const { currency, currentItem } = currentInvoice
    let { totalAmount, netAmount, totalVAT } = currentInvoice
	let allItems = [ ...currentInvoice.items]
	const newItem = applyDiscount(currentItem, currency)

	totalAmount = CURRENCIES[currency](totalAmount)
	netAmount = CURRENCIES[currency](netAmount)
	totalVAT = calculateVAT(totalVAT, currency)

	// Check if item exists
    const exists = allItems.findIndex(el => el.id === elementId)

    if (exists >= 0 && action === 'add') {
		// Item exists, should edit
        console.log('should edit');
		const oldItem = applyDiscount(allItems[exists], currency)

		// If no change - return
		if (compareItems(oldItem, newItem)) {
			totalVAT = normalizeVAT(totalVAT)
			return {
				netAmount,
				totalAmount,
				totalVAT,
				allItems,
			}
		}

		const { netDiff, VATDiff, amountDiff } = diffItems(oldItem, newItem, currency)

		// Update totals
		netAmount = netAmount.add(netDiff)
		totalAmount = totalAmount.add(amountDiff)
		totalVAT['total'] = totalVAT['total'].add(VATDiff)
		totalVAT[newItem.currentVAT] = totalVAT[newItem.currentVAT].add(VATDiff)
		
		// Update items
		const normalizedObject = normalizeObject(newItem)
		allItems[exists] = normalizedObject

    } else if (exists >= 0 && action === 'remove') {
		// Should remove item
        console.log('should remove');

		const oldItem = defaultItem()
		const { netDiff, VATDiff, amountDiff } = diffItems(newItem, oldItem, currency)

		// Update totals
		netAmount = netAmount.add(netDiff)
		totalAmount = totalAmount.add(amountDiff)
		totalVAT['total'] = totalVAT['total'].add(VATDiff)
		totalVAT[newItem.currentVAT] = totalVAT[newItem.currentVAT].add(VATDiff)

		// Update items
        allItems.splice(exists, 1)

		// If no items, re-init
		if (allItems.length === 0) {
			netAmount = CURRENCIES[currency](0)
			totalAmount = CURRENCIES[currency](0)
			totalVAT = { ...DEFAULT_VAT }
			allItems = []
		}
    } else {
		// New item, add
        console.log('will add', newItem);

		const oldItem = applyDiscount( defaultItem(), currency )
		const { netDiff, VATDiff, amountDiff } = diffItems(oldItem, newItem, currency)

		// Update totals
		netAmount = netAmount.add(netDiff)
		totalAmount = totalAmount.add(amountDiff)
		totalVAT['total'] = totalVAT['total'].add(VATDiff)
		totalVAT[newItem.currentVAT] = totalVAT[newItem.currentVAT].add(VATDiff)

		// Update items
		const normalizedObject = normalizeObject(newItem)
        allItems.push(normalizedObject)
    }

	// Need to normalize VAT prior to saving
	totalVAT = normalizeVAT(totalVAT)

	return {
        netAmount,
        totalAmount,
        totalVAT,
        allItems,
    }
  
}