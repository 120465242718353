import { Fragment } from 'react'
import Invoice from '../components/invoice/Invoice'
import FormWrapper from '../components/FormWrapper';
import { useSelector } from 'react-redux';
import { Container, Link } from '@mui/material';
import FullscreenSpinner from '../components/fragments/FullscreenSpinner';
import { API_STATUS } from '../store/storeTypes';

const ShowInvoice = () => {

  const invoice = useSelector(state => state.invoice)
  const user = useSelector(state => state.user)
  const customer = useSelector(state => state.customer)
  
  if (user.status !== API_STATUS.DONE || customer.status !== 'FULFILLED') return (<FullscreenSpinner />)

  return (
    <Fragment>
      <FormWrapper heading="">
          <Invoice
            invoice={invoice}
            user={user}
            customer={customer}
            />
      </FormWrapper>
      <Container sx={{paddingTop: '0', paddingBottom: '2rem', display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
        { invoice.invoiceURL ? <Link color="inherit" variant="body2" href={`${invoice.invoiceURL}`}>Download PDF</Link> : null }
      </Container>
    </Fragment>
  );
}

export default ShowInvoice;
