import { InputAdornment } from "@mui/material";
import InputLabel, { inputLabelClasses } from "@mui/material/InputLabel";
import TextField, { textFieldClasses } from "@mui/material/TextField";
import React from "react";
import { styled, useTheme } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import { clearValidation } from "../../store/miscSlice";
import { AccountFormat } from "./formats/AccountFormat";
import { BankgiroFormat } from "./formats/BankgiroFormat";
import { ClearingFormat } from "./formats/ClearingFormat";
import { OrgFormat } from "./formats/OrgFormat";
import { PhoneNumberFormat } from "./formats/PhoneNumberFormat";
import { VATFormat } from "./formats/VATFormat";

const StyledTextField = styled(TextField)(({theme}) => ({
	[`${textFieldClasses.root}`]: {
		letterSpacing: '0.08em'
	},
	underline: {
		"&:after": {
			borderBottomColor: "#ff9300",
		}
	}
}))

const StyledInputLabel = styled(InputLabel)(({theme}) => ({
	[`${inputLabelClasses.root}`]: {
		color: 'black', //theme.palette.primary.main
	},
	[`${inputLabelClasses.root} & ${inputLabelClasses.focused}`]: {
		color: 'black', //theme.palette.primary.main
	},
	[`${inputLabelClasses.root} & ${inputLabelClasses.error}`]: {
		color: theme.palette.misc.error
	}
}))


function TextInput({
    autoComplete = 'off',
    id = false,
    handleChangeFunc,
    value,
    propName = '',
    inputType = 'amount',
    label = '',
    helperText = '',
    valid = true,
    adorment = { label: '', placement: false },
    disabled = false,
    sx = {},
    // sx = { width: '50%'},
}){

    let inputComponent = {};
    if (inputType === 'phone') inputComponent = PhoneNumberFormat
    if (inputType === 'bg') inputComponent = BankgiroFormat
    if (inputType === 'clearing') inputComponent = ClearingFormat
    if (inputType === 'account') inputComponent = AccountFormat
    if (inputType === 'org') inputComponent = OrgFormat
    if (inputType === 'VAT') inputComponent = VATFormat

    const dispatch = useDispatch();
    const currency = useSelector(state => state.invoice.currency)

    const handleFocus = () => {
        const [slice, prop] = propName.split('.')
        if(!valid) dispatch( clearValidation({ slice, prop }))
    }

    const handleChange = (e) => {
        if (id) {
            const payload = {
                id: id,
                value: e.target.value
            }
            dispatch(handleChangeFunc( payload ))
            return
        }
        dispatch(handleChangeFunc(e.target.value))
    }

	const theme = useTheme();

    return (
        <TextField
            sx={{
                'input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button': {
                    WebkitAppearance: 'none',
                },
                ...sx,
				'.MuiTextField-root .MuiInputLabel-root .Mui-focused': {
					color: 'blue',//"#ff9300"
				},
				'.MuiInput-underline': {
					'&:after': {
						borderBottomColor: theme.palette.primary.main,
					}
				}
            }}
            fullWidth
            autoComplete={autoComplete}
            error={!valid}
            onFocus={handleFocus}
            onChange={handleChange}
            disabled={disabled}
            value={value}
            helperText={helperText}
            type="text"
            variant="standard"
            label={label}
            InputProps={{
                ...inputComponent,
                endAdornment: adorment.placement === 'end' ? <InputAdornment position="end">{adorment.label}</InputAdornment> : null
            }}
        />
    )
}

export default TextInput