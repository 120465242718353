import FormWrapper from '../components/FormWrapper';
import FormNavigationLogic from '../components/forms/FormNavigationLogic';
import { CURRENT_STEP, CURRENT_STEP_INDEX, NEXT_STEP, PREVIOUS_STEP } from '../components/forms/FormSteps';
import ItemForm from '../components/forms/ItemForm';



const CreateInvoiceItem = () => {

	const previousStep = PREVIOUS_STEP('items') 
	const currentStep = CURRENT_STEP('items')
	const nextStep = NEXT_STEP('items')
	const activeStepIndex = CURRENT_STEP_INDEX('items')

	return (
		<FormWrapper heading={currentStep.heading} progress={activeStepIndex}>
			<ItemForm />
			<FormNavigationLogic
				{...currentStep}
				previousStep={previousStep}
				nextStep={nextStep}
			/>
		</FormWrapper>
	);
}

export default CreateInvoiceItem;
