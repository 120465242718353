import { useState } from "react"
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, Typography } from "@mui/material"
import { THEMES } from "../helpers/theme/themeFactory"
import { useTheme } from "@mui/system"
import useMediaQuery from '@mui/material/useMediaQuery';
import { useDispatch, useSelector } from "react-redux";
import { setInvoiceTheme } from "../store/invoiceSlice";
import { setDefaultTheme } from "../store/userSlice";

const BTNSIZE = '2em'

const ThemeButton = ({current, muiTheme, selectTheme}) => {
	const [ name, theme ] = muiTheme
	return (
		<Box
			sx={{
				margin: '9px',
				...(current === name && { border: `5px solid ${theme.palette.frame.mid}`, margin: '4px' }),
				height: BTNSIZE,
				width: BTNSIZE,
				borderRadius: '50%',
				background: `linear-gradient(45deg, ${theme.palette.frame.col1} 0%, ${theme.palette.frame.col1} 50%, ${theme.palette.frame.col2} 50%, ${theme.palette.frame.col2} 100%)`,
			}}
			onClick={() => selectTheme(name)}
		/>
	)
}


const ThemeSelector = () => {

	const currentTheme = useSelector(state => state.invoice.invoiceTheme)
	const dispatch = useDispatch()
	const setTheme = (themeName) => {
		dispatch( setInvoiceTheme(themeName) )
		dispatch( setDefaultTheme(themeName) )
	}

	const [showSelector, setShowSelector] = useState(false)

	const theme = useTheme();
	
	const triggerSelector = () => {
		setShowSelector(!showSelector)
	}

	const largeSize = useMediaQuery(theme.breakpoints.up('md'));

	return (
		<>
			{ showSelector || largeSize ?
				<Box sx={{
					position: 'fixed',
					zIndex: '3',
					right: '0',
					top: '50%',
					transform: 'translateY(-50%)',					
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					borderRadius: '15px 0px 0px 15px',
					padding: '8px 0px',
					backgroundColor: theme.palette.grey[200],
				}}>
					{ largeSize && <Typography sx={{writingMode: 'tb-rl', textAlign: 'center', padding: '9px' }}>Invoice frame</Typography>}
					{ Object.entries(THEMES).map((theme, i) => {
						return <ThemeButton current={currentTheme} muiTheme={theme} key={i} selectTheme={setTheme} />
					})}
					{ !largeSize && <ChevronRightIcon sx={{ height: '30px', width: '30px' }} onClick={triggerSelector} /> }
				</Box>
			: <Box
				onClick={triggerSelector}
				sx={{
					position: 'fixed',
					zIndex: '3',
					right: '0',
					top: '50%',
					transform: 'translateY(-50%)',
					backgroundColor: theme.palette.grey[200],
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					padding: '16px 0px',
					borderRadius: '15px 0px 0px 15px',
				}}>
				<Typography sx={{writingMode: 'tb-rl', textAlign: 'center', padding: '9px' }}>Invoice frame</Typography>
				<ChevronLeftIcon fontSize="medium" />
			</Box>}
		</>
	)
}

export default ThemeSelector