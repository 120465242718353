import * as React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import StartButton from '../components/StartButton';


function ComingSoon() {

  return (
    <React.Fragment>
      <Stack spacing={4} sx={{padding: '20px', paddingTop: '60px',
    paddingBottom: '60px'}}>
        <Typography variant='h1'>Coming soon!</Typography>
        <Typography variant='h6' align="center">Can't wait? Get started!</Typography>
        <StartButton />
      </Stack>
    </React.Fragment>
  )
}

export default ComingSoon;
