import { Stack, Typography } from "@mui/material"
import FilledButton from "./buttons/FilledButton"
import { FORM_PROPS } from "../helpers/FormProps"
import { useNavigate } from "react-router-dom"


const InvoiceListEmpty = () => {
   
    const navigate = useNavigate();
    
    return (
        <Stack spacing={6} sx={{ marginTop: '100px' }}>
            <Stack spacing={FORM_PROPS.ROW_SPACING}>
                <Typography variant="p" align="center">Your invoice history is empty.</Typography>
                <Typography variant="p" align="center">Create and invoice and it will be saved here.</Typography>
            </Stack>
            <FilledButton btnColor="black" onClick={() => navigate('/select-customer')}>Create an invoice</FilledButton>
        </Stack>
    )
}

export default InvoiceListEmpty