import { Box, Stack, Typography, useTheme } from "@mui/material"
import { logLinkClick } from "../helpers/AnalyticsHelper";
import { FrameAnimation } from "./animations/FrameAnimation"


function Footer(props){

	const theme = useTheme();

	const { palette: { frame, } } = theme

	const animation = FrameAnimation(`${frame.col1}, ${frame.col2}`);
	
    return(
        <Box sx={{
			...animation,
            width: '100%',
            minHeight: '260px',
			paddingTop: '20px',
			paddingBottom: '20px',
            display: 'flex',
            justifyContent: 'center',
			alignItems: 'center',
        }}>
			<Stack spacing={2}>
				<Typography
					color={'misc.logo'}
					sx={{
						fontSize: {
							xs: '15vw',
							sm: '15vw',
							md: '15vw',
							lg: '10rem',
						}
					}}
					variant="logo">PEJME</Typography>
				<Typography
					sx={{
						display: { xs: 'none', sm: 'block', md: 'block' },
						textAlign: 'center'
					}}
					variant="inputText"
					color="white">© 2023 Pejme - Made in Sweden<br/><a style={{color: 'white'}} href="mailto:hey@pejme.se" onClick={() => logLinkClick({ linkName: 'Contact', linkPlacement: 'footer'})}
					>Contact us here</a>
				</Typography>
				<Typography
					sx={{
						display: { xs: 'block', sm: 'none' },
						textAlign: 'center'
					}}
					variant="inputText"
					color="white">© 2023 Pejme<br />Made in Sweden<br/><a style={{color: 'white'}} href="mailto:hey@pejme.se" onClick={() => logLinkClick({ linkName: 'Contact', linkPlacement: 'footer'})}>Contact us here</a>
				</Typography>
			</Stack>
        </Box>
    )
}

export default Footer