import { Box } from '@mui/material';
import dayjs from 'dayjs'
import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography"
import { COUNTRY_NAME } from '../../helpers/FormProps';
import InvoiceSection from './InvoiceSection';

const Item = ({text = 'test text'}) => {
	return (
		<Box sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}>
			<Typography variant="tightText">{text}</Typography>
		</Box>
	)
}

const InvoiceTo = ({ customer = {}, invoice = {} }) => {
	return (
	<InvoiceSection>
		<Typography variant="h6" sx={{ fontWeight: 400 }}>Invoice to</Typography>
		<Grid
			sx={{marginTop: '8px'}}
			direction="row"
			alignItems={'flex-start'}
			justifyContent={'space-between'}
			container
			spacing={1}>
			<Grid item xs={6}>
				<Item text={customer.companyName} />
				{ invoice.reference && invoice.reference.length > 0 ? <Item text={invoice.reference} /> : null }
				<Item text={customer.address} />
				<Item text={`${customer.postalCode} ${customer.city}`} />
				<Item text={`${COUNTRY_NAME(customer.country)}`} />
			</Grid>
			<Grid item xs={6}>
				<Item text={customer.email} />
				<Item text={'Org.nr:'} />
				<Item text={customer.ORGnumber} />
				<Item text={'Invoice date'} />
				<Item text={dayjs.unix(invoice.invoiceDate).format("DD MMM YYYY")} />
			</Grid>
		</Grid>
	</InvoiceSection>
	)
}

export default InvoiceTo