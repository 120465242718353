import { createListenerMiddleware } from "@reduxjs/toolkit";
import { setAllPaymentOptions, setInvoiceTheme, unSetInvoice } from "../invoiceSlice";
import { unSetCustomer } from "../customerSlice";

const initInvoiceMiddleware = createListenerMiddleware()

initInvoiceMiddleware.startListening({
	type: 'misc/initInvoice',
	effect: (action, listenerApi) => {

		const { user: { defaultPaymentOptions, defaultTheme} } = listenerApi.getOriginalState()

		listenerApi.dispatch( unSetInvoice() )
		listenerApi.dispatch( unSetCustomer() )
		listenerApi.dispatch( setInvoiceTheme(defaultTheme) )
		listenerApi.dispatch( setAllPaymentOptions(defaultPaymentOptions) )
	}
})

export default initInvoiceMiddleware;