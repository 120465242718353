import { Fragment } from "react";
import Box from "@mui/material/Box";
import Footer from '../components/Footer';
import NavigationBar from '../components/navbar/NavigationBar';
import ThemeSelector from "../components/ThemeSelector";

const PageElements = (props) => {

	const { children, footer = false, navbar = true, canSelectTheme = false, navTitle = false } = props

    return(
		<Fragment>
			{ canSelectTheme ? <ThemeSelector /> : null }
			{ navbar ? <NavigationBar navTitle={navTitle} /> : <Box sx={{height: '50px' }}/> }
			{ children }
			{ footer ? <Footer /> : null }
		</Fragment>
    )
  }

export default PageElements