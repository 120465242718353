import * as React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import StartButton from '../components/StartButton';
import CenterWrap from '../components/CenterWrap';
import { Box } from '@mui/material';

function Why() {

  return (
    <React.Fragment>
      <Box sx={{
          flexDirection: 'row',
          display: 'flex',
          justifyContent: 'center',
        }}>
      <CenterWrap>
        <Stack spacing={4} sx={{padding: '20px', paddingTop: '60px', paddingBottom: '60px'}}>
			<Typography variant='h1'>Important stuff made simple...<br />and good looking!</Typography>
			<Typography variant='p'>You spend way too much time on admin work, shrinking your creative vein. Often, you also share too much of your income to third-parties just to be able to get paid.</Typography>
			<Typography variant='p'>So by cutting out the middle-man and by reducing the boring tasks, we believe that we can do our bit to help your creativity, and wallet, grow.</Typography>
			<Typography variant='p'>Also, use Pejme to put a smile on the receiving end.</Typography>
			<Typography variant="h2">Happy client, happy life</Typography>
			<Typography variant="p">A good-looking and professional invoice will obviously make you look pro. But there’s more. Less costs, send invoices eco-friendly and they are faster and safer - even for the customer "on the go". You give them all the incentives to buy from you again.</Typography>
			<Typography variant='p'>We are on a mission to offer a whole set of productivity tools that prevent you from doing the work that you love the most. Empowering the self-employed mastermind!</Typography>
          <StartButton />
        </Stack>
      </CenterWrap>
      </Box>
    </React.Fragment>
  )
}

export default Why;