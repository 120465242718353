import FormWrapper from "../components/FormWrapper"
import { useDispatch, useSelector } from "react-redux";
import { setCustomer } from "../store/customerSlice";
import { CURRENT_STEP, NEXT_STEP } from "../components/forms/FormSteps";
import FormNavigationLogic from "../components/forms/FormNavigationLogic";
import { useEffect, useState } from "react";
import SelectCustomerForm from "../components/forms/SelectCustomerForm";
import { initInvoice } from "../store/miscSlice";
import { useNavigate } from "react-router-dom";

const SelectCustomer = () => {

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [goToCustomer, setGoToCustomer] = useState(0)

	let currentStep = CURRENT_STEP('user')
	currentStep.heading = 'Select customer'
	const nextStep = NEXT_STEP('user')

	const userValid = useSelector(state => state.user.stateValid)
	const customers = useSelector(state => state.user.customers)

	useEffect(() => {
		if (!userValid) {
			navigate(currentStep.stepUrl)
		}
	}, [userValid])

	const selectCustomer = (customerId) => {
		const selected = customers.find((el) => el.id === customerId)
		dispatch( initInvoice() )
		dispatch( setCustomer(selected) )
		setGoToCustomer(1)
	}

	const createNewCustomer = () => {
		dispatch( initInvoice() )
		setGoToCustomer(1)
	}

	return (
		<FormWrapper heading={currentStep.heading} progress={false}>
			<SelectCustomerForm selectCustomer={selectCustomer} customers={customers} createNewCustomer={createNewCustomer}/>
			<FormNavigationLogic
				{...currentStep}
				showBack={true}
				showNext={false}
				nextStep={nextStep}
				triggerNext={goToCustomer}
			/>
		</FormWrapper>
	)
}

export default SelectCustomer