import { Fragment, useContext, useEffect } from 'react'
import FullscreenSpinner from '../../components/fragments/FullscreenSpinner'
import { AuthContext } from './AuthProvider'


const ProtectedRoute = ({children}) => {

	const { loaded, authenticated, validCode, setShowInvite } = useContext(AuthContext)

	useEffect(() => {
		if (!loaded) return
		if (!authenticated && !validCode) {
			setShowInvite(true)
		}
	}, [loaded])

	if (!loaded) {
		return <FullscreenSpinner />
	}

	return (
		<Fragment>
			{ children }
		</Fragment>
	)
}

export default ProtectedRoute