import { Fragment, useEffect, useState } from "react"
import FormNavigationButtons from "./FormNavigationButtons";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import ValidationModal from "../fragments/ValidationModal";

const FormNavigationLogic = (props) => {
	
	const {
		previousStep = {},
		nextStep = {},
		nextLabel = 'Next',
		nextButtonSize = 'normal',
		nextDisabled = false,
		action,
		invoiceRef = false,
		showBack = true,
		showNext = true,
		triggerNext = 0,
	} = props;

	const [showLoading, setLoading] = useState(false)

	useEffect(() => {
		if (triggerNext !== 0) {
			handleNext({})
		}
	}, [triggerNext])

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const handlePrevious = () => {
		const prevUrl = previousStep.stepUrl || '/'
		navigate(prevUrl)
	}

	const handleNext = async ({ forceSend = false }) => {
		if (action) {
			setLoading(true)
			// Set ref for PDF generation
			let actionArgs = {}
			if (invoiceRef) { actionArgs.ref = invoiceRef }
			if (forceSend) { actionArgs.validate = false }
			const res = await dispatch( action(actionArgs) )
			setLoading(false)
			if (res.error){
				console.error('Error returned', res);
				return
			}
		}
		const nextUrl = nextStep.stepUrl || '/'
		navigate(nextUrl)
	}

	return (
		<Fragment>
			<FormNavigationButtons
				showBack={showBack}
				showNext={showNext}
				showLoading={showLoading}
				nextLabel={nextLabel}
				size={nextButtonSize}
				backDisabled={false}
				nextDisabled={nextDisabled}
				handlePrevious={handlePrevious}
				handleNext={handleNext}
			/>
			<ValidationModal forceNext={() => { handleNext({ forceSend: true })}}/>
		</Fragment>
	)
}

export default FormNavigationLogic;