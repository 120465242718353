import React from 'react';
import FormWrapper from '../components/FormWrapper';
import { useSelector, useDispatch } from 'react-redux'
import { analyticsState } from '../helpers/analyticsMapping';
import UserForm from '../components/forms/UserForm';
import { GET_ADMIN_STEP } from '../components/forms/FormSteps';
import FormNavigationLogic from '../components/forms/FormNavigationLogic';

const EditProfile = () => {

	const userAnalyticsState = useSelector(state => analyticsState(state))
	const currentStep = GET_ADMIN_STEP('user')
	const nextStep = GET_ADMIN_STEP('invoices')
	const dispatch = useDispatch();

	return (
		<FormWrapper heading={currentStep.heading} progress={false}>
			<UserForm />
			<FormNavigationLogic {...currentStep} nextStep={nextStep} analyticsState={userAnalyticsState}/>
		</FormWrapper>
  );
}

export default EditProfile;
