import * as React from 'react';
import PropTypes from 'prop-types';
import './InvoiceArticles.css'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { CURRENCIES } from '../helpers/MoneyHelper';
import { useTheme } from '@mui/material';

const TABLE_STYLES = {
  tr: {
    '&:last-child td, &:last-child th': { border: 0 }
  },
  cell: {
    padding: '8px',
    border: 'none',
  },
  box: {

  }
}

function InvoiceArticles(props){

  const {
    items,
    totalAmount,
    netAmount,
    totalVAT,
    showTotal = true,
    showHeader = false,
    selectItem,
    addItem,
    currency,
   } = props;

   const theme = useTheme()

  const isExpanded = false;

  const showDiscount = items.reduce((discount, item) => discount + item.discount, 0) > 0

  return (
    <TableContainer
      sx={{
        maxHeight: '50vh',
        backgroundColor: theme.palette.grey[100]
      }}
      elevation={0}
      component={Paper}>
      <Table size="small" stickyHeader>
        { showHeader ? <TableHead>
          <TableRow>
            <TableCell sx={{...TABLE_STYLES.cell}} >Description</TableCell>
            { isExpanded ? <TableCell sx={{...TABLE_STYLES.cell}} align="right">Amount excl. VAT</TableCell> : null }
            { isExpanded ? <TableCell sx={{...TABLE_STYLES.cell}} align="right">VAT (%)</TableCell> : null }
            { showDiscount ? <TableCell sx={{...TABLE_STYLES.cell}} align="right">Discount</TableCell> : <TableCell sx={{...TABLE_STYLES.cell}}/> }
            <TableCell  sx={{...TABLE_STYLES.cell}} align="right">Amount</TableCell>
          </TableRow>
        </TableHead> : null }
        <TableBody>
          { items.map((item, index) => (
            <TableRow
              onClick={() => selectItem(item.id)}
              key={`row-${index}`}
              sx={{ ...TABLE_STYLES.tr, cursor: 'pointer' }}
            >
              <TableCell sx={{...TABLE_STYLES.cell, maxWidth: '30vw'}} key={`des-${index}`}>
                <Stack direction="row" spacing={1}>
                  {/* <EditOutlinedIcon fontSize='small'/> */}
                  <Typography variant="body2" sx={{textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', textDecoration: 'underline'}}>{item.description}</Typography>
                </Stack>
              </TableCell>
              { isExpanded ? <TableCell sx={{...TABLE_STYLES.cell}} key={`exk-${index}`}><Typography variant="body2">{CURRENCIES[currency](item.amountExclVAT).format()}</Typography></TableCell> : null }
              { isExpanded ? <TableCell sx={{...TABLE_STYLES.cell}} key={`vat-${index}`}><Typography variant="body2">{CURRENCIES[currency](item.currentVAT).format()}%</Typography></TableCell> : null }
              { showDiscount ? <TableCell sx={{...TABLE_STYLES.cell}} key={`dis-${index}`} align="right"><Typography variant="body2">{item.discount ? `${item.discount} %` : ''}</Typography></TableCell> : <TableCell sx={{...TABLE_STYLES.cell}}/> }
              <TableCell sx={{...TABLE_STYLES.cell}} key={`tot-${index}`} align="right"><Typography sx={{whiteSpace: 'nowrap'}} variant="body2">{CURRENCIES[currency](item.itemAmount).format()}</Typography></TableCell>
            </TableRow>
          ))}
          {/* <TableRow
            onClick={() => addItem()}
            >
            <TableCell sx={{...TABLE_STYLES.cell}}>
              <Stack direction="row" spacing={1}>
                <AddIcon color="info" fontSize='small' color="warning"/>
                <Typography variant="body2">Add one more!</Typography>
              </Stack>
            </TableCell>
          </TableRow> */}
          { showTotal ?
            <TableRow className="showTotal">
              <TableCell sx={{...TABLE_STYLES.cell}} colSpan={ isExpanded ? 4 : 2} align="right">
                <Typography variant="body2">{`Net:`}</Typography>
                <Typography variant="body2" sx={{ color: theme.palette.grey[600] }}>{`VAT:`}</Typography>
				        <Typography variant="body2" sx={{ fontWeight: 600 }}>{`Total:`}</Typography>
              </TableCell>
              <TableCell sx={{...TABLE_STYLES.cell}} align="right">
                <Typography variant="body2" sx={{whiteSpace: 'nowrap'}}>{CURRENCIES[currency](netAmount).format()}</Typography>
                <Typography variant="body2" sx={{whiteSpace: 'nowrap', color: theme.palette.grey[600]}}>{CURRENCIES[currency](totalVAT.total).format()}</Typography>
                <Typography variant="body2" sx={{whiteSpace: 'nowrap', fontWeight: 600 }}>{CURRENCIES[currency](totalAmount).format()}</Typography>
              </TableCell>
            </TableRow>
             : null }
        </TableBody>
      </Table>
    </TableContainer>
  );
}

InvoiceArticles.propTypes = {
  selectItem: PropTypes.func.isRequired,
  addItem: PropTypes.func.isRequired,
}

export default InvoiceArticles;
