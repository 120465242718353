import { createSelector } from "@reduxjs/toolkit"
import { IS_SET } from "./ValidationHelper";

const flattenObject = (obj, parent, res = {}) => {
    for(let key in obj){
        let propName = parent ? parent + '_' + key : key;
        if(typeof obj[key] == 'object'){
            flattenObject(obj[key], propName, res);
        } else {
            res[propName] = obj[key];
        }
    }
    return res;
}

export const analyticsState = createSelector(
	[store => store.user],
	(user) => flattenObject({
		user: {
			name: IS_SET(user.name),
			address: IS_SET(user.address),
			postalCode: IS_SET(user.postalCode),
			city: IS_SET(user.city),
			email: IS_SET(user.email),
			VATnumber: IS_SET(user.VATnumber),
			ORGnumber: IS_SET(user.ORGnumber),
			avatar: IS_SET(user.avatar?.thumbnailPath),
		}
	})
)