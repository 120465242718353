import { onAuthStateChanged } from "firebase/auth";
import { createContext, useEffect, useState } from "react"
import { auth } from "../../Firebase";
import { getValidatedUser } from "./Authentication";
import { useDispatch } from "react-redux";
import { getUser } from "../../store/userSlice";

export const AuthContext = createContext({
	loaded: false,
	authenticated: false,
	validCode: false,
	showInvite: false,
	setShowInvite: null,
	showCreateAccount: false,
	setShowCreateAccount: null,
	showLogin: false,
	setShowLogin: null,
})

const AuthProvider = ({ children }) => {

	const dispatch = useDispatch();

	const hasValidated = localStorage.getItem('validCode') || false

	const [validCode, setValidCode] = useState(hasValidated)
	const [showCreateAccount, setShowCreateAccount] = useState(false)
	const [showInvite, setShowInvite] = useState(false);
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [isLoaded, setIsLoaded] = useState(false)
	const [showLoginModal, setShowLoginState] = useState(false)

	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, (user) => {
			setIsLoaded(true)
			setIsAuthenticated(user?.uid ? true : false)
			dispatch( getUser(user?.uid) )
		})
		return () => unsubscribe()
	}, [])

	useEffect(() => {
		if (!isLoaded) {
			const loadUser = async () => {
				const currentUser = await getValidatedUser()
				setIsLoaded(true)
				setIsAuthenticated(currentUser?.uid ? true : false)
			}
			loadUser()
		}
	}, [isLoaded])

	const _setValidCode = (valid) => {
		localStorage.setItem('validCode', valid)
		setValidCode(valid)
	}

	const contextValue = {
		loaded: isLoaded,
		authenticated: isAuthenticated,
		validCode: validCode,
		setValidCode: (valid) => _setValidCode(valid),
		showCreateAccount, showCreateAccount,
		setShowCreateAccount: (show) => setShowCreateAccount(show),
		showInvite: showInvite,
		setShowInvite: (show) => setShowInvite(show),
		showLogin: showLoginModal,
		setShowLogin: (show) => setShowLoginState(show),
	}

	return(
		<AuthContext.Provider value={contextValue}>
			{ children }
		</AuthContext.Provider>
	)
}

export default AuthProvider