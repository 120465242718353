import { configureStore, combineReducers } from '@reduxjs/toolkit'
import userReducer from './userSlice'
import invoicesReducer from './invoicesSlice'
import customerReducer from './customerSlice'
import invoiceReducer from './invoiceSlice'
import miscReducer from './miscSlice'
import formValidationMiddleware from './middlewares/validationMiddleware'
import initInvoiceMiddleware from './middlewares/initInvoiceMiddleware'
import invoicesMiddleware from './middlewares/invoicesMiddleware'


const reducers = combineReducers({
  user: userReducer,
  customer: customerReducer,
  invoice: invoiceReducer,
  invoices: invoicesReducer,
  misc: miscReducer,
})

const rootReducer = (state, action) => {
  if (action.type === 'misc/resetStore') {
    state = undefined;
  }
  return reducers(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultmiddleware) =>
  getDefaultmiddleware({
    serializableCheck: {
      ignoredPaths: ['payload.dayjsDate'],
    },
    immutableCheck: {
      ignoredPaths: ['invoice.invoiceDate'],
      ignore: 'true',
    },
  }).prepend([
	formValidationMiddleware.middleware,
	initInvoiceMiddleware.middleware,
	invoicesMiddleware.middleware,
  ])
})

export default store
