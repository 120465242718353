import React from 'react';
import FormWrapper from '../components/FormWrapper';
// import { analyticsState } from '../helpers/analyticsMapping';
import UserForm from '../components/forms/UserForm';
import FormNavigationLogic from '../components/forms/FormNavigationLogic';
import { CURRENT_STEP, CURRENT_STEP_INDEX, NEXT_STEP, PREVIOUS_STEP } from '../components/forms/FormSteps';

const CreateInvoice = () => {

	// const userAnalyticsState = useSelector(state => analyticsState(state))

	const previousStep = PREVIOUS_STEP('user') 
	const currentStep = CURRENT_STEP('user')
	const nextStep = NEXT_STEP('user')
	const activeStepIndex = CURRENT_STEP_INDEX('user')

	return (
		<FormWrapper heading={currentStep.heading} progress={activeStepIndex}>
			<UserForm />
			<FormNavigationLogic
				{...currentStep}
				previousStep={previousStep}
				nextStep={nextStep}
			/>
		</FormWrapper>
	);
}

export default CreateInvoice;
