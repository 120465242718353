import * as React from 'react';
import InvoiceArticles from './InvoiceArticles'
import Divider from '@mui/material/Divider'
import dayjs from 'dayjs'
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { CURRENCIES } from '../../helpers/MoneyHelper';
import PaymentMethods from './PaymentMethods';
import UserAvatar from '../UserAvatar';
import SeeFull from './SeeFull';
import OrgDetails from '../OrgDetails';
import InvoiceTo from './InvoiceTo'


const Invoice = ({ invoice, user, customer, showFull = false }) => {

  const hasDiscount = invoice.items.reduce((discount, item) => discount + item.discount, 0) > 0

  const paymentLabels = {
    "item4": { label: 'Amount due', enabled: true, value: CURRENCIES[invoice.currency](invoice.totalAmount).format(), style: 'bold' },
	"item5": { label: 'Due date', enabled: true, value: dayjs.unix(invoice.dueDate).format("DD MMM YYYY")  },
    "item6": { label: 'Invoice no', enabled: true, value: invoice.invoiceNumber },
    // "item2": { label: 'Reference', enabled: invoice.reference && invoice.reference.length > 0 ? true : false, value: invoice.reference },
    "item7": { label: '', enabled: true, value: ''  },
  }

  let paymentOptionsLabels = {
	"Swish": { label: 'Swish', enabled: false, value: '', },
	"Bankgiro": { label: 'Bankgiro', enabled: false, value: '', },
	"BankTransferName": { label: '', enabled: false, value: '', },
	"BankTransferAccount": { label: '', enabled: false, value: '', },
  }

  if (invoice.paymentOptions.BankTransferName?.enabled && invoice.paymentOptions.BankTransferAccount?.enabled && invoice.paymentOptions.BankTransferClearing?.enabled) {
	paymentOptionsLabels.BankTransferName.label = invoice.paymentOptions.BankTransferName?.value
	paymentOptionsLabels.BankTransferName.value = invoice.paymentOptions.BankTransferClearing?.value
	paymentOptionsLabels.BankTransferAccount.value = invoice.paymentOptions.BankTransferAccount.value
	paymentOptionsLabels.BankTransferName.enabled = true
	paymentOptionsLabels.BankTransferAccount.enabled = true
  }

  if (invoice.paymentOptions.Swish.enabled) {
	paymentOptionsLabels.Swish.value = invoice.paymentOptions.Swish.value
	paymentOptionsLabels.Swish.enabled = true
  }

  if (invoice.paymentOptions.Bankgiro.enabled) {
	paymentOptionsLabels.Bankgiro.value = invoice.paymentOptions.Bankgiro.value
	paymentOptionsLabels.Bankgiro.enabled = true
  }

  return (
      <Box sx={{backgroundColor: 'white'}}>
        <Stack spacing={4}>
			<Stack
				direction="row"
				justifyContent="space-between"
				alignItems="center"
				>
				<Box sx={{ maxWidth: '60%'}}>
					<Typography align="left" variant="h6">
					Invoice
					</Typography>
					<Typography align="left" variant="h4">
					{ user.companyName }
					</Typography>
				</Box>
				<UserAvatar {...user} sx={{ marginBottom: '-10px', marginTop: '20px', marginRight: '20px', }}></UserAvatar>
			</Stack>
			<PaymentMethods showDiscount={hasDiscount} paymentOptions={{...paymentLabels, ...paymentOptionsLabels}} />
          <SeeFull showFull={showFull}>
            <InvoiceArticles
              {...invoice}
			  showDiscount={hasDiscount}
              showHeader={true}
              showTotal={true} />
            {/* <Divider variant="middle" /> */}
			<InvoiceTo customer={customer} invoice={invoice} />
            <OrgDetails {...user} />
          </SeeFull>
        </Stack>
      </Box>
  );
}

export default Invoice;
