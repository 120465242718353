import { useSelector } from "react-redux";
import { FORM_PROPS } from '../../helpers/FormProps'
import { setPaymentOption as setInvoicePaymentOption } from '../../store/invoiceSlice'
import NumberInput from '../inputs/NumberInput';
import TextInput from '../inputs/TextInput';
import Stack from '@mui/material/Stack';
import { setDefaultPaymentOption } from "../../store/userSlice";


const BillingForm = ({ paymentOptions, validation, sliceName = 'invoice', changeFunc = () => {}}) => {

	return (
		<Stack spacing={FORM_PROPS.ROW_SPACING}>
			<Stack direction="row" spacing={FORM_PROPS.COLUMN_SPACING}>
				<NumberInput
					value={paymentOptions['Swish'].value}
					label={paymentOptions['Swish'].label}
					handleChangeFunc={changeFunc}
					inputType='phone'
					id='Swish'
					propName={sliceName}
					valid={validation?.valid}
				/>
				<NumberInput
					value={paymentOptions['Bankgiro'].value}
					label={paymentOptions['Bankgiro'].label}
					handleChangeFunc={changeFunc}
					valid={validation?.valid}
					propName={sliceName}
					inputType='bg'
					id='Bankgiro'
				/>
			</Stack>
			<Stack direction="row" spacing={FORM_PROPS.COLUMN_SPACING}>
				<TextInput
					value={paymentOptions['BankTransferName'].value}
					handleChangeFunc={changeFunc}
					id="BankTransferName"
					label={paymentOptions['BankTransferName'].label}
					valid={validation?.valid}
					propName={sliceName}
					variant="standard"
					sx={{ width: '48.5%' }}
				/>
			</Stack>
			<Stack direction="row" spacing={FORM_PROPS.COLUMN_SPACING}>
				<NumberInput
					value={paymentOptions['BankTransferClearing'].value}
					label={paymentOptions['BankTransferClearing'].label}
					handleChangeFunc={changeFunc}
					valid={validation?.valid}
					propName={sliceName}
					inputType='clearing'
					id='BankTransferClearing'
					// helperText={validation?.valid ? '' : 'A payment method is needed'}
				/>
				<NumberInput
					value={paymentOptions['BankTransferAccount'].value}
					label={paymentOptions['BankTransferAccount'].label}
					handleChangeFunc={changeFunc}
					valid={validation?.valid}
					propName={sliceName}
					inputType='account'
					id='BankTransferAccount'
				/>
			</Stack>
        </Stack>
	)
}

const BillingFormHOC = ({ type = 'invoice' }) => {
	
	const paymentOptions = useSelector(state => state.invoice.paymentOptions);
	const defaultPaymentOptions = useSelector(state => state.user.defaultPaymentOptions);
	const paymentOptionsToUse = type === 'invoice' ? paymentOptions : defaultPaymentOptions

	const validation = useSelector(state => state.invoice.validation.paymentOptions)
	const defaultPaymentOptionsValidation = useSelector(state => state.user.validation.defaultPaymentOptions)
	const validationToUse = type === 'invoice' ? validation : defaultPaymentOptionsValidation

	const handleChangeFunc = type === 'invoice' ? setInvoicePaymentOption : setDefaultPaymentOption

	const sliceName = type === 'invoice' ? 'invoice.paymentOptions' : 'user.defaultPaymentOptions'

	return (
		<BillingForm paymentOptions={paymentOptionsToUse} validation={validationToUse} sliceName={sliceName} changeFunc={handleChangeFunc}/>
	)
}

export default BillingFormHOC