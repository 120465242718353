import * as React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import StartButton from '../components/StartButton';


function Team() {

  return (
    <React.Fragment>
      <Stack spacing={4} sx={{padding: '20px', paddingTop: '60px',
    paddingBottom: '60px'}}>
        <Typography variant='h1'>Meet the team:</Typography>
        <Typography variant='p'>Pejme has raised $0M in funding to power the future of the self-employed mastermind!</Typography>
        <Typography variant='p'>About us:</Typography>
        <Typography variant='p'>Ruben Svartholm</Typography>
        <Typography variant='p'>Henrik Kanekrans</Typography>
        <StartButton />
      </Stack>
    </React.Fragment>
  )
}

export default Team;
