import store from '../../store/store'
import {
    signInAnonymously,
    onAuthStateChanged,
    signInWithEmailLink,
	signInWithEmailAndPassword,
    isSignInWithEmailLink,
    EmailAuthProvider,
    linkWithCredential,
    sendSignInLinkToEmail,
    signOut,
	auth,
} from "../../Firebase";
import { getUser, setUserEmail, setUserId, unsetUserId } from "../../store/userSlice";
import { AuthErrorCodes, confirmPasswordReset, createUserWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';

export function getValidatedUser() {
	return new Promise((resolve, reject) => {
	  const unsubscribe = onAuthStateChanged(
			auth,
			(user) => {
				unsubscribe();
				resolve(user);
			},
			reject // pass up any errors attaching the listener
		);
	});
  }

const userLoggedIn = (user) => {
	store.dispatch( setUserId( user.uid ) )
	store.dispatch( getUser(user.uid) )
}

const userLoggedOut = () => {
	store.dispatch( unsetUserId() )
}

export const userUpdated = (user) => {
	if (user) {
		userLoggedIn(user)
	} else {
		userLoggedOut()
	}
}

const authStateChanged = (user) => {
    console.log('auth state changed');
    if (user) {
        console.log('SIGNED IN', user.uid);
		console.log(user);
		userLoggedIn(user)
    } else {
        console.log('Not signed in');
		userLoggedOut()
    }
}

export const currentUser = () => {
	if (auth.currentUser) return auth.currentUser.uid
	return false
}

export const checkAuth = onAuthStateChanged(auth, authStateChanged)

export const logOut = async () => {
    console.log('should logout');
    console.log(store.getState());
    try {
        console.log('Do signout');
        await signOut(auth)    
        store.dispatch( unsetUserId( ) )
        console.log('signed out.!');
    } catch (error) {
        console.log(error);
    }
    
}

export const createAccount = async () => {
    try {
        // const userEmail = store.getState().user?.email
        // const signInMethods = await fetchSignInMethodsForEmail(auth, userEmail)
        // if (signInMethods.indexOf(EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD) != -1) {
        //     signInLink()
        //     return
        // }


    } catch (error) {
        
    }
}

export const requestPasswordReset = async (userEmail) => {
	try {
		console.log('Requesting reset for', userEmail);
		const actionCode = {
			url: `${window.location.origin}/reset-password`
		}
		const resetRequested = await sendPasswordResetEmail(auth, userEmail, actionCode)
		console.log(resetRequested);
		return
	} catch (error) {
		console.log(error);
	}
}

export const doPasswordReset = async (oobCode, newPassword) => {
	try {
		console.log('Doing reset', oobCode, newPassword);
		const passwordReset = await confirmPasswordReset(auth, oobCode, newPassword)
		console.log(passwordReset);
		return { pwdReset: true }
	} catch (error) {
		console.log(error.code);
		return {
			pwdReset: false,
			error: error.code
		}
	}
}

export const signInUserPassword = async (userEmail, password) => {
	try {
		const signIn = await signInWithEmailAndPassword(auth, userEmail, password )
		if (signIn.uid) {
			store.dispatch( setUserId( signIn.uid ) )
			store.dispatch( setUserEmail( userEmail ) )
		}
		return { signedIn: true }
	} catch (error) {
		console.log(error.code);
		return {
			signedIn: false,
			error: error.code
		}
	}
}

export const createUserPassword = async (userEmail, password) => {
	try {
		const signIn = await createUserWithEmailAndPassword(auth, userEmail, password )
		if (signIn.uid) {
			store.dispatch( setUserId( signIn.uid ) )
			store.dispatch( setUserEmail( userEmail ) )
		}
		return { signedIn: true }
	} catch (error) {
		console.log(error.code);
		return {
			signedIn: false,
			error: error.code
		}
	}
}

export const signInLink = async (userEmail = false) => {
    try {
        const { email } = store.getState().user
        console.log('email', userEmail, email);
		userEmail = email.includes('@') ? email : userEmail


        if (!userEmail) return false
        // const email = 'r.svartholm@gmail.com'
        const { pathname, origin } = window.location
        const currentURL = `${origin}/verifylink?redirect=${encodeURIComponent(pathname)}`

        const actionCodeSettings = {
            url: currentURL,
            handleCodeInApp: true,
        };

        console.log('should create link');
        await sendSignInLinkToEmail(auth, userEmail, actionCodeSettings)
        window.localStorage.setItem('emailForSignIn', userEmail);
        console.log('Link sent');
        return true
    } catch (error) {
        console.log('error generating signInLink');
        console.log(error);
    }
}

export const verifySingInLink = async () => {
    try {
        console.log(auth);
        if (isSignInWithEmailLink(auth, window.location.href)) {
            let email = window.localStorage.getItem('emailForSignIn');
            const { currentUser } = auth
            const credential = EmailAuthProvider.credentialWithLink(email, window.location.href);
            const userCred = await linkWithCredential(currentUser, credential)
            console.log('User is linked', userCred);
            window.localStorage.removeItem('emailForSignIn');
        } else {
            alert('not valid link!')
        }
    } catch (error) {
        console.log(error);
    }
}