import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material';
import LightningBolt from '../login/LightningBolt';
import { Fragment } from 'react';

const StyledBox = styled(Box, {
	shouldForwardProp: (prop) => prop !== 'modalSize'
})(({theme, modalSize = 'normal'}) => ({
	backgroundColor: theme.palette.grey[300],
	borderRadius: '60px',
	padding: '20px',
	paddingTop: '60px',
	paddingBottom: '60px',
	display: 'flex',
	position: 'relative',
	justifyContent: 'center',
	flexDirection: 'column',
	width: '400px',
	...(modalSize === 'narrow' && {
		width: '400px'
	}),
	[theme.breakpoints.down('sm')]:{
		width: '70%',
		...(modalSize === 'narrow' && {
			width: '70%'
		}),
	},
}))

const StyledIconButton = styled(IconButton)(({theme}) => ({
	position: 'absolute',
	top: '-25px',
	right: '-25px',
	backgroundColor: theme.palette.grey[300],
	borderRadius: '50%',
	[theme.breakpoints.down("sm")]:{
		display: 'none',
	},
	['&:hover']:{
		backgroundColor: theme.palette.primary.dark,
		color: theme.palette.primary.contrastText,
	}
}))

const RoundCloseButton = ({ handleClose }) => {
	return (
		<StyledIconButton onClick={() => handleClose()}>
			<CloseIcon />
		</StyledIconButton>
	)
}

const FullModal = ({
	modalSize = 'normal',
	showBolts = false,
	showClose = true,
	children,
	isOpen = false,
	setIsOpen = () => {},
	onClose = () => {}
}) => {

    const handleClose = () => {
		setIsOpen(false)
		onClose()
    }

    return(
        <Modal
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, justifyContent: "center", alignItems: 'center', display: 'flex', }}
            open={isOpen}
			onClose={handleClose}
        >
			<Fragment>
			{ showBolts ? [<LightningBolt key={'bolt-left'} />,<LightningBolt key={'bolt-right'} orientation="right"/>] : null }
				<StyledBox modalSize={modalSize}>
					{ showClose && <RoundCloseButton handleClose={handleClose} /> }
					{ children }
				</StyledBox>
			</Fragment>
        </Modal>
    )
}

export default FullModal