import { styled } from '@mui/material';
import Button from '@mui/material/Button';


const RoundStyleButton = styled(Button)(({theme, btncolor, size}) => ({
	...theme.typography.button,
	height: '80px',
	width: '80px',
	minWidth: '80px',
	minHeight: '80px',
	...(size === 'large' && {
		...theme.typography.buttonLg,
		height: '120px',
		width: '120px',
	}),
	borderRadius: '50%',
	backgroundColor: theme.palette.primary.contrastText,
	'&:hover': { 
		backgroundColor: theme.palette.primary.contrastText,
	 },
	 ...(btncolor === 'black' && {
		color: theme.palette.primary.contrastText,
		backgroundColor: theme.palette.primary.dark,
		'&:hover': { 
			backgroundColor: theme.palette.primary.dark,
	 	},
	 }),
	 ...(btncolor === 'pink' && {
		color: theme.palette.primary.dark,
		backgroundColor: theme.palette.pinkOrange.col2,
		'&:hover': { 
			backgroundColor: theme.palette.primary.dark,
			color: theme.palette.primary.contrastText,
	 	},
	 }),
}))

const RoundButton = ({ children, btnColor = "white", size = 'large', ...inputProps}) => {
	return (
		<RoundStyleButton btncolor={btnColor} size={size} {...inputProps} variant='contained'>
			{ children }
		</RoundStyleButton>
	)
}

export default RoundButton