import FaceIcon from '@mui/icons-material/Face';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

function UserAvatar({name, email, avatar, sx = {}}){

    return (
      <Stack alignItems="center">
        <Box sx={{
			...sx,
          	height: '110px',
			width: '110px',
			borderRadius: '50%',
			backgroundImage: avatar.URL ? `url(${avatar.URL})` : '',
			backgroundRepeat: 'no-repeat',
			backgroundPosition: '50% 50%',
			backgroundSize: 'cover',
        }}/>
      </Stack>
    );
  }

export default UserAvatar;