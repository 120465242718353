import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
  addDoc,
  collection,
  doc,
  updateDoc,
  getDoc,
  setDoc,
  deleteDoc,
  arrayUnion,
} from "firebase/firestore";
import { db } from '../Firebase';
import dayjs from 'dayjs';
import { fetchInvoice, recalculateTotal, saveInvoice, setPdfForInvoice } from './invoiceActions';
import { defaultItem, TEST_ITEM, DEFAULT_VAT, PAYMENT_OPTIONS } from './storeTypes'
import { INVOICE_DEBUG_STATE } from './debugStore';
import { validateState, clearValidationForParam } from '../helpers/ValidationHelper';
import { VALIDATE_INVOICE_RULES } from './validationRules';

const INITIAL_STATE = {
    items: [],
    currentItem: defaultItem(),
    totalAmount: 0,
    netAmount: 0,
    paymentOptions: { ...PAYMENT_OPTIONS },
    totalVAT: {...DEFAULT_VAT},
    reference: '',
    paymentTerms: 7,
    invoiceDate: dayjs().unix(),
    dueDate: dayjs().add(7, 'days').unix(),
    invoiceNumber: '',
    currency: 'SEK',
	invoiceURL: false,
    customer: false,
    user: false,
    id: false,
    status: false,
	invoiceTheme: 'pinkOrange',
	stateValid: true, // Don'' save
	validation: { }, // Don't save
}


const getInvoiceToSave = (invoiceSlice) => {
	const { status, stateValid, validation, invoiceURL, ...invoiceToSave } = invoiceSlice
	return invoiceToSave
}

const getPublicFields = (invoiceSlice) => {
	const { status, valid, ...publicInvoice } = invoiceSlice
	return publicInvoice
}

const USE_STATE = INITIAL_STATE
// const USE_STATE = (process.env.NODE_ENV === 'development') ? Object.assign({}, INITIAL_STATE, INVOICE_DEBUG_STATE) : INITIAL_STATE

export const createOrUpdateInvoice = createAsyncThunk('invoice/create', async (args, thunkAPI) => {
  try {
    
    const { invoice, user, customer } = thunkAPI.getState()
    let { id, ...invoiceToSave } = invoice
    invoiceToSave.user = user.id
	invoiceToSave.customer = customer.id
    console.log('Saving invoice', id, invoiceToSave);
    if (id) {
      await updateDoc(doc(db, 'invoices', id), invoiceToSave)
	  invoiceToSave.id = id
    } else {
      const { id: saveId } = await addDoc(collection(db, 'invoices'), invoiceToSave)      
	  invoiceToSave.id = saveId
    }
	const res = await setDoc(doc(db, 'entitiesForUser', user.id), { invoices: arrayUnion(invoiceToSave.id) }, { merge: true })
	console.log('saved invoice', invoiceToSave.id);
    return invoiceToSave
  } catch (error) {
    console.log('ERROR SAVING INVOICE');
    console.log(error);
    console.log('----');
  }
})

export const updateInvoiceData = createAsyncThunk('invoice/update', async (args, thunkAPI) => {
	try {
		const { id, data } = args
		if (id) {
			await updateDoc(doc(db, 'invoices', id), data)
		}
		return data
	} catch (error) {
		console.log(error);
	}
})

export const getInvoice = createAsyncThunk('invoice/get', async (invoiceId, thunkAPI) => {
  try {
    const docRef = doc(db, 'invoices', invoiceId)
    const docSnap = await getDoc(docRef);
    console.log('docSnap invoice', docSnap.data());
    if (docSnap.exists()) {
      const data = docSnap.data()
      return data
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  } catch (error) {
    console.log(error);
  }
})

export const removeInvoice = createAsyncThunk('invoice/remove', async (invoiceId, thunkAPI) => {

	try {
		const docRef = doc(db, 'invoices', invoiceId)
		const removedDoc = await deleteDoc(docRef)
		return {
			removed: true,
			removedId: invoiceId,
		}
	} catch (error) {
		console.log(error)
		return {
			removed: false,
			error: error
		}
	}
})


export const invoiceSlice = createSlice({
  name: 'invoice',
  initialState: { ...USE_STATE },
  reducers: {
	unSetInvoice: (state) => {
		state = Object.assign(state, INITIAL_STATE)
	},
    saveItem: (state, action) => {
      
      let invoiceCopy = Object.assign({}, action.payload)

      const {
        netAmount,
        totalAmount,
        totalVAT,
        allItems
      } = recalculateTotal(invoiceCopy, invoiceCopy.currentItem.id, 'add')

      //Update state
      state.totalVAT = totalVAT
      state.netAmount = netAmount.value
      state.totalAmount = totalAmount.value
      state.currentItem = {}
      state.items = allItems
    },
    removeItem: (state, action) => {
      let invoiceCopy = Object.assign({}, state)
      const idToRemove = action.payload
      const {
        netAmount,
        totalAmount,
        totalVAT,
        allItems
      } = recalculateTotal(invoiceCopy, idToRemove, 'remove')
      state.totalVAT = totalVAT
      state.netAmount = netAmount.value
      state.totalAmount = totalAmount.value
      state.currentItem = allItems.length > 0 ? {} : defaultItem()
      state.items = allItems
    },
    createItem: (state) => {
		const newItem = defaultItem()
		console.log('creating', newItem);
      	state.currentItem = newItem
    },
    setCurrentItem: (state, action) => {
      const selectedItem = state.items.find(it => it.id === action.payload)
      state.currentItem = selectedItem
    },
    setItemNetAmountExclVAT: (state, action) => {
      state.currentItem.itemNetAmountExclVAT = action.payload
    },
    setDescription: (state, action) => {
      state.currentItem.description = action.payload
    },
    setCurrentVAT: (state, action) => {
      state.currentItem.currentVAT = action.payload
    },
    setDiscount: (state, action) => {
      state.currentItem.discount = action.payload
    },
    setReference: (state, action) => {
      state.reference = action.payload
    },
    setPaymentTerms: (state, action) => {
      const newDate = dayjs.unix(state.invoiceDate).add(action.payload, 'days')
      state.dueDate = newDate.unix()
      state.paymentTerms = action.payload
    },
    setInvoiceDate: (state, action) => {
      const newDate = dayjs.unix(action.payload).add(state.paymentTerms, 'days')
      state.dueDate = newDate.unix()
      state.invoiceDate = action.payload
    },
    setInvoiceNumber: (state, action) => {
      state.invoiceNumber = action.payload
    },
    setCustomerForInvoice: (state, action) => {
      state.customer = action.payload
    },
    setUserForInvoice: (state, action) => {
      state.user = action.payload
    },
    setPaymentOption: (state, action) => {
      const paymentOpt = {
        enabled: action.payload.value ? true : false,
        value: action.payload.value,
        label: PAYMENT_OPTIONS[action.payload.id].label,
      } 
      state.paymentOptions[action.payload.id] = paymentOpt
    },
    setInvoice: (state, action) => {
      state.items = action.payload.items
      state.totalAmount = action.payload.totalAmount
      state.netAmount = action.payload.netAmount
      state.totalVAT = action.payload.totalVAT
      state.invoiceDate = action.payload.invoiceDate
      state.dueDate = action.payload.dueDate
      state.invoiceNumber = action.payload.invoiceNumber
      state.customer = action.payload.customer
      state.user = action.payload.user
      state.id = action.payload.id
    },
	validateInvoice: (state) => {
		const { stateValid, validation } = validateState(state, VALIDATE_INVOICE_RULES)
		state.stateValid = stateValid
		state.validation = validation
	},
	clearValidation: clearValidationForParam,
	setInvoiceTheme: (state, action) => {
		state.invoiceTheme = action.payload
	},
	setAllPaymentOptions: (state, action) => {
		state.paymentOptions = action.payload
	},
  },
  extraReducers: builder => {
    builder
      .addCase(createOrUpdateInvoice.pending, (state, action) => {
        state.status = 'LOADING'
      })
      .addCase(createOrUpdateInvoice.fulfilled, (state, action) => {
		console.log('craete orupdate', action.payload);
        state.id = action.payload.id
        state.status = 'FULFILLED'
      })
      .addCase(getInvoice.pending, (state, action) => {
        state.status = 'LOADING'
      })
      .addCase(getInvoice.fulfilled, (state, action) => {
        Object.assign(state, action.payload)
        state.status = 'FULFILLED'
      })
      .addCase(fetchInvoice.pending, (state, action) => {
        state.status = 'LOADING'
      })
      .addCase(fetchInvoice.fulfilled, (state, action) => {
        Object.assign(state, action.payload)
        state.status = 'FULFILLED'
      })
	  .addCase(saveInvoice.fulfilled, (state, action) => {
        state.id = action.payload
      })
	  .addCase(setPdfForInvoice.fulfilled, (state, action) => {
		state.invoiceURL = action.payload
	  })
	  .addCase(updateInvoiceData.fulfilled, (state, action) => {
		state = Object.assign(state, action.payload)
	  })
	  .addCase(removeInvoice.pending, (state, action) => {
		state.status = 'LOADING'
	  })
	  .addCase(removeInvoice.fulfilled, (state, action) => {
		state.status = 'FULFILLED'
	  })
  }
})

export const {
	unSetInvoice,
	saveItem,
	removeItem,
	setItemNetAmountExclVAT,
	setCurrentVAT,
	setDiscount,
	createItem,
	setCurrentItem,
	setDescription,
	setInvoiceDate,
	setReference,
	setPaymentTerms,
	setPaymentOption,
	setInvoiceNumber,
	setCustomerForInvoice,
	setUserForInvoice,
	setInvoice,
	validateInvoice,
	clearValidation,
	setInvoiceTheme,
	setAllPaymentOptions,
} = invoiceSlice.actions

export default invoiceSlice.reducer
