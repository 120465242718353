import React from 'react';
import FormWrapper from '../components/FormWrapper';
import FormNavigationLogic from '../components/forms/FormNavigationLogic';
import { GET_ADMIN_STEP } from '../components/forms/FormSteps';
import BillingForm from '../components/forms/BillingForm';

const EditDefaultBilling = () => {

	const currentStep = GET_ADMIN_STEP('billing')
	const nextStep = GET_ADMIN_STEP('invoices')

  return (
    <FormWrapper heading={currentStep.heading} progress={false}>
		<BillingForm type="user" />
      	<FormNavigationLogic
			{...currentStep}
			nextStep={nextStep}
		/>
    </FormWrapper>
  );
}

export default EditDefaultBilling;
