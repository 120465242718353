import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { storage } from '../Firebase';
import { delayMsPromise } from './GeneralHelper';

export const uploadBlob = async (filename = 'testfile', folder = 'testFolder', blobContents) => {
	try {
		const fileRef = ref(storage, `${folder}/${filename}`)
		const snapshot = await uploadBytes(fileRef, blobContents)
		const publicURL = await getFileURLFromPath(snapshot.metadata.fullPath)
		return publicURL
	} catch (error) {
		console.error(error);
	}
}

export const getFileURLFromPath = async (filePath) => {
	try {
		const fileRef = ref(storage, filePath)
		const fileURL = await getDownloadURL(fileRef)
		return fileURL
	} catch (error) {
		console.error('Error generating FileRef', error);
	}
}

export const awaitFileURLFromPath = async (filePath, maxRetries = 5, currentTry = 0) => {
    const fileRef = ref(storage, filePath)
    let fileURL;

    try {
        fileURL = await getDownloadURL(fileRef)
    } catch (error) {
        if (currentTry < maxRetries) {
            console.log('File not found, retrying..', currentTry);
            await delayMsPromise(10*1000)
            fileURL = await awaitFileURLFromPath(filePath, maxRetries, currentTry + 1)
        } else {
            console.error('No file found, give up');
            throw new Error('Thumbnail not found')
        }
    } finally {
        console.log('finally', fileURL);
        return fileURL
    }
}