import * as React from 'react'
import { Typography } from '@mui/material'
import { Box } from '@mui/material'

function SeeFull(props){

    const { children, showFull = false } = props

    const [showFullInvoice, setShowFullInvoice] = React.useState(showFull)

    return (
        <React.Fragment>
		{ !showFullInvoice ?
			<Box sx={{
                position: 'relative',
                width: '100%',
                height: '0px',
                margin: '0',
            }}>
                <Box sx={{
                    height: '200px',
                    position: 'absolute',
                    width: '100%',
                    background: 'linear-gradient(rgba(255,255,255,0) 0%, rgba(255,255,255,0.8) 20%, rgba(255,255,255,1) 100%)',
                    top: 0,
                    left: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}>
                <Typography
                    onClick={() => setShowFullInvoice(true)}
                    variant="h6"
                    sx={{
                        paddingTop: '20px',
						textDecoration: 'underline',
						cursor: 'pointer',
                    }}
                    >See full invoice
                </Typography>
                </Box>
            </Box>
		: null }
        { showFullInvoice ? children : <Box sx={{ height: '150px', overflow: 'hidden' }}>{ children }</Box>}
        </React.Fragment>
    )
}

export default SeeFull;