import React, { useContext, useEffect } from 'react'
import FullscreenSpinner from '../../components/fragments/FullscreenSpinner'
import { AuthContext } from './AuthProvider'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { API_STATUS } from '../../store/storeTypes'


function AuthenticatedRoute(props) {

	const { children } = props
	const { loaded, authenticated } = useContext(AuthContext)
	const userFetching = useSelector(state => state.user.userFetching)
	const navigate = useNavigate()

	useEffect(() => {
		if (!loaded) return
		if (!authenticated ) {
			navigate('/')
		}
		// eslint-disable-next-line
	}, [loaded, authenticated])

	if (!loaded || userFetching === API_STATUS.PENDING) return <FullscreenSpinner />

	return (
		<>
		{ children }
		</>
	)
}

export default AuthenticatedRoute