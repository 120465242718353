import { forwardRef } from "react";
import { NumericFormat } from "react-number-format";

export const AmountFormat = forwardRef(function AmountFormat(props, ref) {
    const { onChange = () => {}, displayType = 'input', ...other } = props

    return (
        <NumericFormat
            {...other}
            displayType={displayType}
            getInputRef={ref}
            thousandSeparator=" "
            type="text"
            valueIsNumericString={true}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value
                    }
                })
            }}
        />
    )
})