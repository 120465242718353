import { forwardRef } from "react"
import { PatternFormat } from "react-number-format"

export const PhoneNumberFormat = forwardRef(function PhoneNumberFormat(props, ref) {
    const { onChange, displayType = 'input', ...other } = props

    return (
        <PatternFormat
            {...other}
            displayType={displayType}
            getInputRef={ref}
            format="### ### ## ##"
            type="text"
            valueIsNumericString={true}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value
                    }
                })
            }}
        />
    )
})